import { useState } from 'react'

import mixpanel from 'mixpanel-browser'

import { BUTTON_PRESS } from '@/utils/analytics'

import { MixpanelDataIProps } from '../types/types'

interface UseTabsIProps {
  initialTab?: IncidentTab
  mixpanelData?: MixpanelDataIProps
}

export enum IncidentTab {
  AddNote,
  DispatchGuards,
  RadioGuards,
  OrderRepair,
  Resolve,
}

export const useTabs = ({ initialTab = null, mixpanelData }: UseTabsIProps) => {
  const [tab, setTab] = useState<null | IncidentTab>(initialTab)

  const toggleTab = (tab: IncidentTab) => {
    setTab((previousTab) => (previousTab === tab ? null : tab))
  }

  const openTab = (tab: IncidentTab) => {
    setTab(tab)
  }

  const closeTabs = () => {
    setTab(null)
  }

  const handleAddNoteClick = () => {
    toggleTab(IncidentTab.AddNote)
    mixpanel.track(`${BUTTON_PRESS} Add Note`, mixpanelData)
  }

  const handleDispatchClick = () => {
    openTab(IncidentTab.DispatchGuards)
    mixpanel.track(`${BUTTON_PRESS} Dispatch Guards`, mixpanelData)
  }

  const handleRadioClick = () => {
    openTab(IncidentTab.RadioGuards)
    mixpanel.track(`${BUTTON_PRESS} Radio Guards`, mixpanelData)
  }

  const handleOrderRepairClick = () => {
    toggleTab(IncidentTab.OrderRepair)
    mixpanel.track(`${BUTTON_PRESS} Order Repair`, mixpanelData)
  }

  const handleResolveClick = () => {
    toggleTab(IncidentTab.Resolve)
    mixpanel.track(`${BUTTON_PRESS} Resolve`, mixpanelData)
  }

  return {
    tab,
    handleAddNoteClick,
    handleDispatchClick,
    handleRadioClick,
    handleOrderRepairClick,
    handleResolveClick,
    closeTabs,
    openTab,
    toggleTab,
  }
}
