import React from 'react'

import { useDispatch } from 'react-redux'

import { SOPDocument, SOPDrawerHeader } from '@/features/sop-drawer'
import { useStandardOperatingProcedureQuery } from '@/graphql/generated/hooks'
import { setSOPIncidentId } from '@/redux/ui/uiSlice'

export const SOPIncidentDetail = ({ id }: { id: string }) => {
  const dispatch = useDispatch()
  const { data, loading: isLoading } = useStandardOperatingProcedureQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })

  const onClose = () => {
    dispatch(setSOPIncidentId({ id: null }))
  }

  return (
    <>
      <SOPDrawerHeader
        isLoading={isLoading}
        onClose={onClose}
        sop={data?.standardOperatingProcedure}
      />
      <SOPDocument
        isLoading={isLoading}
        sop={data?.standardOperatingProcedure}
      />
    </>
  )
}
