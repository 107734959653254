import { useDispatch } from 'react-redux'

import { Box, Grid, Skeleton, VStack } from '@chakra-ui/react'

import { IncidentPriority, IncidentStatus } from '@/graphql/generated/schemas'
import {
  closeDeviceDrawer,
  openIncidentDrawer,
  setIncident,
} from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'

import { EventListItem } from './EventListItem'

interface IProps {
  incidents: {
    __typename?: 'Incident'
    id: string
    createdAt: string
    status?: IncidentStatus
    name?: string
    description?: string
    priority?: IncidentPriority
  }[]
  isLoading: boolean
}

const ListItemSkeleton = () => {
  return (
    <Grid
      alignItems='flex-start'
      borderBottom='1px'
      borderColor='#D5DCE4'
      gap='2'
      px='24px'
      py='4'
      templateColumns='13px auto'
    >
      <Skeleton h='13px' rounded='4px' w='13px' />
      <VStack alignItems='flex-start' spacing='1'>
        <Skeleton h='16px' maxW='220px' w='full' />
        <Skeleton h='16px' maxW='200px' w='full' />
        <Skeleton h='16px' maxW='300px' w='full' />
      </VStack>
    </Grid>
  )
}

export const DeviceAlerts = ({ incidents, isLoading }: IProps) => {
  const dispatch = useDispatch()

  const openAlertIncidentDrawer = (id: string) => {
    dispatch(closeDeviceDrawer())
    dispatch(setIncident({ id }))
    dispatch(openIncidentDrawer())
    const date = new Date().toUTCString()
    mixpanel.track('Incident List Button Clicked, Device Card', {
      user_id: '1',
      facility_id: '1',
      incident_id: id,
      event_at: date,
      distinct_id: '1',
      number_of_incidents: '1',
      device_id: '1',
      device_type: 'camera',
    })
  }

  if (isLoading) {
    return (
      <VStack align='stretch' spacing={2}>
        <ListItemSkeleton />
        <ListItemSkeleton />
        <ListItemSkeleton />
      </VStack>
    )
  }

  return (
    <VStack align='stretch' spacing={0}>
      {incidents?.length > 0 ? (
        [...incidents]
          ?.sort(
            (a, b) =>
              new Date(b?.createdAt).valueOf() -
              new Date(a?.createdAt).valueOf()
          )
          .map((d) => (
            <EventListItem
              dateTime={d?.createdAt}
              description={d?.description}
              eventType={d?.priority}
              handleClick={() => openAlertIncidentDrawer(d?.id)}
              key={d?.id}
              status={d?.status}
              title={d?.name}
            />
          ))
      ) : (
        <Box mt='3' textAlign='center'>
          No incidents
        </Box>
      )}
    </VStack>
  )
}
