import { memo } from 'react'

import { useSelector } from 'react-redux'

import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'

import { DrawerScrollContainer } from '@/components/ui'
import { useSubmittedTourQuery } from '@/graphql/generated/hooks'
import { selectSubmittedTour } from '@/redux/ui/uiSlice'

import { formatData } from '../utils/format'
import { SubmittedDrawerTourContent } from './SubmittedDrawerTourContent'
import { SubmittedTourHeader } from './SubmittedTourHeader'

export interface SubmittedTourDrawerIProps {
  isOpen: boolean
  onClose: () => void
}

export const SubmittedTourDrawer = memo(
  ({ isOpen, onClose }: SubmittedTourDrawerIProps) => {
    const { id } = useSelector(selectSubmittedTour)
    const { data, loading: isLoading } = useSubmittedTourQuery({
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    })
    const drawerData = formatData(data?.guardTour)

    return (
      <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='md'>
        <DrawerOverlay />
        <DrawerContent>
          <SubmittedTourHeader
            isLoading={isLoading}
            onClose={onClose}
            tourPlan={drawerData}
          />
          <DrawerScrollContainer px='30px' py='30px'>
            <SubmittedDrawerTourContent
              drawerData={drawerData}
              isLoading={isLoading}
            />
          </DrawerScrollContainer>
        </DrawerContent>
      </Drawer>
    )
  }
)
