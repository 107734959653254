import { HiOutlineExclamation, HiOutlinePencil } from 'react-icons/hi'
import { Marker } from 'react-map-gl'
import { useSelector } from 'react-redux'

import { Box, Circle } from '@chakra-ui/react'

import { selectEditMode } from '@/redux/ui/uiSlice'

interface IProps {
  openEditFacility?: (MouseEventHandler) => void
  onPress?: () => void
  facility: {
    id: string
    latitude: number
    longitude: number
    incidentCount: number
    shortName: string
  }
}

export const FacilityMarker = ({
  facility,
  openEditFacility,
  onPress,
}: IProps) => {
  const hasAlerts = facility.incidentCount > 0
  const numCount = facility.incidentCount > 999 ? '1k+' : facility.incidentCount
  const isEditMode = useSelector(selectEditMode)
  return (
    <Marker
      latitude={facility.latitude}
      longitude={facility.longitude}
      offset={[-25, -25]}
    >
      <Circle
        _after={{
          content: '""',
          pos: 'absolute',
          borderRadius: '50%',
          h: '47px',
          w: '47px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderColor: hasAlerts ? '#fff' : '#4ad06c',
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
        bg={hasAlerts ? '#D01030' : '#fff'}
        boxShadow={
          hasAlerts
            ? '0 2px 7px 0 rgba(189,25,25,0.5)'
            : '4px 0 14px 0 rgba(65,71,95,0.17)'
        }
        cursor='pointer'
        onClick={onPress}
        pos='relative'
        size='50px'
      >
        {isEditMode && (
          <Circle
            _hover={{
              bgColor: '#d2d2d4',
            }}
            bgColor='#E5E8EE'
            boxShadow='0 2px 7px 0 rgba(50,121,199,0.5)'
            cursor='pointer'
            left='-6px'
            onClick={openEditFacility}
            pos='absolute'
            size='24px'
            top='-30px'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
            zIndex='overlay'
          >
            <HiOutlinePencil color='#3279C7' size='16px' />
          </Circle>
        )}
        <Box
          color={hasAlerts ? '#fff' : '#000'}
          data-testid='globalPage_facilityMarker_shortName'
          fontSize='14px'
          fontWeight='semibold'
          letterSpacing='-0.4px'
        >
          {facility.shortName}
        </Box>
        {hasAlerts && (
          <Circle
            bg='#fff'
            bottom='-6px'
            boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
            left='-6px'
            pos='absolute'
            size='24px'
            zIndex='overlay'
          >
            <HiOutlineExclamation color='#D01030' size={16} />
          </Circle>
        )}
        {hasAlerts && (
          <Circle
            bg='#fff'
            bottom='-6px'
            boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
            pos='absolute'
            right='-6px'
            size='24px'
            zIndex='overlay'
          >
            <Box
              color='#D01030'
              data-testid='globalPage_facilityMarker_countBadge'
              fontSize='12px'
              fontWeight='bold'
              letterSpacing='-0.4px'
            >
              {numCount}
            </Box>
          </Circle>
        )}
      </Circle>
    </Marker>
  )
}
