export const ALLOWED_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
  '.mp4',
  '.mov',
  '.mpg',
  '.mpeg',
]
export const ALLOWED_MIME_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/quicktime',
  'video/mpeg',
  'video/mpg',
  'application/pdf',
]
export const FILE_TYPES = [
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'mp4',
  'mov',
  'mpg',
  'mpeg',
]
export const ALLOWED_CHARS_REGEX = /^[a-zA-Z0-9 _\-.]+$/
export const BYTES_IN_MB = 1048576
export const MAX_FILES = 4
export const MAX_FILENAME_LENGTH = 255
export const MAX_FILE_SIZE = 524288000 // 500 MB
