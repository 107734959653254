import { Box, Grid } from '@chakra-ui/react'

import { NotificationsTab } from '@/features/notifications-tab'

import { Drawers } from './Drawers'
import { SideBar } from './SideBar'

interface AppLayoutIProps {
  children?: React.ReactNode
}

export const AppLayout = ({ children }: AppLayoutIProps) => {
  return (
    <>
      <Grid bgColor='#F0F5FD' minH='100vh' templateColumns='max-content 1fr'>
        <SideBar />
        <Box pos='relative' w='full'>
          {children}
        </Box>
      </Grid>
      <NotificationsTab />
      <Drawers />
    </>
  )
}
