import { useLocalState } from '@/hooks/useLocalState'
import { useDebounce } from 'use-debounce'

type BoundsArray = [[number, number], [number, number]]

const parseBounds = (boundsString: string) => {
  const bounds = JSON.parse(boundsString) as BoundsArray
  return {
    northEastLatitude: bounds[1][1],
    northEastLongitude: bounds[1][0],
    southWestLatitude: bounds[0][1],
    southWestLongitude: bounds[0][0],
  }
}

export function useDataBounds() {
  const [dataBounds, setDataBounds] = useLocalState<string>(
    'bounds',
    '[[0,0],[0,0]]'
  )
  const [debouncedDataBounds] = useDebounce(dataBounds, 1000)

  return {
    setDataBounds,
    bounds: parseBounds(debouncedDataBounds),
  }
}
