import { MdCircle, MdOutlineCircle } from 'react-icons/md'

import { Box } from '@chakra-ui/react'

export interface MediaCounterIProps {
  index: number
  items: string[]
}

export const MediaCounter = ({ index, items }: MediaCounterIProps) => {
  return (
    <Box
      alignContent='center'
      bottom='20px'
      display='flex'
      flexDirection='row'
      justifyContent='center'
      position='absolute'
      width='100%'
      zIndex={1}
    >
      {items.map((_, i) => {
        return index === i ? (
          <Box key={i} p='2px'>
            <MdCircle size='16px' />
          </Box>
        ) : (
          <Box key={i} p='2px'>
            <MdOutlineCircle size='16px' />
          </Box>
        )
      })}
    </Box>
  )
}
