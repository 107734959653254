import React, { useState } from 'react'

import { BsEyeFill } from 'react-icons/bs'

import { Flex, IconButton, useDisclosure } from '@chakra-ui/react'

import { IncidentNoteVideoPlayer } from '../../add-note/components/IncidentNoteVideoPlayer'
import { IncidentNoteMediaIProps, IncidentNotesMediaType } from '../types/types'
import { MediaPreviewerModal } from './MediaPreviewerModal'
import { RenderMediaFile } from './RenderMediaFile'

export const MediaGrid = ({ files }: IncidentNoteMediaIProps) => {
  const { onClose, onOpen, isOpen } = useDisclosure()
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileClick = (file: IncidentNotesMediaType) => {
    onOpen()
    setSelectedFile(file)
  }

  const videos = files.filter((file) => file.mimeType.startsWith('video/'))
  const imagesAndPDFs = files.filter(
    (file) =>
      file.mimeType.startsWith('image/') || file.mimeType === 'application/pdf'
  )

  return (
    <Flex direction='column'>
      {videos.length > 0 && (
        <Flex
          alignItems='flex-start'
          flexWrap='wrap'
          gap='10px'
          justifyContent='space-between'
        >
          {videos.map((video) => (
            <Flex key={video.url} position='relative'>
              <IncidentNoteVideoPlayer videoUrl={video.url} />
              <IconButton
                aria-label='Expand'
                color='gray.500'
                icon={<BsEyeFill />}
                onClick={() => handleFileClick(video)}
                position='absolute'
                right='10px'
                size='sm'
                top='10px'
                zIndex='1'
              />
            </Flex>
          ))}
        </Flex>
      )}
      <Flex flexWrap='wrap' gap='10px' justifyContent='space-between' mt='10px'>
        {imagesAndPDFs.map((file) => (
          <RenderMediaFile
            file={file}
            key={file.name}
            onFileClick={handleFileClick}
          />
        ))}
      </Flex>
      {selectedFile && isOpen && (
        <MediaPreviewerModal
          file={selectedFile}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  )
}
