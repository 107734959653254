import { HiExclamation } from 'react-icons/hi'

import { Spinner } from '@chakra-ui/react'

import { VirusScanStatus } from '@/graphql/generated/schemas'

import { SOPType } from '../types/types'
import { SOPStatusBox } from './SOPStatusBox'

export const SOPDocument = ({
  sop,
  isLoading,
}: {
  sop: SOPType
  isLoading: boolean
}) => {
  const status = sop?.status
  if (isLoading || status === VirusScanStatus.Unscanned) {
    return (
      <SOPStatusBox isLoading={isLoading} title='Uploading'>
        <Spinner
          color='#298bbd'
          data-testid='SOPDocument:spinner'
          emptyColor='gray.300'
          mt='10px'
          size='lg'
          thickness='4px'
        />
      </SOPStatusBox>
    )
  }
  if (status === VirusScanStatus.Infected) {
    return (
      <SOPStatusBox title='Upload failed'>
        <HiExclamation color='#D01030' size='54px' />
      </SOPStatusBox>
    )
  }
  return (
    <embed
      data-testid='SOPDocument:embed'
      height='100%'
      src={sop?.url}
      type='application/pdf'
      width='100%'
    />
  )
}
