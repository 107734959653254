import { Box } from '@chakra-ui/react'

import { IncidentNoteVideoPlayer } from '../../add-note/components/IncidentNoteVideoPlayer'
import { RenderMediaFileIProps } from '../types/types'
import { FeedImageThumbnail } from './FeedImageThumbnail'
import { FeedPdfThumbnail } from './FeedPdfThumbnail'

export const RenderMediaFile = ({
  file,
  onFileClick,
}: RenderMediaFileIProps) => {
  const { name, url, mimeType } = file

  const handleClick = () => {
    onFileClick(file)
  }

  if (mimeType.startsWith('image/')) {
    return (
      <Box key={url} onClick={handleClick}>
        <FeedImageThumbnail file={file} />
      </Box>
    )
  } else if (mimeType === 'video/mp4' || mimeType === 'video/quicktime') {
    return (
      <Box key={url} onClick={handleClick}>
        <IncidentNoteVideoPlayer videoUrl={url} />
      </Box>
    )
  } else if (mimeType === 'application/pdf') {
    return (
      <Box key={url} onClick={handleClick}>
        <FeedPdfThumbnail handleClick={handleClick} name={name} />
      </Box>
    )
  } else {
    return null
  }
}
