import { FlexboxProps, HStack, Text } from '@chakra-ui/react'

interface HeaderCellIProps {
  label: string
  justifyContent: FlexboxProps['justifyItems']
}

export const HeaderCell = ({ label, justifyContent }: HeaderCellIProps) => {
  return (
    <HStack alignItems='center' justifyContent={justifyContent} spacing='5px'>
      <Text>{label}</Text>
    </HStack>
  )
}
