export const roleName = (
  role: string | 'GUARD' | 'PROGRAM_MANAGER' | 'IMPLEMENTER'
) => {
  switch (role) {
    case 'GUARD':
      return 'Guard'
    case 'PROGRAM_MANAGER':
      return 'PM'
    case 'IMPLEMENTER':
      return 'Implementer'
    default:
      return 'Operator'
  }
}
