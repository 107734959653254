import { CSSProperties } from 'react'

import { Box, Grid, VStack } from '@chakra-ui/react'

import { IncidentIcon } from '@/components/icons'
import { NotificationSubtitle } from '@/features/notification'
import { IncidentType } from '@/graphql/generated/schemas'

interface IncidentDataIProps {
  id: string
  name: string
  title: string
  dateTime: string
  facility: string
  type: IncidentType
  displayId: string
  handleClick: () => void
}

interface IncidentsListItemIProps {
  data: IncidentDataIProps[]
  index: number
  style?: CSSProperties
}

export const IncidentsListItem = ({
  data,
  index,
  style,
}: IncidentsListItemIProps) => {
  const { name, title, dateTime, facility, type, displayId, handleClick } =
    data[index]
  return (
    <Grid
      _hover={{
        bgColor: 'gray.50',
      }}
      alignItems='flex-start'
      cursor='pointer'
      gap='4'
      onClick={handleClick}
      p='3'
      style={style}
      templateColumns='24px auto'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='background-color'
    >
      <IncidentIcon incidentType={type} size={24} />
      <VStack alignItems='flex-start' pos='relative' spacing='6px'>
        <Box
          color='#2E6CDB'
          fontSize='13.5px'
          fontWeight='bold'
          letterSpacing='-0.47px'
          lineHeight='1'
          noOfLines={1}
        >
          {displayId} {title}
        </Box>
        <NotificationSubtitle
          facilityShortName={facility}
          fontSize='11px'
          sourceName={name}
          timestamp={dateTime}
        />
        <Box
          bgColor='#e2e8f0'
          bottom='-12px'
          h='1px'
          pos='absolute'
          w='calc(100% - 12px)'
        />
      </VStack>
    </Grid>
  )
}
