import { Circle, Grid } from '@chakra-ui/react'

interface IncidentsDrawerTitleIProps {
  title: string
  showCount?: boolean
  totalCount: number
}

export const IncidentsDrawerTitle = ({
  title,
  showCount = false,
  totalCount,
}: IncidentsDrawerTitleIProps) => {
  return (
    <Grid
      alignItems='center'
      gap='2'
      justifyContent='start'
      templateColumns='auto auto'
    >
      {title}
      {showCount && (
        <Circle
          bgColor='#D11D3A'
          color='#fff'
          fontSize='14px'
          fontWeight='bold'
          h='28px'
          paddingX='11px'
        >
          {totalCount ? totalCount : '-'}
        </Circle>
      )}
    </Grid>
  )
}
