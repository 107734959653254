import type { Feature } from 'geojson'

import {
  IncidentsByMarketFacilityQuery,
  OrganizationQuery,
} from '@/graphql/generated/operations'

import { ClusterPointIProps, PointType } from '../types/types'

export const clusterOptions = {
  map: (props: ClusterPointIProps) => ({
    type: props.type,
    incidentCount: props.incidentCount,
    facilityCount: props.type === PointType.Facility ? 1 : 0,
  }),
  reduce: (acc: ClusterPointIProps, props: ClusterPointIProps) => {
    if (props.type === PointType.Facility) {
      acc.facilityCount += props.facilityCount
    }
    if (props.incidentCount) {
      acc.incidentCount += props.incidentCount
    }
    return acc
  },
}

export function facilityToGeoJSONPoint(data: OrganizationQuery): Feature[] {
  return (
    data?.facilities?.edges?.map((f) => {
      const facility = f.node
      const feature: Feature = {
        type: 'Feature',
        id: facility.id,
        geometry: {
          type: 'Point',
          coordinates: [facility.longitude, facility.latitude],
        },
        properties: {
          id: facility.id,
          type: PointType.Facility,
          latitude: facility.latitude,
          longitude: facility.longitude,
          incidentCount: facility.incidents?.totalCount ?? 0,
          shortName: facility.shortName ?? `N/A`,
          floorId:
            facility.defaultFloorId || facility.floors.edges[0]?.node?.id,
        },
      }
      return feature
    }) || []
  )
}

export function marketIncidentsToGeoJSONPoint(
  data: IncidentsByMarketFacilityQuery
): Feature[] {
  return (
    data?.incidents?.edges?.map((f) => {
      const incident = f.node
      const feature: Feature = {
        type: 'Feature',
        id: incident.id,
        geometry: {
          type: 'Point',
          coordinates: [incident.longitude, incident.latitude],
        },
        properties: {
          id: incident.id,
          type: PointType.MarketIncident,
          name: incident.name,
          facilityName: incident.facility?.name,
          address: incident.address,
          displayId: incident.displayId,
          latitude: incident.latitude,
          longitude: incident.longitude,
        },
      }
      return feature
    }) || []
  )
}

export function dataToGeoJSONPoint(
  facilityData: OrganizationQuery,
  marketIncidentsData: IncidentsByMarketFacilityQuery
): Feature[] {
  const facilityPoints = facilityToGeoJSONPoint(facilityData)
  const marketIncidentPoints =
    marketIncidentsToGeoJSONPoint(marketIncidentsData)

  return [...facilityPoints, ...marketIncidentPoints]
}
