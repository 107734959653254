import React from 'react'

import { IoIosClose } from 'react-icons/io'

import { Flex, IconButton } from '@chakra-ui/react'

import { ImageThumbnail } from './ImageThumbnail'
import { PdfThumbnail } from './PdfThumbnail'
import { VideoThumbnail } from './VideoThumbnail'

interface MediaThumbnailIProps {
  file: File
  removeFile: (file: File) => void
}

export const MediaThumbnail = ({ file, removeFile }: MediaThumbnailIProps) => {
  const handleRemoveFile = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    removeFile(file)
  }

  return (
    <Flex
      alignItems='center'
      className='group relative'
      justifyContent='center'
    >
      <IconButton
        aria-label='Remove'
        className='rounded-full close-button absolute top-[-8px] right-[-5px] invisible group-hover:visible'
        colorScheme='gray'
        icon={<IoIosClose size={18} />}
        onClick={handleRemoveFile}
        size='20px'
        zIndex={10}
      />
      {file.type.startsWith('image/') && (
        <ImageThumbnail imgUrl={URL.createObjectURL(file)} />
      )}
      {file.type === 'application/pdf' && <PdfThumbnail file={file} />}
      {file.type.startsWith('video/') && <VideoThumbnail videoFile={file} />}
    </Flex>
  )
}
