import { useState } from 'react'

import { useDispatch } from '../hooks/useDispatch'
import { useGuards } from '../hooks/useGuards'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler } from 'react-hook-form'

import { Box, Icon, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'

import { Guards } from '@/components/icons'
import {
  Button,
  FormInputSelectControl,
  Textarea,
  VoiceRecorder,
} from '@/components/ui'
import { IncidentQueryResult } from '@/graphql/generated/hooks'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../../types/types'
import { GuardsFormInputs, allGuardsOption } from '../types/types'
import { getDispatchedGuardIds } from '../utils/guards'
import { DispatchMessage } from './DispatchMessage'
import { RadioGuardHeader } from './RadioGuardHeader'

interface DispatchGuardsIProps {
  incident?: IncidentQueryResult['data']['incident']
  mixpanelData?: MixpanelDataIProps
  onActionComplete?: () => void
}

export const DispatchGuards = ({
  incident,
  mixpanelData,
  onActionComplete,
}: DispatchGuardsIProps) => {
  const { shouldEnableIncidentRadioGuards } = usePermissions()
  const { showSuccess, showError } = useToasts()
  const [isLoading, setLoading] = useState(false)

  const {
    control,
    guards,
    guardsErrorMessage,
    guardsOptions,
    handleSubmit,
    isGuardsLoading,
    isGuardsValid,
  } = useGuards({ incident })

  const { audioFile, note, onNoteChange, onGenerateAudioFile, dispatch } =
    useDispatch({ incident })

  const onSubmit: SubmitHandler<GuardsFormInputs> = async (values) => {
    setLoading(true)
    try {
      await dispatch(getDispatchedGuardIds(values))
      onActionComplete?.()
      showSuccess('Incident successfully dispatched.')
    } catch (error) {
      showError()
    } finally {
      setLoading(false)
    }
  }

  const onRecording = () => {
    mixpanel.track(`${BUTTON_PRESS} Dispatch - Recording`, mixpanelData)
  }

  const onCancel = () => {
    mixpanel.track(`${BUTTON_PRESS} Dispatch - Cancel Button`, mixpanelData)
    onActionComplete?.()
  }

  return (
    <Box mb={8}>
      <RadioGuardHeader
        dateTime={format(new Date(), 'MM/dd/yyyy')}
        guards={guards}
        header='Dispatch Guards'
        isGuardsLoading={isGuardsLoading}
        subHeader='Select Guard to dispatch'
      />
      <Box mb='20px' mt='14px'>
        <FormInputSelectControl
          control={control}
          defaultValue={allGuardsOption}
          errorMessage={guardsErrorMessage}
          id='guards'
          isClearable
          isInvalid={!isGuardsValid}
          isMulti
          isSearchable
          options={[allGuardsOption, ...guardsOptions]}
          placeholder='Select Guards'
          rules={{
            required: true,
            validate: (values) => values.length !== 0,
          }}
          shouldAnimate={false}
        />
      </Box>

      <DispatchMessage
        noteTextarea={
          <Textarea
            id='dispatchNote'
            onChange={onNoteChange}
            placeholder='Add a comment...'
            value={note}
          />
        }
        voiceRecorder={
          <VoiceRecorder
            getAudioFile={onGenerateAudioFile}
            hasFile={!!audioFile}
            onRecording={onRecording}
          />
        }
      />

      <Stack align='center' direction='row' mt={6} spacing={3}>
        <Button onClick={onCancel} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={
            isLoading || !shouldEnableIncidentRadioGuards || !isGuardsValid
          }
          isLoading={isLoading}
          leftIcon={<Icon as={Guards} boxSize={5} />}
          loadingText='Dispatching...'
          onClick={handleSubmit(onSubmit)}
        >
          Dispatch
        </Button>
      </Stack>
    </Box>
  )
}
