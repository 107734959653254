import { useRef } from 'react'

import { BiMessageRounded } from 'react-icons/bi'
import { HiOutlineExclamation } from 'react-icons/hi'
import { useOnClickOutside } from 'usehooks-ts'

import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import { Notifications } from '@/features/notification'
import { useResetNotificationCountMutation } from '@/graphql/appsync-notifications'
import { useGlobalUnresolvedIncidentsCountQuery } from '@/graphql/generated/hooks'

import { InboxDrawer } from '../inbox-drawer'
import { IncidentsDrawer } from '../incidents-drawer'
import { DrawerToggleButton } from './DrawerToggleButton'
import { InboxItemsCount } from './InboxItemsCount'
import { IncidentsCount } from './IncidentsCount'

export const NotificationsTab = () => {
  const { data: totalCountData } = useGlobalUnresolvedIncidentsCountQuery()
  const [resetNotificationCount] = useResetNotificationCountMutation({
    errorPolicy: 'all',
  })

  const totalCount = totalCountData?.globalUnresolvedIncidentsCount?.totalCount

  const {
    isOpen: isIncidentsDrawerOpen,
    onOpen: onIncidentsDrawerOpen,
    onClose: onIncidentsDrawerClose,
  } = useDisclosure()

  const {
    isOpen: isInboxDrawerOpen,
    onOpen: onInboxDrawerOpen,
    onClose: onInboxDrawerClose,
  } = useDisclosure()

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => {
    onIncidentsDrawerClose()
    onInboxDrawerClose()
  })

  const toggleInboxDrawer = async () => {
    onIncidentsDrawerClose()
    if (isInboxDrawerOpen) {
      onInboxDrawerClose()
    } else {
      onInboxDrawerOpen()
      await resetNotificationCount()
    }
  }

  const toggleIncidentsDrawer = () => {
    onInboxDrawerClose()
    isIncidentsDrawerOpen ? onIncidentsDrawerClose() : onIncidentsDrawerOpen()
  }

  // General open-close button
  const toggleDrawers = () => {
    if (isIncidentsDrawerOpen || isInboxDrawerOpen) {
      onInboxDrawerClose()
      onIncidentsDrawerClose()
    } else {
      onIncidentsDrawerOpen()
    }
  }

  return (
    <>
      <Notifications />
      <Box bottom='0' pos='fixed' ref={ref} right='0' w='450px' zIndex='docked'>
        <AnimatePresence exitBeforeEnter>
          {isIncidentsDrawerOpen && <IncidentsDrawer totalCount={totalCount} />}
          {isInboxDrawerOpen && <InboxDrawer />}
        </AnimatePresence>
        <Grid
          bg='white'
          borderTopRadius='10px'
          boxShadow='0px 2px 5px 6px rgba(0, 0, 0, 0.2)'
          h='42px'
          overflow='hidden'
          templateColumns='42px 250px auto 50px'
        >
          <Flex
            _hover={{
              bgColor: '#b40e2a',
            }}
            alignItems='center'
            bgColor='#D01030'
            boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
            cursor='pointer'
            data-testid='NotificationsTab:activeIncidentsIcon'
            flexDirection='column'
            justifyContent='center'
            onClick={toggleIncidentsDrawer}
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
          >
            <HiOutlineExclamation color='#fff' size={22} />
          </Flex>
          <Grid
            _hover={{
              bgColor: 'gray.200',
            }}
            alignItems='center'
            bgColor={isIncidentsDrawerOpen ? 'gray.200' : '#fff'}
            borderRight='1px solid #DCE8FA'
            cursor='pointer'
            data-testid='NotificationsTab:activeIncidents'
            gap='2'
            justifyContent='start'
            onClick={toggleIncidentsDrawer}
            px='3'
            templateColumns='auto auto'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
          >
            <Box
              color='#2D2E41'
              data-testid='notificationsTab_activeIncidents_title'
              fontSize='12px'
              fontWeight='bold'
              noOfLines={1}
              style={{
                lineBreak: 'anywhere',
              }}
            >
              Active Incidents
            </Box>
            <IncidentsCount />
          </Grid>
          <Grid
            _hover={{
              bgColor: 'gray.200',
            }}
            alignItems='center'
            bgColor={isInboxDrawerOpen ? 'gray.200' : '#fff'}
            borderRight='1px solid #DCE8FA'
            cursor='pointer'
            data-testid='notificationsTab_inbox_title'
            gap='2'
            justifyContent='center'
            onClick={toggleInboxDrawer}
            px='3'
            templateColumns='auto auto'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
          >
            <BiMessageRounded color='#4C535F' size={22} />
            <InboxItemsCount />
          </Grid>
          <Flex
            _hover={{
              bgColor: 'gray.200',
            }}
            alignItems='center'
            cursor='pointer'
            flexDirection='column'
            justifyContent='center'
            onClick={toggleDrawers}
            position='relative'
            px='3'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='background-color'
          >
            <DrawerToggleButton
              isOpen={isIncidentsDrawerOpen || isInboxDrawerOpen}
            />
          </Flex>
        </Grid>
      </Box>
    </>
  )
}
