import { Box, Grid, VStack } from '@chakra-ui/react'

import { formatDateTime } from '@/utils/formatDateTime'

function statusColor(status) {
  switch (status) {
    case 'RESOLVED':
      return '#29D11E'
    case 'ACTIVE':
      return '#D01030'
    case 'IN_PROGRESS':
      return '#D16A1E'
    default:
      break
  }
}

export const EventListItem = ({
  title,
  status,
  dateTime,
  description,
  handleClick,
}: {
  title: string
  status?: string
  eventType: string
  dateTime?: string
  description: string
  handleClick?: () => void
}) => {
  return (
    <Grid
      _hover={{
        bgColor: 'whitesmoke',
      }}
      alignItems='flex-start'
      borderBottom='1px'
      borderColor='#D5DCE4'
      cursor='pointer'
      gap='2'
      onClick={handleClick}
      px='24px'
      py='4'
      templateColumns='13px auto'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='background-color'
    >
      <Box bgColor={statusColor(status)} h='13px' rounded='4px' w='13px' />
      <VStack alignItems='flex-start' spacing='1'>
        <Box
          color='#2D2E41'
          fontSize='16px'
          fontWeight='semibold'
          letterSpacing='-0.47px'
          lineHeight='17px'
        >
          {title}
        </Box>
        <Box
          color='#2D2E41'
          fontSize='14px'
          fontWeight='medium'
          letterSpacing='-0.63px'
          opacity='0.8'
        >
          {formatDateTime(dateTime)}
        </Box>
        <Box
          color='#2D2E41'
          fontSize='16px'
          fontWeight='medium'
          letterSpacing='-0.53px'
          lineHeight='17px'
          opacity='0.6'
        >
          {description}
        </Box>
      </VStack>
    </Grid>
  )
}
