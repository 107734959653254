import { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import {
  IncidentQueryResult,
  useFacilityGuardsQuery,
} from '@/graphql/generated/hooks'
import { OptionI, getGuardOptions } from '@/utils/forms/optionBuilders'
import { getOptionsArray } from '@/utils/forms/optionHelpers'

import { GuardsFormInputs, allGuardsOption } from '../types/types'
import { isAllGuardsOption, isSpecificGuardOption } from '../utils/guards'

interface UseGuardsIProps {
  incident?: IncidentQueryResult['data']['incident']
}

export const useGuards = ({ incident }: UseGuardsIProps) => {
  const [isDispatchAll, setIsDispatchAll] = useState(true)

  const facilityId = incident?.facility?.id
  const alreadyDispatchedGuardIds = incident?.dispatchedGuardIds || []

  const { data: guardsData, loading: isGuardsLoading } = useFacilityGuardsQuery(
    {
      variables: { facilityId },
      fetchPolicy: 'network-only',
    }
  )

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm<GuardsFormInputs>({
    mode: 'onChange',
  })

  const guards = guardsData?.checkedInUsers?.edges.map((edge) => edge.node)

  // Guards that have already been dispatched by a specific dispatch should be removed from options
  const guardsOptions = getGuardOptions(guardsData).filter(
    (option) => !alreadyDispatchedGuardIds.includes(option.value)
  )

  const setAllGuards = () => {
    setValue('guards', allGuardsOption)
  }

  const removeAllGuards = (options: OptionI[]) => {
    setValue('guards', options.filter(isSpecificGuardOption))
  }

  useEffect(() => {
    const options = watch('guards')
    const optionsArray = getOptionsArray(options)

    const selectedOptionsCount = optionsArray.length
    const isNextDispatchAll =
      optionsArray?.find(isAllGuardsOption) !== undefined

    // if specific guard(s) is selected on top of "all guards", the "all guards" options should be removed
    if (isDispatchAll && isNextDispatchAll && selectedOptionsCount > 1) {
      removeAllGuards(optionsArray)
    }

    // if "all guards" is selected on top of already selcted specific guard(s) it should remain as the only option
    if (!isDispatchAll && isNextDispatchAll && selectedOptionsCount > 1) {
      setAllGuards()
    }

    setIsDispatchAll(isNextDispatchAll)
  }, [watch('guards')])

  return {
    control,
    guards,
    guardsErrorMessage: !!errors?.guards && 'Guards are required',
    guardsOptions,
    handleSubmit,
    isGuardsLoading,
    isGuardsValid: isValid,
  }
}
