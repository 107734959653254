import { SubmittedTourStatus } from '@/features/submitted-tours-table'
import { SubmittedTourQuery } from '@/graphql/generated/operations'
import { formatDateTimeWithAt } from '@/utils/formatDateTime'

import { CheckpointIProps, SubmittedTourType } from '../types/types'

export const groupByFloor = (checkpoints: CheckpointIProps[]) => {
  return checkpoints?.reduce((acc, cur) => {
    acc[cur.floor] = acc[cur.floor] || []
    acc[cur.floor].push(cur)
    return acc
  }, Object.create(null))
}

export const formatCheckpoints = (
  checkpoints: SubmittedTourQuery['guardTour']['tourPlan']['checkpoints'],
  completedCheckpoints
) => {
  return checkpoints?.edges.map((checkpoint) => {
    const node = checkpoint.node
    const c = completedCheckpoints?.find(
      (cc) => cc?.checkpoint?.id === checkpoint?.node?.id
    )
    return {
      id: node?.id,
      displayId: node?.displayId,
      name: node?.device?.name,
      scanned: Boolean(c?.scannedAt),
      time: c?.scannedAt,
      floor: node?.device?.floor?.name,
    }
  })
}

export const formatData = (
  submittedTour: SubmittedTourQuery['guardTour']
): SubmittedTourType => {
  const completedCheckpoints = submittedTour?.completedCheckpoints?.edges?.map(
    (c) => c.node
  )
  return {
    id: submittedTour?.id,
    name: submittedTour?.tourPlan?.name,
    startedAt: formatDateTimeWithAt(submittedTour?.createdAt),
    submittedAt: formatDateTimeWithAt(submittedTour?.updatedAt),
    guardFullName: `${submittedTour?.guard?.firstName} ${submittedTour?.guard?.lastName}`,
    status:
      completedCheckpoints?.length <
      submittedTour?.tourPlan?.checkpointIds.length
        ? SubmittedTourStatus.INCOMPLETE
        : SubmittedTourStatus.COMPLETE,
    checkpoints: submittedTour?.completedCheckpoints
      ? Object?.entries(
          groupByFloor(
            formatCheckpoints(
              submittedTour?.tourPlan?.checkpoints,
              completedCheckpoints
            )
          )
        )
      : [],
  }
}
