import { ChangeEvent, useEffect, useState } from 'react'

import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdQrCode } from 'react-icons/md'

import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react'

import { Checkbox } from '@/components/ui'

export interface SelectCheckpointFlatListItemIProps {
  label: string
  subLabel: string
  selected?: boolean
  onChecked?: () => void
}

export const SelectCheckpointFlatListItem = ({
  label,
  subLabel,
  selected,
  onChecked,
}: SelectCheckpointFlatListItemIProps) => {
  const [isChecked, setChecked] = useState(selected)

  const onCheckboxChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
    onChecked?.()
  }

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  return (
    <HStack
      _hover={{
        backgroundColor: selected ? '#D8E2FF' : '#F1F2F4',
      }}
      backgroundColor={selected ? '#EDF0FF' : '#fff'}
      borderBottom='1px solid #CCCCCC'
      cursor='pointer'
      justifyContent='space-between'
      role='group'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='all'
    >
      <HStack
        alignItems='flex-start'
        flexShrink={1}
        isTruncated
        minWidth='0'
        px='15px'
        py='8px'
        spacing='12px'
      >
        <Checkbox
          borderColor='#44546F'
          boxShadow='rgba(68, 84, 111, 0.7)'
          color='#508FF9'
          isChecked={isChecked}
          onChange={onCheckboxChecked}
        />

        <Box>
          <Icon
            as={FaMapMarkerAlt}
            boxSize='24px'
            color={isChecked ? '#508FF9' : '#44546F'}
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='color'
          />
        </Box>

        <VStack align='flex-start' flexShrink={1} isTruncated spacing='0'>
          <Text
            color={isChecked ? '#005BBF' : '#172B4D'}
            fontSize='14px'
            fontWeight='medium'
            isTruncated
            lineHeight='20px'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='color'
            w='full'
          >
            {label}
          </Text>

          <Box w='full'>
            <HStack flexShrink={1} spacing='2px' w='full'>
              <Box>
                <Icon
                  as={MdQrCode}
                  boxSize='12px'
                  color={isChecked ? '#508FF9' : '#44546F'}
                  transition='ease-in-out'
                  transitionDuration='300ms'
                  transitionProperty='color'
                />
              </Box>
              <Text
                color={isChecked ? '#005BBF' : '#172B4D'}
                fontSize='12px'
                fontWeight={400}
                isTruncated
                lineHeight='18px'
                transition='ease-in-out'
                transitionDuration='300ms'
                transitionProperty='color'
                w='full'
              >
                {subLabel}
              </Text>
            </HStack>
          </Box>
        </VStack>
      </HStack>
    </HStack>
  )
}
