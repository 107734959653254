import { useWindowSize } from '@react-hook/window-size'
import { BiListUl } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Icon, Text, Tooltip } from '@chakra-ui/react'

import {
  selectSOPInicdentDrawerState,
  toggleSOPIncidentDrawer,
} from '@/redux/ui/uiSlice'

import { SOP_DRAWER_BREAKPOINT } from './SOPIncidentDrawer'

export const SOPIncidentDrawerToggle = ({
  showLabel = true,
}: {
  showLabel?: boolean
}) => {
  const { isOpen } = useSelector(selectSOPInicdentDrawerState)
  const dispatch = useDispatch()
  const [width] = useWindowSize()

  const onClick = () => {
    dispatch(toggleSOPIncidentDrawer())
  }

  const isDrawerDisabled = width < SOP_DRAWER_BREAKPOINT
  const isDrawerEnabled = !isDrawerDisabled

  return (
    <Tooltip
      bgColor='#fff'
      boxShadow='md'
      color='#1a202c'
      fontSize='16px'
      fontWeight='semibold'
      isDisabled={isDrawerEnabled && showLabel}
      label={
        isDrawerDisabled
          ? 'A wider screen is required for SOP functionality'
          : 'Standard Procedures'
      }
      letterSpacing='-0.65px'
      p='2'
      placement='bottom'
      rounded='5px'
      width={isDrawerDisabled ? '200px' : 'auto'}
    >
      <Box alignItems='center' display='flex'>
        <Button
          color='brand.100'
          disabled={isDrawerDisabled}
          fontSize='14px'
          m={0}
          onClick={onClick}
          variant='link'
        >
          <Icon
            as={isOpen ? MdClose : BiListUl}
            boxSize='26px'
            color='brand.100'
          />
          {showLabel ? <Text ml={2}>Standard Procedures</Text> : null}
        </Button>
      </Box>
    </Tooltip>
  )
}
