import React from 'react'

import Image from 'next/image'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { IncidentNoteVideoPlayer } from './IncidentNoteVideoPlayer'

export const PreviewMedia = ({
  onClose,
  isOpen,
  previewFile,
}: {
  onClose: () => void
  isOpen: boolean
  previewFile: File
}) => {
  const modalBodyStyles = {
    padding: '20px',
    maxWidth: '900px',
    maxHeight: '900px',
    objectFit: 'contain',
  }

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size='3xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='16px'>{previewFile.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody sx={modalBodyStyles}>
          {previewFile?.type.startsWith('image/') ? (
            <Image
              alt={previewFile.name}
              height={800}
              src={URL.createObjectURL(previewFile)}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
              width={800}
            />
          ) : previewFile?.type.startsWith('application/pdf') ? (
            <embed
              data-testid='AddNote:embed'
              height={800}
              src={URL.createObjectURL(previewFile)}
              type='application/pdf'
              width='100%'
            ></embed>
          ) : (
            <IncidentNoteVideoPlayer
              videoUrl={URL.createObjectURL(previewFile)}
            />
          )}
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
