import { useState } from 'react'

export const useMediaCounter = ({ images }: { images: string[] }) => {
  const [index, setIndex] = useState(0)
  const [isImageError, setIsImageError] = useState(false)

  const handleClickNext = () => {
    if (index + 1 < images.length) {
      setIsImageError(false)
      setIndex((prevIndex) => prevIndex + 1)
    }
  }
  const handleClickPrevious = () => {
    if (index - 1 >= 0) {
      setIsImageError(false)
      setIndex((prevIndex) => prevIndex - 1)
    }
  }
  const handleImageError = () => {
    setIsImageError(true)
  }
  return {
    index,
    isImageError,
    handleImageError,
    handleClickNext,
    handleClickPrevious,
  }
}
