import { FacilityListType } from '@/pages/facilities'

export const filterFacilities = (
  facilitiesList: FacilityListType,
  filter: string,
  hasActiveIncidents: boolean
): FacilityListType => {
  return (
    facilitiesList
      ?.filter(
        (f) =>
          f?.node?.name
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          f?.node?.address
            ?.join(', ')
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          filter === ''
      )
      .filter((f) => {
        if (hasActiveIncidents) {
          return f?.node?.incidents?.totalCount > 0
        } else if (hasActiveIncidents == false) {
          return f?.node?.incidents?.totalCount === 0
        }
        return true
      }) || []
  )
}
