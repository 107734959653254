export const getOrigin = () =>
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : ''

export const getTabColors = (isSelected: boolean) => {
  const bgColor = isSelected ? '#5f96f5' : '#e3eefb'
  const hoverBgColor = isSelected ? '#5f96f5' : '#1b253f'
  const iconColor = isSelected ? '#ffffff' : '#3d7fce'
  const hoverIconColor = isSelected ? '#ffffff' : '#5a6c9a'

  return { bgColor, hoverBgColor, iconColor, hoverIconColor }
}
