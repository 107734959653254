import { memo, useEffect, useMemo, useRef } from 'react'

import { useSelector } from 'react-redux'

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'

import { useIncidentQuery } from '@/graphql/generated/hooks'
import { selectMe } from '@/redux/me/meSlice'
import { selectIncident } from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'

import { Feed } from '../feed'
import { SOPIncidentDrawer } from '../sop-incident-drawer/components/SOPIncidentDrawer'
import { MixpanelDataIProps } from '../types/types'
import { IncidentActions } from './IncidentActions'
import { IncidentDrawerHeader } from './IncidentDrawerHeader'
import { IncidentMedia } from './IncidentMedia'

export interface IncidentDrawerIProps {
  isOpen: boolean
  onClose: () => void
}

export const IncidentDrawer = memo(
  ({ isOpen, onClose }: IncidentDrawerIProps) => {
    const incidentId = useSelector(selectIncident)?.id
    const me = useSelector(selectMe)
    const bodyRef = useRef<HTMLDivElement>()

    const {
      data,
      loading: isIncidentsLoading,
      refetch: refetchFeedItems,
    } = useIncidentQuery({
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        id: incidentId ?? '',
      },
    })

    const isLoading = !data || isIncidentsLoading
    const incident = data?.incident
    const device = incident?.devices[0]
    const isArchived = incident?.facility?.archived
    const shouldShowIncidentActions = !(isLoading || isArchived)

    const scrollToTop = () => {
      bodyRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const mixpanelData: MixpanelDataIProps = useMemo(() => {
      if (data) {
        return {
          incident_id: incidentId,
          incident_source: device?.type,
          incident_type: incident?.type,
          incident_status: incident?.status,
          device_id: device?.id,
          device_type: device?.type,
          facility_id: incident?.facility?.id,
          facility_address: incident?.facility?.address,
          floor_id: incident?.floor?.id,
          floor_name: incident?.floor?.name,
        }
      }
    }, [incidentId, data, incident?.type, device])

    useEffect(() => {
      if (isOpen) {
        if (data) {
          const date = new Date().toUTCString()
          mixpanel.track('Active Incident View, Drawer View', {
            user_id: me?.id,
            incident_id: incident?.id,
            event_at: date,
            incident_source: device?.type,
            incident_type: incident?.type,
            device_id: device?.id,
            device_type: device?.type,
            facility_id: incident?.facility?.id,
          })
        }
      }
    }, [isOpen, incidentId, data, incident?.type, device])

    return (
      <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='lg'>
        <DrawerOverlay />
        <DrawerContent boxShadow='xl'>
          <SOPIncidentDrawer />
          <DrawerHeader
            bgColor='#ffffff'
            borderBottom='1px solid #D5DCE4'
            p={0}
            pr='24px'
          >
            <IncidentDrawerHeader
              incident={incident}
              isLoading={isLoading}
              onClose={onClose}
            />
          </DrawerHeader>
          <DrawerBody bgColor='#ffffff' p={0} ref={bodyRef}>
            <IncidentMedia
              incident={incident}
              isLoading={isLoading}
              mixpanelData={mixpanelData}
              scrollToTop={scrollToTop}
            />
            {shouldShowIncidentActions && (
              <IncidentActions
                incident={incident}
                mixpanelData={mixpanelData}
                refetchFeedItems={refetchFeedItems}
              />
            )}
            <Feed incident={incident} mixpanelData={mixpanelData} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  }
)
