import { SubmittedToursQuery } from '@/graphql/generated/operations'
import { getArchivedLabel } from '@/utils/formats'

import { SubmittedTourStatus, SubmittedToursRowDataType } from '../types/table'

export const formatTableData = (
  submittedTours: SubmittedToursQuery['guardTours']['edges']
): SubmittedToursRowDataType[] =>
  submittedTours.map((submittedTour) => {
    const node = submittedTour?.node
    const facility = node?.tourPlan?.facility
    const completedCheckpoints = node?.completedCheckpoints?.edges?.map(
      (c) => c.node
    )
    return {
      id: node?.id,
      tourPlanId: node?.tourPlan?.id,
      facilityName: getArchivedLabel(facility?.name, facility?.archived),
      facilityId: node?.tourPlan?.facility?.id,
      facilityAddress: node?.tourPlan?.facility?.address[0],
      tourPlan: node?.tourPlan?.name,
      guardFullName: node?.guard?.firstName + ' ' + node?.guard?.lastName,
      submittedTime: node?.updatedAt,
      status:
        completedCheckpoints?.length < node.tourPlan.checkpointIds.length
          ? SubmittedTourStatus.INCOMPLETE
          : SubmittedTourStatus.COMPLETE,
    }
  })
