import { useSubmittedToursQuery } from '@/graphql/generated/hooks'

export enum SubmittedTourStatus {
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
}

export type SubmittedToursRowDataType = {
  id: string
  facilityId: string
  facilityName: string
  facilityAddress: string
  tourPlanId: string
  tourPlan: string
  guardFullName: string
  submittedTime: string
  status: SubmittedTourStatus
}

export type SubmittedToursListType = ReturnType<
  typeof useSubmittedToursQuery
>['data']['guardTours']['edges']

export type SubmittedToursRefetchType = ReturnType<
  typeof useSubmittedToursQuery
>['refetch']
