export enum FacilitiesTableModalType {
  Guards,
  Devices,
  Floors,
  Cameras,
  AC,
  Alerts,
  DeleteFacility,
  EditFacility,
}

export type OpenFacilitiesTableModalType = (
  modalType: FacilitiesTableModalType,
  facilityId: string
) => void

export type ModalType = {
  facilityId: string
  type: FacilitiesTableModalType
}
