import React from 'react'

import { useVideoThumbnail } from '../hooks/useVideoThumbnail'
import { FaPlay } from 'react-icons/fa'

import { HStack } from '@chakra-ui/react'

interface VideoThumbnailIProps {
  videoFile: File
}

export const VideoThumbnail = ({ videoFile }: VideoThumbnailIProps) => {
  const { thumbnail, videoRef } = useVideoThumbnail(videoFile)

  return (
    <HStack
      bgColor='#000000'
      borderRadius='md'
      height='50px'
      justifyContent='center'
      overflow='hidden'
      width='50px'
    >
      {thumbnail ? (
        <img
          alt='Video Thumbnail'
          src={thumbnail}
          style={{ width: '100%', height: 'auto' }}
        />
      ) : (
        <>
          <video
            ref={videoRef}
            src={URL.createObjectURL(videoFile)}
            style={{ display: 'none' }}
          />
          <FaPlay color='#fff' style={{ margin: 0 }} />
        </>
      )}
    </HStack>
  )
}
