import { usePermissions } from '@/hooks/usePermissions'
import { HiExclamation } from 'react-icons/hi'

import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import { Share } from '@/components/icons'
import { Button } from '@/components/ui'
import { IncidentActionType } from '@/graphql/generated/schemas'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  incidentName: string
  currentOwner: string
  displayId: string
  isLoading: boolean
  nextAction:
    | IncidentActionType.AcknowledgementByOperator
    | IncidentActionType.Resolved
}

const getIncidentActionDisplayName = (action: IncidentActionType) => {
  switch (action) {
    case IncidentActionType.AcknowledgementByOperator:
      return 'Acknowledge'
    case IncidentActionType.Resolved:
      return 'Resolve'
  }
}

export const TakeOverIncidentModal = ({
  isOpen,
  onClose,
  onSubmit,
  incidentName,
  currentOwner,
  displayId,
  isLoading,
  nextAction,
}: IProps) => {
  const { shouldEnableIncidentActions } = usePermissions()
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' overflow='hidden'>
        <ModalCloseButton color='#000' opacity={0.4} />

        <>
          <ModalBody p='0'>
            <Box px='10' py='6'>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                Take Over Incident
              </Box>
              <Box
                color='#8c8d97'
                fontSize='17px'
                letterSpacing='-0.4px'
                mt='6'
              >
                Incident Name:
              </Box>
              <Box
                color='#353849'
                data-testid='TakeOverIncidentModal:incidentDisplayName'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
              >
                {`${displayId} ${incidentName}`}
              </Box>
            </Box>
          </ModalBody>
          <Box px='4'>
            <Flex
              alignItems='center'
              color='#D11D3A'
              flexDirection='row'
              pb='5'
            >
              <HiExclamation size='24px' />
              <Box
                data-testid='TakeOverIncidentModal:message'
                fontSize='16px'
                fontWeight='medium'
                letterSpacing='-0.53px'
                ml='1'
              >
                Current owner is{' '}
                <Box as='span' fontWeight='bold'>
                  {currentOwner}
                </Box>
                . Are you sure you would like to{' '}
                <Box as='span' fontWeight='bold'>
                  Take Over and {getIncidentActionDisplayName(nextAction)}
                </Box>{' '}
                this Incident?
              </Box>
            </Flex>
            <ModalFooter px='0'>
              <Button mr={3} onClick={onClose} variant='secondary'>
                Cancel
              </Button>
              <Button
                isDisabled={!shouldEnableIncidentActions}
                isLoading={isLoading}
                leftIcon={<Icon as={Share} boxSize={6} strokeColor='#fff' />}
                loadingText='Taking Over...'
                onClick={onSubmit}
                variant='danger'
              >
                {`Take Over & ${getIncidentActionDisplayName(nextAction)}`}
              </Button>
            </ModalFooter>
          </Box>
        </>
      </ModalContent>
    </Modal>
  )
}
