import { Box, CloseButton, Flex } from '@chakra-ui/react'

import { AudioPlayer } from '@/components/ui'
import { Notification } from '@/graphql/appsync-notifications'
import { useNotificationAudioMessagesQuery } from '@/graphql/generated/hooks'

import { AudioNotificationTitle } from './AudioNotificationTitle'
import { NotificationIcon } from './NotificationIcon'
import { NotificationSubtitle } from './NotificationSubtitle'

export const AudioNotification = ({
  notification,
  onCancel,
}: {
  notification: Notification
  onCancel?: (incidentTimeStamp: string) => void
}) => {
  const { data } = useNotificationAudioMessagesQuery({
    variables: {
      id: notification.audioMessageId,
    },
  })

  return (
    <Flex
      alignItems='center'
      bg='white'
      borderRadius='10px'
      boxShadow='0px 2px 5px 6px rgba(0, 0, 0, 0.2)'
      flexDirection='row'
      h='120px'
      overflow='hidden'
      pe='10px'
    >
      <Flex
        bgColor='#D01030'
        boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
        h='full'
        transition='ease-in-out'
        w='10px'
      />
      <Box h='full' p='10px' position='relative' w='full'>
        <NotificationIcon
          boxShadow='rgba(189,25,25,0.5)'
          color='#D01030'
          size='20px'
        />
        <Box ml='30px' position='relative'>
          <AudioNotificationTitle notification={notification} />
          <NotificationSubtitle
            facilityShortName={notification?.facilityShortName}
            sourceName={notification?.incidentSourceName}
            timestamp={notification?.notificationTimestamp}
          />
          <Box
            color='#2D2E41'
            fontSize='13px'
            fontWeight='bold'
            letterSpacing='-0.53px'
            lineHeight='17px'
            mt='-18px'
            noOfLines={2}
          >
            {data?.audioMessages.edges[0].node.audioClip.url && (
              <AudioPlayer
                url={data?.audioMessages.edges[0].node.audioClip.url}
              />
            )}
          </Box>
        </Box>
      </Box>
      <CloseButton
        color='#D01030'
        onClick={(e) => {
          e.stopPropagation()
          onCancel(notification?.notificationTimestamp)
        }}
        opacity={0.8}
      />
    </Flex>
  )
}
