export const marketFacilites = {
  incidents: {
    edges: [
      {
        node: {
          id: '55dbca77-2e7c-40b7-a1b7-e062091b8876',
          name: 'Market Facility Incident - Address',
          displayId: 'INC-1',
          description: 'Test',
          latitude: 52.2296756,
          longitude: 21.0122287,
          address: ['Carlow, Ireland'],
          facility: {
            name: 'Facility Name',
          },
        },
      },
      {
        node: {
          id: '27690bd2-0dff-49eb-abad-3a7217853adc',
          name: 'Market Incident - Coordinates',
          displayId: 'INC-2',
          description: 'test',
          latitude: 50.2,
          longitude: 12.0,
          address: ['Carlow, Ireland'],
          facility: {
            name: 'Facility Name',
          },
        },
      },
      {
        node: {
          id: 'bca008da-56e6-4061-a64b-ce24bcb7b048',
          name: 'test mk inc 1',
          displayId: 'INC-3',
          description: 'test',
          latitude: 42.92084,
          longitude: -78.898551,
          address: ['Carlow, Ireland'],
          facility: {
            name: 'Facility Name',
          },
        },
      },
    ],
    totalCount: 3,
  },
}
