import { Box, Grid, Skeleton } from '@chakra-ui/react'

export const ListItemSkeleton = () => {
  return (
    <Box px='10' py='6'>
      <Skeleton h='28px' maxW='250px' w='full' />
      <Skeleton h='18px' maxW='180px' mt='3' w='full' />

      <Grid
        alignItems='center'
        gap='15px'
        gridTemplateColumns='24px auto'
        mt='3'
      >
        <Skeleton h='24px' mt='3' w='24px' />
        <Skeleton h='28px' maxW='220px' mt='3' w='full' />
      </Grid>
      <Skeleton h='18px' maxW='150px' mt='6' w='full' />
      <Grid gap='15px' gridTemplateColumns='24px auto' mt='3'>
        <Skeleton h='24px' mt='3' w='24px' />
        <Box>
          <Skeleton h='16px' maxW='100px' mt='3' w='full' />
          <Skeleton h='16px' maxW='80px' mt='3' w='full' />
        </Box>
      </Grid>
    </Box>
  )
}
