import { Font, StyleSheet } from '@react-pdf/renderer'

Font.register({
  family: 'Montserrat',
  fonts: [
    { fontWeight: 'normal', src: '/fonts/Montserrat-Regular.ttf' },
    { fontWeight: 'bold', src: '/fonts/Montserrat-Bold.ttf' },
  ],
})

//Disable hiphenation
Font.registerHyphenationCallback((word) => [word])

export const styles = StyleSheet.create({
  document: {
    fontFamily: 'Montserrat',
    fontSize: 14,
  },

  header: {
    backgroundColor: '#E8F2FF',
    fontSize: 22,
    color: '#465467',
    position: 'absolute',
    width: '600px',
    height: '10%',
    padding: '3%',
  },

  page: {
    padding: '5%',
  },

  view: {
    marginTop: '5%',
  },

  blueBg: {
    padding: '2%',
    borderRadius: '10%',
    height: 180,
    backgroundColor: 'rgb(210, 228, 255)',
  },

  facilityName: {
    backgroundColor: '#CDE1FF',
    padding: '2%',
    borderRadius: '10%',
    fontWeight: 'bold',
    color: '#002964',
    textAlign: 'center',
    marginTop: '5%',
    fontSize: 18,
    maxWidth: '40%',
  },

  bold: {
    fontWeight: 'bold',
  },

  mt10: {
    marginTop: '10%',
  },

  mt5: {
    marginTop: '5%',
  },

  mt2: {
    marginTop: '2%',
  },

  mt1: {
    marginTop: '2%',
  },

  ml2: {
    marginLeft: '2%',
  },

  ml15px: {
    marginLeft: '15px',
  },

  grid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },

  imgBg: {
    width: '250px',
    height: '250px',
    marginTop: '5%',
  },

  normal: {
    fontWeight: 'normal',
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  footer: {
    position: 'absolute',
    bottom: 20,
    textAlign: 'center',
    left: 0,
    right: 0,
    fontWeight: 'bold',
    fontSize: 12,
  },

  location: {
    marginLeft: '15px',
    width: '100%',
  },

  fs12: {
    fontSize: 12,
  },
})
