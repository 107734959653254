import { useForm } from 'react-hook-form'
import { HiArrowLeft } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
} from '@chakra-ui/react'

import { Button, FormInputSelectControl } from '@/components/ui'
import { useFacilityNamesQuery } from '@/graphql/generated/hooks'
import {
  selectCreatedAtFilter,
  selectFacilityHasIncidentsFilter,
  selectFacilityIdsFilter,
  setFacilityCreatedAtFilter,
  setFacilityHasIncidentsFilter,
  setFacilityIdsFilter,
} from '@/redux/ui/uiSlice'
import { activeIncidentsOptions, createdAtOptions } from '@/utils/filterDrawers'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const FacilityFilterDrawer = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()
  const { data } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })
  const { control, handleSubmit } = useForm({
    reValidateMode: 'onChange',
  })
  const idsFilterValue = useSelector(selectFacilityIdsFilter)
  const createdAtFilterValue = useSelector(selectCreatedAtFilter)
  const activeIncidentsFilterValue = useSelector(
    selectFacilityHasIncidentsFilter
  )
  const facilityOptions =
    data?.facilityNames?.edges.map((f) => ({
      label: `${f?.node?.name} - (${f?.node?.shortName})`,
      value: f?.node?.id,
    })) || []

  const onSubmit = async (values) => {
    const selectedFacilityIds = values?.name
    const selectedCreatedAtRange = values?.createdAt
    const selectedActiveIncidents = values?.activeIncidents
    dispatch(
      setFacilityIdsFilter(
        selectedFacilityIds?.length ? selectedFacilityIds : null
      )
    )
    dispatch(setFacilityCreatedAtFilter(selectedCreatedAtRange || null))
    dispatch(setFacilityHasIncidentsFilter(selectedActiveIncidents || null))
    onClose()
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='sm'>
      <DrawerOverlay data-testid='FacilityFilterDrawer:overlay' />
      <DrawerContent>
        <DrawerHeader
          alignItems='center'
          borderBottomWidth='1px'
          borderColor='#D5DCE4'
          d='flex'
          minH='65px'
          px='5'
          py='0'
        >
          <HStack spacing='3'>
            <IconButton
              aria-label='Back button'
              borderRadius='4px'
              data-testid='facilitiesPage_filterDrawer_backButton'
              h='30px'
              icon={<HiArrowLeft size={18} />}
              minWidth='30px'
              onClick={onClose}
              variant='ghost'
              w='30px'
            />
            <Box fontSize='20px' fontWeight='bold' letterSpacing='-0.53px'>
              Filter Facilities
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Box mb='3' mt='6'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='facilitiesPage_filterDrawer_facilityName'
              defaultValue={idsFilterValue?.length ? idsFilterValue : null}
              id='name'
              isClearable
              isMulti
              isSearchable
              label='Facility Name'
              options={facilityOptions}
              placeholder='Select Facility(-ies)'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='facilitiesPage_filterDrawer_createdAt'
              defaultValue={createdAtFilterValue || null}
              id='createdAt'
              isClearable
              isSearchable={false}
              label='Created At'
              options={createdAtOptions}
              placeholder='Select Time Range'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='facilitiesPage_filterDrawer_status'
              defaultValue={activeIncidentsFilterValue || null}
              id='activeIncidents'
              isClearable
              isSearchable={false}
              label='Active Incidents'
              options={activeIncidentsOptions}
              placeholder='Select Active Incidents Status'
            />
          </Box>
        </DrawerBody>
        <DrawerFooter borderColor='#D5DCE4' borderTopWidth='1px'>
          <Button
            data-testid='facilitiesPage_filterDrawer_applyButton'
            isFullWidth
            onClick={handleSubmit(onSubmit)}
          >
            Apply Filters
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
