import { useUpload } from '@/hooks/useUpload'

import { useCreateAudioClipMutation } from '@/graphql/generated/hooks'
import { UploadedFileType } from '@/graphql/generated/schemas'

type UseUploadAudioFile = () => UploadAudioFileFunction

export enum UploadAudioFileError {
  GenerateFileUploadUrlError = 'GenerateFileUploadUrlError',
  UploadFileError = 'UploadFileError',
  CreateAudioClipError = 'CreateAudioClipError',
}

interface UploadAudioFileFunctionIProps {
  file: File
  facilityId: string
  incidentId: string
}

export type UploadAudioFileFunction = ({
  file,
  facilityId,
  incidentId,
}: UploadAudioFileFunctionIProps) => Promise<string | UploadAudioFileError>

export const useUploadAudioFile: UseUploadAudioFile = () => {
  const [createAudioClip] = useCreateAudioClipMutation()
  const [upload] = useUpload()

  const uploadAudioFile: UploadAudioFileFunction = async ({
    file,
    facilityId,
    incidentId,
  }) => {
    const fileId = await upload({
      file,
      fileType: UploadedFileType.VoiceClip,
    })
    try {
      // Create DB entry for file
      const { data: audioClipData } = await createAudioClip({
        variables: {
          input: {
            endTime: new Date().toISOString(),
            startTime: new Date().toISOString(),
            externalId: fileId,
            facilityId,
            incidentId,
          },
        },
      })
      return audioClipData?.createAudioClip?.audioClip?.id
    } catch (error) {
      throw new Error(UploadAudioFileError.CreateAudioClipError)
    }
  }

  return uploadAudioFile
}
