import { useDispatch, useSelector } from 'react-redux'

import { DeviceDrawer } from '@/features/device-drawer'
import { IncidentDrawer } from '@/features/incident-drawer'
import { SOPDrawer } from '@/features/sop-drawer'
import { SubmittedTourDrawer } from '@/features/submitted-tour-drawer'
import {
  closeDeviceDrawer,
  closeIncidentDrawer,
  closeSOPDrawer,
  closeSubmittedTourDrawer,
  selectIsDeviceDrawerOpen,
  selectIsIncidentDrawerOpen,
  selectIsSOPDrawerOpen,
  selectSubmittedTourOpen,
  setDevice,
  setIncident,
  setSOP,
  setSubmittedTourId,
} from '@/redux/ui/uiSlice'

export const Drawers = () => {
  const dispatch = useDispatch()

  const handleCloseDeviceDrawer = () => {
    dispatch(setDevice({ id: null, type: null }))
    dispatch(closeDeviceDrawer())
  }

  const handleCloseIncidentDrawer = () => {
    dispatch(setIncident({ id: null }))
    dispatch(closeIncidentDrawer())
  }

  const handleCloseSOPDrawer = () => {
    dispatch(setSOP({ id: null }))
    dispatch(closeSOPDrawer())
  }

  const handleCloseSubmittedTourDrawer = () => {
    dispatch(setSubmittedTourId({ id: null }))
    dispatch(closeSubmittedTourDrawer())
  }

  const isIncidentDrawerOpen = useSelector(selectIsIncidentDrawerOpen)
  const isDeviceDrawerOpen = useSelector(selectIsDeviceDrawerOpen)
  const isSOPDrawerOpen = useSelector(selectIsSOPDrawerOpen)
  const isSubmittedTourOpen = useSelector(selectSubmittedTourOpen)

  return (
    <>
      {isIncidentDrawerOpen && (
        <IncidentDrawer
          isOpen={isIncidentDrawerOpen}
          onClose={handleCloseIncidentDrawer}
        />
      )}
      {isDeviceDrawerOpen && (
        <DeviceDrawer
          isOpen={isDeviceDrawerOpen}
          onClose={handleCloseDeviceDrawer}
        />
      )}
      {isSOPDrawerOpen && (
        <SOPDrawer isOpen={isSOPDrawerOpen} onClose={handleCloseSOPDrawer} />
      )}
      {isSubmittedTourOpen && (
        <SubmittedTourDrawer
          isOpen={isSubmittedTourOpen}
          onClose={handleCloseSubmittedTourDrawer}
        />
      )}
    </>
  )
}
