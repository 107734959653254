import { useEffect, useState } from 'react'

import {
  PaginationState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import { Navigation, PageSizeSelector } from '@/components/ui'
import { selectMe } from '@/redux/me/meSlice'
import {
  openSubmittedTourDrawer,
  selectSubmittedToursPageSize,
  setSubmittedTourId,
  setSubmittedToursPageSize,
} from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'
import { getCurrentRangeMessage } from '@/utils/pageNavigation'

import {
  SubmittedToursListType,
  SubmittedToursRefetchType,
} from '../types/table'
import { formatTableData } from '../utils/format'
import { TableSpinner } from './TableSpinner'
import { columns } from './columns'

interface SubmittedToursTableIProps {
  tours: SubmittedToursListType
  totalTours?: number
  refetch?: SubmittedToursRefetchType
}

const ROW_HEIGHT = 57

export const SubmittedToursTable = ({
  tours,
  totalTours,
  refetch,
}: SubmittedToursTableIProps) => {
  const dispatch = useDispatch()
  const me = useSelector(selectMe)
  const tableData = formatTableData(tours)

  const pageSize = useSelector(selectSubmittedToursPageSize)
  const pageCount = Math.ceil(totalTours / pageSize)

  const [isLoading, setIsLoading] = useState(false)
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  const onPageResize = (size: number) => {
    dispatch(setSubmittedToursPageSize(size))
  }

  const openDrawer = (id: string) => {
    dispatch(setSubmittedTourId({ id }))
    dispatch(openSubmittedTourDrawer())
  }

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      pagination: paginationState,
    },
    manualPagination: true,
    pageCount,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  useEffect(() => {
    setIsLoading(true)
    refetch({
      pageIndex: paginationState.pageIndex,
      pageSize: paginationState.pageSize,
    }).then(() => {
      setIsLoading(false)
    })
  }, [paginationState.pageIndex, paginationState.pageSize])

  return (
    <TableContainer display='grid' w='full'>
      <Table style={{ backgroundColor: 'white' }}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  _first={{
                    borderLeftRadius: '8px',
                  }}
                  _last={{
                    borderRightRadius: '8px',
                  }}
                  bg='#F6F5F8'
                  border='none'
                  color='#5F5F5F'
                  cursor='pointer'
                  fontSize='12px'
                  fontWeight={400}
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  position='relative'
                  py='16px'
                  textTransform='capitalize'
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {isLoading ? (
          <TableSpinner height={paginationState.pageSize * ROW_HEIGHT} />
        ) : (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                _hover={{
                  bgColor: '#fafafa',
                }}
                cursor='pointer'
                key={row.id}
                onClick={() => {
                  openDrawer(row.original.id)
                  mixpanel.track('Button Press: Submitted Tour Details', {
                    tour_plan_id: row.original.tourPlanId,
                    guard_tour_id: row.original.id,
                    guard_tour_status: row.original.status,
                    facility_address: row.original.facilityAddress ?? '',
                    facility_id: row.original.facilityId,
                    role: me.roleName,
                  })
                }}
                transition='ease-in-out'
                transitionDuration='300ms'
                transitionProperty='all'
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    color='#838692'
                    fontSize='14px'
                    fontWeight={500}
                    height={ROW_HEIGHT}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
      <Box
        alignItems='center'
        bg='#F6F5F8'
        color='#000000'
        display='grid'
        fontSize='12px'
        fontWeight={600}
        gridTemplateColumns='repeat(3, 1fr)'
        letterSpacing='-0.2px'
        px='16px'
        py='18px'
        rounded='8px'
        textTransform='none'
        w='full'
      >
        <Text alignSelf='left' p='10px' textAlign='left'>
          {getCurrentRangeMessage(
            paginationState.pageIndex,
            paginationState.pageSize,
            totalTours
          )}
        </Text>
        <Navigation
          gotoPage={table.setPageIndex}
          nextPage={table.nextPage}
          pageCount={pageCount}
          pageIndex={paginationState.pageIndex}
          previousPage={table.previousPage}
        />
        <PageSizeSelector
          onClick={onPageResize}
          pageSize={paginationState.pageSize}
          setPageSize={table.setPageSize}
          title='Tours per page:'
        />
      </Box>
    </TableContainer>
  )
}
