import { useEffect } from 'react'

import { useWindowSize } from '@react-hook/window-size'
import { useDispatch, useSelector } from 'react-redux'

import {
  closeSOPIncidentDrawer,
  selectSOPInicdentDrawerState,
} from '@/redux/ui/uiSlice'

import { SOPIncidentDetail } from './SOPIncidentDetail'
import { SOPIncidentDrawerContainer } from './SOPIncidentDrawerContainer'
import { SOPIncidentTable } from './SOPIncidentTable'

export const SOP_DRAWER_WIDTH = 810
export const INCIDENT_DRAWER_WIDTH = 625
export const SOP_DRAWER_BREAKPOINT = INCIDENT_DRAWER_WIDTH + SOP_DRAWER_WIDTH

export const SOPIncidentDrawer = () => {
  const { id, isOpen } = useSelector(selectSOPInicdentDrawerState)
  const dispatch = useDispatch()
  const [width] = useWindowSize()

  useEffect(() => {
    if (width < SOP_DRAWER_BREAKPOINT) {
      dispatch(closeSOPIncidentDrawer())
    }
  }, [width])

  return (
    isOpen && (
      <SOPIncidentDrawerContainer>
        {id === null ? <SOPIncidentTable /> : <SOPIncidentDetail id={id} />}
      </SOPIncidentDrawerContainer>
    )
  )
}
