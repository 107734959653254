import { Text, View } from '@react-pdf/renderer'

import { styles } from '../styles/styles'

export const EditableFields = () => {
  return (
    <>
      <View break style={{ ...styles.blueBg }}>
        <Text style={styles.bold}>Summary</Text>
      </View>
      <View style={{ ...styles.blueBg, ...styles.mt5 }}>
        <Text style={styles.bold}>Actions</Text>
      </View>
    </>
  )
}
