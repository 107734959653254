import { useState } from 'react'

import { As, Button, Icon, Tooltip } from '@chakra-ui/react'

import { getTabColors } from '../utils/helpers'

interface IncidentActionTabI {
  icon: As<any>
  label: string
  onClick: () => void
  isSelected: boolean
}

export const IncidentActionTab = ({
  icon,
  label,
  onClick,
  isSelected,
}: IncidentActionTabI) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const { bgColor, hoverBgColor, iconColor, hoverIconColor } =
    getTabColors(isSelected)

  return (
    <Tooltip
      arrow={hoverBgColor}
      bg={hoverBgColor}
      color='white'
      display={isSelected ? 'none' : 'inherit'}
      hasArrow
      isDisabled={isSelected}
      label={label}
      mt='5px'
      openDelay={250}
      p='10px'
    >
      <button
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onMouseOut={() => setIsHovering(false)}
        onMouseOver={() => setIsHovering(true)}
      >
        <Button
          _active={{ bgColor: hoverBgColor }}
          _focus={{ bgColor: hoverBgColor }}
          _hover={{ bgColor: hoverBgColor }}
          bgColor={bgColor}
          border='none'
          isSelected={isSelected}
          ml='5px'
          onClick={onClick}
        >
          <Icon
            as={icon}
            boxSize='18px'
            color={isHovering || isFocused ? hoverIconColor : iconColor}
            strokeColor={isHovering || isFocused ? hoverIconColor : iconColor}
          />
        </Button>
      </button>
    </Tooltip>
  )
}
