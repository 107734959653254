import { Flex, Grid, Skeleton, SkeletonCircle } from '@chakra-ui/react'

import { Card } from '@/components/ui'

export const DeviceListItemSkeleton = () => {
  return (
    <Card p='0'>
      <Grid templateColumns='40% 1fr'>
        <Grid
          alignItems='center'
          borderRight='1px solid #D5DCE4'
          gap='2'
          p='4'
          templateColumns='45px 1fr'
          w='full'
        >
          <SkeletonCircle size='45px' />
          <Skeleton h='20px' maxW='200px' w='full' />
        </Grid>
        <Grid templateColumns='1fr 1fr 1fr' w='full'>
          <Flex
            alignItems='center'
            borderRight='1px solid #D5DCE4'
            flexDirection='column'
            justifyContent='center'
            p='4'
          >
            <Skeleton h='20px' w='40px' />
            <Skeleton h='16px' my='2' w='35px' />
            <Skeleton h='22px' w='22px' />
          </Flex>
          <Flex
            alignItems='center'
            borderRight='1px solid #D5DCE4'
            flexDirection='column'
            justifyContent='center'
            p='4'
          >
            <Skeleton h='20px' w='40px' />
            <Skeleton h='16px' my='2' w='35px' />
            <Skeleton h='22px' w='22px' />
          </Flex>
          <Flex
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            p='1'
          >
            <Skeleton h='20px' w='40px' />
            <Skeleton h='16px' my='2' w='35px' />
            <Skeleton h='22px' w='22px' />
          </Flex>
        </Grid>
      </Grid>
    </Card>
  )
}
