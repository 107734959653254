import { Box } from '@chakra-ui/react'

import { getRoleDisplayName } from '@/features/managed-accounts'
import { NotificationSubtitle } from '@/features/notification'
import { Notification } from '@/graphql/appsync-notifications'

export const ClearNotification = ({
  notification,
  onClick,
}: {
  notification: Notification
  onClick: () => void
}) => {
  const senderDisplayName = `${getRoleDisplayName(
    notification?.incidentActionUserRole
  )} ${notification?.incidentActionUserName}`
  return (
    <Box cursor='pointer' flex={1} onClick={onClick}>
      <Box
        data-testid='ClearNotification:header'
        fontSize='13.5px'
        fontWeight='600'
        mb={1.5}
        noOfLines={2}
      >
        {senderDisplayName} cleared{' '}
        <Box as='span' color='#2E6CDB'>
          {notification.incidentDisplayId} {notification.incidentName}
        </Box>
      </Box>
      <NotificationSubtitle
        facilityShortName={notification?.facilityShortName}
        fontSize='11px'
        sourceName={notification?.incidentSourceName}
        timestamp={notification?.notificationTimestamp}
      />
    </Box>
  )
}
