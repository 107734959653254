import { ChangeEvent, useState } from 'react'

import { useUploadAudioFile } from './useUploadAudioFile'

import {
  IncidentDocument,
  IncidentQueryResult,
  useCreateIncidentActionMutation,
} from '@/graphql/generated/hooks'
import { IncidentActionType } from '@/graphql/generated/schemas'

interface UseDispatchIProps {
  incident?: IncidentQueryResult['data']['incident']
}

export const useDispatch = ({ incident }: UseDispatchIProps) => {
  const [note, setNote] = useState('')
  const [audioFile, setAudioFile] = useState(null)

  const facilityId = incident?.facility?.id
  const incidentId = incident?.id

  const uploadAudioFile = useUploadAudioFile()
  const [createIncidentAction] = useCreateIncidentActionMutation()

  const onGenerateAudioFile = ({ blob }: { blob: BlobPart }) => {
    setAudioFile(
      new File([blob], new Date().valueOf().toString(), {
        type: 'audio/wav',
      })
    )
  }

  const onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.currentTarget.value)
  }

  const dispatch = async (dispatchedGuardIds: string[]) => {
    const audioClipId = audioFile
      ? await uploadAudioFile({
          file: audioFile,
          facilityId,
          incidentId,
        })
      : null

    await createIncidentAction({
      variables: {
        input: {
          incidentId,
          type: IncidentActionType.Dispatch,
          dispatchedGuardIds,
          ...(audioClipId && { audioClipId }),
          ...(note && { message: note }),
        },
      },
      refetchQueries: [
        {
          query: IncidentDocument,
          variables: {
            id: incidentId ?? '',
          },
        },
      ],
    })

    setAudioFile(null)
  }

  return {
    audioFile,
    note,
    onNoteChange,
    onGenerateAudioFile,
    dispatch,
  }
}
