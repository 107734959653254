import { useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Box, Flex, Spinner } from '@chakra-ui/react'

import { DataTable, columns } from '@/features/sops-table'
import { useStandardOperatingProceduresQuery } from '@/graphql/generated/hooks'
import {
  OrderDirection,
  StandardOperatingProcedureOrderField,
} from '@/graphql/generated/schemas'
import {
  selectSOPInicdentDrawerState,
  setSOPIncidentId,
} from '@/redux/ui/uiSlice'

import { SOPIncidentHeader } from './SOPIncidentHeader'

const INITIAL_PAGE_SIZE = 25

export const SOPIncidentTable = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)

  const { sopNameLike } = useSelector(selectSOPInicdentDrawerState)
  const filter = { nameLike: sopNameLike }

  const {
    data,
    loading: isLoading,
    refetch,
  } = useStandardOperatingProceduresQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: filter,
      first: 0,
      last: pageSize,
      orderBy: [
        {
          direction: OrderDirection.Desc,
          field: StandardOperatingProcedureOrderField.CreatedAt,
        },
      ],
    },
  })

  const onItemClick = (id: string) => {
    dispatch(setSOPIncidentId({ id }))
  }

  const sopsCount = data?.standardOperatingProcedures.totalCount
  const sopList = data?.standardOperatingProcedures.edges ?? []

  const tableData = sopList.map((e) => {
    return {
      id: e?.node?.id,
      name: e?.node?.name,
      date: e?.node?.createdAt,
      creator: e?.node?.creator,
      facilities: e?.node?.facilities?.edges,
    }
  })

  const sopsTable = useMemo(
    () => (
      <DataTable
        columns={columns}
        data={tableData}
        filters={filter}
        onItemClick={onItemClick}
        onPageResize={setPageSize}
        pageSize={pageSize}
        refetch={refetch}
        total={sopsCount}
      />
    ),
    [sopList, sopNameLike]
  )

  return (
    <>
      <SOPIncidentHeader sopsCount={sopsCount} />

      <Box h='calc(100vh - 24px)' m='auto' overflow='auto' p='24px'>
        {isLoading && !data ? (
          <Flex justifyContent='center' mt='10'>
            <Spinner
              emptyColor='gray.300'
              size='lg'
              speed='0.65s'
              thickness='3px'
            />
          </Flex>
        ) : sopList?.length > 0 ? (
          sopsTable
        ) : (
          <Box textAlign='center'>List is empty</Box>
        )}
      </Box>
    </>
  )
}
