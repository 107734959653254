import { useNavigation } from '@/hooks/useNavigation'
import { HiArrowLeft } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

import {
  As,
  Flex,
  HStack,
  Icon,
  IconButton,
  SkeletonCircle,
  Spacer,
} from '@chakra-ui/react'

import {
  DeviceHealthPopover,
  DrawerBreadcrumb,
  DrawerTextClipboard,
} from '@/components/ui'
import { IncidentQueryResult } from '@/graphql/generated/hooks'
import { DeviceStatus, FacilityType } from '@/graphql/generated/schemas'
import { openDeviceDrawer, setDevice } from '@/redux/ui/uiSlice'
import { getArchivedLabel } from '@/utils/formats'

import { getOrigin } from '../utils/helpers'

const CustomIcon = ({ as, fontSize }: { as: As<any>; fontSize: string }) => {
  return <Icon as={as} fontSize={fontSize} />
}

interface IncidentDrawerHeaderIProps {
  incident: IncidentQueryResult['data']['incident']
  isLoading: boolean
  onClose: () => void
}

export const IncidentDrawerHeader = ({
  incident,
  isLoading,
  onClose,
}: IncidentDrawerHeaderIProps) => {
  const dispatch = useDispatch()
  const { goToFloorPlan } = useNavigation()
  const origin = getOrigin()

  const device = incident?.devices?.[0]
  const displayId = incident?.displayId
  const isArchived = incident?.facility?.archived

  const facilityLabel = incident?.facility?.shortName
  const floorLabel = `Floor ${incident?.floor?.name}`
  const deviceLabel = device?.name

  const incidentLink =
    origin && displayId ? `${origin}/incidents/?displayId=${displayId}` : ''

  const onFloorPlanOpen = () => {
    onClose()
    goToFloorPlan(incident?.floor?.id)
  }

  const onDeviceDrawerOpen = () => {
    dispatch(setDevice({ id: device?.id, type: device?.type }))
    dispatch(openDeviceDrawer())
  }

  const isNormalIncident = incident?.facility?.type === FacilityType.Normal

  return (
    <Flex bg='white' h='64px' w='full'>
      <HStack isTruncated pl='24px'>
        <IconButton
          aria-label='close incident drawer'
          borderRadius='md'
          h='30px'
          icon={<CustomIcon as={HiArrowLeft} fontSize='18px' />}
          minWidth='30px'
          onClick={onClose}
          variant='ghost'
          w='30px'
        />
        <DrawerTextClipboard
          copyMessage='Copy link to incident'
          isLoading={isLoading}
          label={displayId}
          link={incidentLink}
        />
        <DrawerBreadcrumb
          dataTestId='incidentDrawer_header_incidentName'
          isLoading={isLoading}
          label={incident?.name}
        />
        {isNormalIncident && (
          <>
            <DrawerBreadcrumb
              dataTestId='incidentDrawer_header_deviceName'
              disabled={isArchived}
              isLoading={isLoading}
              label={deviceLabel}
              onClick={onDeviceDrawerOpen}
              tooltipLabel={getArchivedLabel(deviceLabel, isArchived)}
            />
            <DrawerBreadcrumb
              dataTestId='incidentDrawer_header_floor'
              disabled={isArchived}
              isLoading={isLoading}
              label={floorLabel}
              onClick={onFloorPlanOpen}
              tooltipLabel={getArchivedLabel(floorLabel, isArchived)}
            />
          </>
        )}
        <DrawerBreadcrumb
          dataTestId='incidentDrawer_header_facilityShortName'
          disabled={isArchived}
          hideDivider={true}
          isLoading={isLoading}
          label={facilityLabel}
          onClick={isNormalIncident ? onFloorPlanOpen : undefined}
          tooltipLabel={getArchivedLabel(facilityLabel, isArchived)}
        />
      </HStack>
      <Spacer />
      {device && (
        <HStack spacing='18px'>
          {isLoading ? (
            <SkeletonCircle size='30px' />
          ) : (
            <DeviceHealthPopover
              deviceIsUnhealthy={device?.status === DeviceStatus.Unhealthy}
            />
          )}
        </HStack>
      )}
    </Flex>
  )
}
