import { useState } from 'react'

import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react'

import { Guards } from '@/components/icons'
import { MenuSelectItem } from '@/components/ui'

import { getTabColors } from '../../utils/helpers'

export interface GuardActionTabIProps {
  onDispatchClick: () => void
  onRadioClick: () => void
  isDispatchEnabled: boolean
  isRadioEnabled: boolean
  isSelected: boolean
}

export const GuardActionTab = ({
  onDispatchClick,
  onRadioClick,
  isDispatchEnabled,
  isRadioEnabled,
  isSelected,
}: GuardActionTabIProps) => {
  const { bgColor, hoverBgColor, iconColor, hoverIconColor } =
    getTabColors(isSelected)

  const [isHovering, setIsHovering] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Menu
      closeOnBlur={true}
      gutter={0}
      isLazy={true}
      matchWidth={false}
      onClose={() => setIsMenuOpen(false)}
      onOpen={() => setIsMenuOpen(true)}
    >
      <MenuButton
        _active={{ bgColor: hoverBgColor }}
        _focus={{ bgColor: hoverBgColor }}
        _hover={{ bgColor: hoverBgColor }}
        as={Button}
        bgColor={bgColor}
        border='none'
        isSelected={isSelected}
        ml='5px'
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onMouseOut={() => setIsHovering(false)}
        onMouseOver={() => setIsHovering(true)}
      >
        <Icon
          as={Guards}
          boxSize='18px'
          color={
            isHovering || isFocused || isMenuOpen ? hoverIconColor : iconColor
          }
          strokeColor={
            isHovering || isFocused || isMenuOpen ? hoverIconColor : iconColor
          }
        />
      </MenuButton>
      <MenuList p={0}>
        <MenuSelectItem
          isDisabled={!isDispatchEnabled}
          label='Dispatch Guards'
          onClick={onDispatchClick}
        />
        <MenuSelectItem
          isDisabled={!isRadioEnabled}
          label='Radio Guards'
          onClick={onRadioClick}
          showDivider={false}
        />
      </MenuList>
    </Menu>
  )
}
