import { Text, View } from '@react-pdf/renderer'

import { styles } from '../styles/styles'

export const Footer = () => {
  return (
    <View fixed style={styles.footer}>
      <Text>Confidential</Text>
    </View>
  )
}
