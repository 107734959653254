import { useSelector } from 'react-redux'

import { Flex, Text, Tooltip } from '@chakra-ui/react'

import { TableEditRemoveButton } from '@/features/sop-crud'
import { FacilityEdge } from '@/graphql/generated/schemas'
import { selectEditMode } from '@/redux/ui/uiSlice'

export const FacilitiesCell = ({
  facilities,
  onRemove,
  onEdit,
}: {
  facilities: FacilityEdge[]
  onRemove: () => void
  onEdit: () => void
}) => {
  const isEditMode = useSelector(selectEditMode)
  const names = facilities?.map((f) => f?.node?.name)

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Tooltip
        hasArrow
        label={names?.join(', ')}
        openDelay={500}
        placement='auto'
      >
        <Flex dir='row' w='calc(100% - 80px)'>
          {facilities?.map((f) => {
            if (f?.node?.archived) {
              return
            }
            return (
              <Text
                bg='#ebeff9'
                borderRadius='20px'
                color='brand.600'
                data-testid='sopsPage_table_facilityCell'
                fontSize='11px'
                key={f?.node?.id}
                mr='4px'
                noOfLines={1}
                px='8px'
              >
                {f?.node?.name}
              </Text>
            )
          })}
        </Flex>
      </Tooltip>
      {isEditMode && (
        <TableEditRemoveButton
          handleOnEdit={onEdit}
          handleOnRemove={onRemove}
        />
      )}
    </Flex>
  )
}
