import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

import { Box, Skeleton } from '@chakra-ui/react'

import { ArrowButton } from './ArrowButton'
import { MediaCounter } from './MediaCounter'

interface MediaControlsIProps {
  index: number
  items: string[]
  loading: boolean
  onChevronClick?: (index: number) => void
  onClickPrevious?: () => void
  onClickNext?: () => void
  children: React.ReactNode
}

export const MediaControls = ({
  index,
  items,
  loading,
  onClickPrevious,
  onClickNext,
  children,
}: MediaControlsIProps) => {
  const isFirstSlide = index == 0
  const isLastSlide = index == items.length - 1
  return (
    <Skeleton
      bg='#D9D9D9'
      borderRadius='0.375rem'
      borderWidth='1px'
      h='100%'
      isLoaded={!loading}
      maxW='100%'
      w='577px'
    >
      <Box pos='relative'>
        {items?.length > 1 && (
          <Box>
            <ArrowButton
              isDisabled={isFirstSlide}
              onClick={onClickPrevious}
              style={{ paddingLeft: '0', left: '0' }}
            >
              <MdArrowBackIosNew
                color={isFirstSlide ? 'white' : 'black'}
                size='48px'
              />
            </ArrowButton>

            <ArrowButton
              isDisabled={isLastSlide}
              onClick={onClickNext}
              style={{ paddingRight: '0', right: '0' }}
            >
              <MdArrowForwardIos
                color={isLastSlide ? 'white' : 'black'}
                size='48px'
              />
            </ArrowButton>
            <MediaCounter index={index} items={items} />
          </Box>
        )}
        {children}
      </Box>
    </Skeleton>
  )
}
