import { Box, HStack, Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'

const Container = styled(motion.div)`
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
  box-shadow: 0px 2px 5px 6px rgba(0, 0, 0, 0.2);
`

interface DrawerContainerIProps {
  title: string | React.ReactNode
  loading?: boolean
  children: React.ReactNode
}

export const DrawerContainer = ({
  title,
  loading = false,
  children,
}: DrawerContainerIProps) => {
  return (
    <Container
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
    >
      <HStack
        borderBottom='2px solid #e2e8f0'
        justifyContent='space-between'
        px='1rem'
        py='0.75rem'
        w='full'
      >
        <Box
          color='#2D2E41'
          display='flex'
          flex={1}
          fontSize='22px'
          fontWeight='bold'
          letterSpacing='-0.6px'
        >
          {title}
        </Box>
        <AnimatePresence>
          {loading && (
            <Spinner
              animate={{ opacity: 1 }}
              as={motion.div}
              color='#298bbd'
              emptyColor='gray.300'
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              size='md'
              thickness='4px'
            />
          )}
        </AnimatePresence>
      </HStack>
      <Box h='500px'>{children}</Box>
    </Container>
  )
}
