import { createContext, useContext, useEffect, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import {
  FacilitiesTableModalType,
  OpenFacilitiesTableModalType,
} from '../facilities-table-modal'
import { ModalType } from '../facilities-table-modal/types/types'

export const FacilitiesContext =
  createContext<FacilitiesContextValuesIProps | null>(null)

interface FacilitiesContextValuesIProps {
  modal: ModalType
  isFacilitiesTableModalOpen: boolean
  onFacilitiesTableModalOpen: () => void
  onFacilitiesTableModalClose: () => void
  handleGuardsClick: (facilityId: string) => void
  handleDevicesClick: (facilityId: string) => void
  handleFloorsClick: (facilityId: string) => void
  handleAlertsClick: (facilityId: string) => void
  handleCamerasClick: (facilityId: string) => void
  handleACClick: (facilityId: string) => void
  handleOnEdit: (facilityId: string) => void
  handleOnDelete: (facilityId: string) => void
}

export const FacilitiesProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const [modal, setModal] = useState<ModalType | null>(null)
  const {
    isOpen: isFacilitiesTableModalOpen,
    onOpen: onFacilitiesTableModalOpen,
    onClose: onFacilitiesTableModalClose,
  } = useDisclosure()

  const openFacilitiesTableModal: OpenFacilitiesTableModalType = (
    modalType,
    facilityId
  ) => {
    setModal({ facilityId, type: modalType })
  }

  const handleGuardsClick = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.Guards, facilityId)
  }

  const handleDevicesClick = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.Devices, facilityId)
  }

  const handleFloorsClick = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.Floors, facilityId)
  }

  const handleAlertsClick = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.Alerts, facilityId)
  }

  const handleCamerasClick = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.Cameras, facilityId)
  }

  const handleACClick = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.AC, facilityId)
  }

  const handleOnEdit = (facilityId: string) => {
    openFacilitiesTableModal(FacilitiesTableModalType.EditFacility, facilityId)
  }

  const handleOnDelete = (facilityId: string) => {
    openFacilitiesTableModal(
      FacilitiesTableModalType.DeleteFacility,
      facilityId
    )
  }

  useEffect(() => {
    if (modal) {
      onFacilitiesTableModalOpen()
    }
  }, [modal])

  return (
    <FacilitiesContext.Provider
      value={{
        isFacilitiesTableModalOpen,
        modal,
        onFacilitiesTableModalOpen,
        onFacilitiesTableModalClose,
        handleGuardsClick,
        handleDevicesClick,
        handleFloorsClick,
        handleAlertsClick,
        handleCamerasClick,
        handleACClick,
        handleOnEdit,
        handleOnDelete,
      }}
    >
      {children}
    </FacilitiesContext.Provider>
  )
}

export const useFacilitiesModals = () => useContext(FacilitiesContext)
