import React from 'react'

import ReactPlayer from 'react-player'

interface IncidentNoteVideoPlayerIProps {
  height?: string | number
  play?: boolean
  videoUrl: string
  width?: string | number
}

export const IncidentNoteVideoPlayer = ({
  height = '100%',
  play = false,
  videoUrl,
  width = '100%',
}: IncidentNoteVideoPlayerIProps) => {
  return (
    <ReactPlayer
      controls
      data-testid='incident-note-video-player'
      height={height}
      loop
      muted
      playing={play}
      url={videoUrl}
      width={width}
    />
  )
}
