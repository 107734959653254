import { Box } from '@chakra-ui/react'

export const NotificationIcon = ({
  color,
  size,
  boxShadow,
}: {
  color: string
  size: string
  boxShadow: string
}) => {
  return (
    <Box
      alignItems='center'
      bgGradient={`linear(to-t, ${color}, ${color})`}
      borderRadius='base'
      boxShadow={`0 2px 7px 0 ${boxShadow}`}
      h={size}
      justifyContent='center'
      mt='3px'
      position='absolute'
      w={size}
      zIndex='2'
    />
  )
}
