import { ChangeEvent, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { Box, CloseButton, Spacer } from '@chakra-ui/react'

import { CountBadge } from '@/components/ui'
import { SearchInput } from '@/components/ui'
import {
  closeSOPIncidentDrawer,
  selectSOPInicdentDrawerState,
  setSOPIncidentNameLike,
} from '@/redux/ui/uiSlice'

interface SOPIncidentHeaderIProps {
  sopsCount: number
}

export const SOPIncidentHeader = ({ sopsCount }: SOPIncidentHeaderIProps) => {
  const dispatch = useDispatch()

  const { sopNameLike } = useSelector(selectSOPInicdentDrawerState)
  const [nameFilter, setNameFilter] = useState(sopNameLike)
  const [debouncedNameFilter] = useDebounce(nameFilter, 1000)

  useEffect(() => {
    dispatch(setSOPIncidentNameLike(debouncedNameFilter))
  }, [debouncedNameFilter])

  const onNameFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNameFilter(e.target.value)
  }

  const onClose = () => {
    dispatch(closeSOPIncidentDrawer())
  }

  return (
    <Box
      alignItems='center'
      borderBottom='1px solid #D5DCE4'
      display='flex'
      px='24px'
      py='12px'
    >
      <CountBadge count={sopsCount} title='SOPs' />
      <Spacer />
      <Box>
        <SearchInput
          onChange={onNameFilterChange}
          placeholder='Search or Filter'
          value={nameFilter ?? ''}
        />
      </Box>
      <CloseButton ml={4} onClick={onClose} />
    </Box>
  )
}
