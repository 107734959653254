export class UploadError extends Error {}

export const UPLOAD_FAILED = 'Failed to upload file.'

export const FILE_INCORRECT_TYPE = 'File has incorrect type.'
export const FILE_TOO_LARGE = 'File is too large.'
export const FILENAME_INCORRECT_CHARACTERS =
  'Filename can only contain letters, numbers and ".", "-", "_".'

export const FILENAME_TOO_LONG = (maxLength: number) =>
  `Filename should have less than ${maxLength} characters.`
export const FILE_INCORRECT_EXTENSION = (extension: string) =>
  `File should have ${extension} extension.`
export const FILE_INCORRECT_EXTENSIONS = (extensions: string[]) =>
  `Incorrect file extension. Allowed extensions: ${extensions.join(' ')}`
