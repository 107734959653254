import { Text, TextProps } from '@chakra-ui/react'

import { SubmittedTourStatus } from '../types/table'

export const TourStatusBadge = (
  props: TextProps & {
    status: SubmittedTourStatus
  }
) => {
  const badgeColor =
    props.status === SubmittedTourStatus.COMPLETE
      ? 'incident.resolved.100'
      : 'incident.active.100'

  return (
    <Text
      backgroundColor={badgeColor}
      borderRadius='4px'
      color='white'
      data-testid='submittedToursPage_table_status'
      fontSize='14px'
      fontWeight='bold'
      minWidth='100px'
      padding='2px 7px'
      textAlign='center'
      {...props}
    >
      {props.status}
    </Text>
  )
}
