import { useEffect, useRef, useState } from 'react'

import { useNavigation } from '@/hooks/useNavigation'
import { BiVideo } from 'react-icons/bi'
import { HiArrowLeft, HiOutlineExclamation } from 'react-icons/hi'

import {
  Box,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  SkeletonCircle,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useInViewRef } from 'rooks'

import { DrawerBreadcrumb, DrawerTabs, ShowVideoButton } from '@/components/ui'
import {
  VideoFeedEvent,
  VideoPlayer,
  useCameraFeed,
} from '@/features/video-player'
import { useDeviceQuery } from '@/graphql/generated/hooks'
import { AnalyticsScreen, useMixpanel } from '@/utils/analytics'
import { getDeviceStatusColor } from '@/utils/devices'

import { DeviceAlerts } from './DeviceAlerts'

interface IProps {
  defaultIndex: number
  deviceId: string
  isOpen: boolean
  onClose: () => void
}

export const CameraDeviceDrawer = ({
  defaultIndex,
  isOpen,
  onClose,
  deviceId,
}: IProps) => {
  const { goToFloorPlan } = useNavigation()
  const { data, loading: isDeviceLoading } = useDeviceQuery({
    variables: {
      id: deviceId,
    },
    nextFetchPolicy: 'network-only',
  })
  const isLoading = !data || isDeviceLoading
  const [index, setIndex] = useState(defaultIndex)
  const [isPipActive, setPIPActive] = useState(false)
  const [videoRef, isInView] = useInViewRef()
  const bodyRef = useRef<HTMLDivElement>()
  const device = data?.device
  const incidents = device?.incidents?.edges?.map((i) => i?.node)

  const { createCameraEventHandler, createPlayerActionHandler } = useMixpanel(
    AnalyticsScreen.DeviceDrawer
  )

  const mixpanelData = () => {
    return {
      device_id: deviceId,
      facility_id: data?.device?.facility?.id,
      facility_address: data?.device?.facility?.address,
      floor_id: data?.device?.floor?.id,
      floor_name: data?.device?.floor?.name,
    }
  }
  const handleCameraEvent = createCameraEventHandler(mixpanelData())
  const handlePlayerAction = createPlayerActionHandler(mixpanelData())

  const {
    url: cameraFeedUrl,
    isLoading: isFeedDataLoading,
    errorMessage,
  } = useCameraFeed(deviceId, handleCameraEvent)

  useEffect(() => {
    setIndex(defaultIndex)
  }, [defaultIndex])

  const tabs = [
    {
      label: 'Incidents',
      icon: <HiOutlineExclamation className='tab-btn__icon' size={18} />,
      panel: <DeviceAlerts incidents={incidents} isLoading={isDeviceLoading} />,
    },
  ]

  const scrollToVideoPlayer = () => {
    bodyRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const disablePIP = () => setPIPActive(false)
  const enablePIP = () => setPIPActive(true)

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom='1px solid #D5DCE4' p={0} pr='24px'>
          <Flex h='64px' w='full'>
            <HStack isTruncated pl='24px' spacing={2}>
              <IconButton
                aria-label='Back button'
                borderRadius='md'
                h='30px'
                icon={<HiArrowLeft size={18} />}
                minWidth='30px'
                onClick={onClose}
                variant='ghost'
                w='30px'
              />
              {isLoading ? (
                <SkeletonCircle size='30px' />
              ) : (
                <Circle
                  bg={getDeviceStatusColor(device?.status)}
                  color='#fff'
                  size='30px'
                >
                  <BiVideo color='#fff' size={20} />
                </Circle>
              )}
              <HStack isTruncated spacing='14px'>
                <DrawerBreadcrumb isLoading={isLoading} label={device?.name} />
                <DrawerBreadcrumb
                  isLoading={isLoading}
                  label={`Floor ${device?.floor?.name}`}
                  onClick={() => {
                    onClose()
                    goToFloorPlan(device?.floor?.id)
                  }}
                />
                <DrawerBreadcrumb
                  hideDivider={true}
                  isLoading={isLoading}
                  label={device?.facility?.shortName}
                  onClick={() => {
                    onClose()
                    goToFloorPlan(device?.floor?.id)
                  }}
                />
              </HStack>
            </HStack>
          </Flex>
        </DrawerHeader>
        <DrawerBody p={0} ref={bodyRef}>
          <Box
            bg='white'
            position='sticky'
            ref={videoRef}
            top='-352px'
            zIndex='sticky'
          >
            <Box pt='24px' px='24px' w='100%'>
              {isFeedDataLoading ? (
                <Skeleton
                  borderRadius='0.375rem'
                  d='block'
                  h='324px'
                  maxW='100%'
                  w='577px'
                />
              ) : cameraFeedUrl ? (
                <VideoPlayer
                  disablePIP={disablePIP}
                  errorLabel={errorMessage}
                  liveEnabled
                  liveOnly
                  onPlayerControlAction={handlePlayerAction}
                  onStartPlaying={() =>
                    handleCameraEvent(VideoFeedEvent.Playing)
                  }
                  pipActive={isPipActive}
                  placeholderHeight='324px'
                  placeholderWidth='577px'
                  url={cameraFeedUrl}
                />
              ) : (
                <Flex
                  alignItems='center'
                  bgColor='#000'
                  borderRadius='0.375rem'
                  h='324px'
                  justifyContent='center'
                  w='577px'
                >
                  <Box color='#fff'>Video is unavailable</Box>
                </Flex>
              )}
            </Box>
          </Box>
          <Tabs index={index} isLazy onChange={(i) => setIndex(i)}>
            <Box
              bg='white'
              boxShadow='lg'
              position='sticky'
              top='0'
              zIndex='sticky'
            >
              <ShowVideoButton
                disablePIP={disablePIP}
                enablePIP={enablePIP}
                onClick={scrollToVideoPlayer}
                pipActive={isPipActive}
                show={!isInView}
                url={cameraFeedUrl}
              />
              <DrawerTabs tabs={tabs} />
              <Box
                bgGradient='linear-gradient(135deg, #2E6CDB 0%, #6096F5 100%)'
                h='4px'
                w='full'
              />
            </Box>
            <TabPanels>
              {tabs.map(({ panel, label }) => (
                <TabPanel key={label} p='0'>
                  {panel}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
