import { EditFacilityModal } from '@/features/facility-crud'
import { DeleteFacilityModal } from '@/features/facility-crud'

import { FacilitiesTableModalType } from '../types/types'
import { FacilityACModal } from './FacilityACModal'
import { FacilityAlertsModal } from './FacilityAlertsModal'
import { FacilityCamerasModal } from './FacilityCamerasModal'
import { FacilityDevicesModal } from './FacilityDevicesModal'
import { FacilityFloorsModal } from './FacilityFloorsModal'
import { FacilityGuardsModal } from './FacilityGuardsModal'

export const FacilitiesTableModal = ({
  modalType,
  isOpen,
  onClose,
  facilityId,
}: {
  modalType: FacilitiesTableModalType
  isOpen: boolean
  onClose: () => void
  facilityId: string
}) => {
  switch (modalType) {
    case FacilitiesTableModalType.Guards:
      return (
        <FacilityGuardsModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    case FacilitiesTableModalType.Floors:
      return (
        <FacilityFloorsModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    case FacilitiesTableModalType.Devices:
      return (
        <FacilityDevicesModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    case FacilitiesTableModalType.Cameras:
      return (
        <FacilityCamerasModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    case FacilitiesTableModalType.Alerts:
      return (
        <FacilityAlertsModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    case FacilitiesTableModalType.AC:
      return (
        <FacilityACModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )

    case FacilitiesTableModalType.EditFacility:
      return (
        <EditFacilityModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )

    case FacilitiesTableModalType.DeleteFacility:
      return (
        <DeleteFacilityModal
          facilityId={facilityId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    default:
      return null
  }
}
