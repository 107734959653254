import { HiExclamation, HiOutlineOfficeBuilding } from 'react-icons/hi'

import { Box, Flex, Grid } from '@chakra-ui/react'

interface ModalBodyConentIProps {
  hasUnresolvedIncidents: boolean
  facilityName: string
}

export const ModalBodyConent = ({
  hasUnresolvedIncidents,
  facilityName,
}: ModalBodyConentIProps) => {
  return (
    <Box px='10' py='6'>
      <Grid gap='10px'>
        <Box>
          <Box
            color='#353849'
            fontSize='26px'
            fontWeight='extrabold'
            letterSpacing='-0.4px'
            lineHeight='1'
            mb='3'
          >
            Deleting Facility
          </Box>
          <Box
            color='#2d2e41'
            fontSize='16px'
            fontWeight='500'
            letterSpacing='-0.53px'
          >
            {hasUnresolvedIncidents
              ? 'This facility has active incidents. Please resolve these incidents and try again.'
              : 'Are you sure you want to delete:'}
          </Box>
        </Box>
      </Grid>

      {!hasUnresolvedIncidents && (
        <>
          <Grid
            alignItems='center'
            gap='5px'
            gridTemplateColumns='24px auto'
            mt='3'
          >
            <HiOutlineOfficeBuilding color='#000' size={16} />
            <Box
              color='#353849'
              fontSize='16px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
            >
              {facilityName}
            </Box>
          </Grid>

          <Flex alignItems='center' color='#D11D3A' flexDirection='row' pt='4'>
            <HiExclamation size='24px' />
            <Box fontSize='16px' fontWeight='medium' ml='1'>
              <Box as='span' fontWeight='bold'>
                Warning:
              </Box>{' '}
              All deletions are permanent
            </Box>
          </Flex>
        </>
      )}
    </Box>
  )
}
