import { useMediaCounter } from '../hooks/useMediaCounter'

import { Box, Image } from '@chakra-ui/react'

import { MediaControls } from './MediaControls'
import { MediaError } from './MediaError'

export interface IncidentImagesIProps {
  images: string[]
}

export const IncidentImages = ({ images }: IncidentImagesIProps) => {
  const {
    index,
    isImageError,
    handleImageError,
    handleClickNext,
    handleClickPrevious,
  } = useMediaCounter({
    images,
  })
  return (
    <Box
      data-testid='incidentsPage_drawer_incidentImages'
      pt='20px'
      px='24px'
      w='100%'
    >
      <MediaControls
        index={index}
        items={images}
        loading={false}
        onClickNext={handleClickNext}
        onClickPrevious={handleClickPrevious}
      >
        {!isImageError && (
          <Image
            borderRadius='4px'
            h='324px'
            objectFit='contain'
            onError={handleImageError}
            src={images?.[index]}
            w='100%'
          />
        )}
        {isImageError && <MediaError />}
      </MediaControls>
    </Box>
  )
}
