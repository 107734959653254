import { JSXElementConstructor, ReactElement, useState } from 'react'

import { useToasts } from '@/hooks/useToasts'
import { DocumentProps, pdf } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'

import fontkit from '@pdf-lib/fontkit'
import downloadjs from 'downloadjs'
import { PDFDocument } from 'pdf-lib'

import { selectMe } from '@/redux/me/meSlice'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { ReportTemplate } from '../components/ReportTemplate'
import { DownloadReportButtonIProps } from '../types/types'
import { createPDFTextField, getFieldAppearanceOptions } from '../utils/utils'

export const useReportDownload = ({ incident }: DownloadReportButtonIProps) => {
  const template = ReportTemplate({ incident })
  const { showError } = useToasts()
  const [isLoading, setIsLoading] = useState(false)
  const me = useSelector(selectMe)

  const handleReportDownload = async (
    template: ReactElement<DocumentProps, string | JSXElementConstructor<any>>,
    reportName: string
  ) => {
    const blob = await pdf(template).toBlob()

    const formPdfBytes = await blob.arrayBuffer()
    const pdfDoc = await PDFDocument.load(formPdfBytes)

    pdfDoc.registerFontkit(fontkit)

    const form = pdfDoc.getForm()

    const pages = pdfDoc.getPages()

    const summaryField = createPDFTextField(form, 'summary', '[Summary Here]')
    const actionsField = createPDFTextField(form, 'actions', '[Actions Here]')

    const lastPage = pages[pages.length - 1]

    summaryField.addToPage(
      lastPage,
      getFieldAppearanceOptions(lastPage, 40, 630)
    )

    actionsField.addToPage(
      lastPage,
      getFieldAppearanceOptions(lastPage, 40, 425)
    )

    const montserrat = await pdfDoc.embedFont(
      await fetch('/fonts/Montserrat-Regular.ttf').then((res) =>
        res.arrayBuffer()
      )
    )

    actionsField.updateAppearances(montserrat)
    summaryField.updateAppearances(montserrat)
    summaryField.setFontSize(16)
    actionsField.setFontSize(16)

    const pdfBytes = await pdfDoc.save()

    downloadjs(pdfBytes, reportName, 'application/pdf')
  }

  const onDownloadComplete = () => {
    mixpanel.track(`${BUTTON_PRESS} Incident Report Download`, {
      user_id: me.id,
      download_time: new Date().toISOString(),
    })
  }

  const downloadReport = async () => {
    setIsLoading(true)

    try {
      await handleReportDownload(
        template,
        `${incident?.displayId}-${incident?.name} Report.pdf`
      )
      setIsLoading(false)
      onDownloadComplete()
    } catch {
      setIsLoading(false)
      showError('Download Failed. Please try again later')
    }
  }

  return { isLoading, handleReportDownload, downloadReport }
}
