import React, { useEffect } from 'react'

import { useKeyCloak } from '@/hooks/useKeyCloak'
import { ROLE_STATUS } from '@/hooks/useKeyCloak/types'

import { AnimatePresence } from 'framer-motion'
import mixpanel from 'mixpanel-browser'

import { PageLoader } from '@/components/ui'
import { InvalidRole } from '@/components/ui'

import { AnimatedDiv } from './animatedDiv'

export function requireAuth<PT>(Component: React.ComponentType<PT>) {
  return function (props: PT) {
    const { isKcInitilised, logIn, isLoggedIn, roleStatus } = useKeyCloak()

    useEffect(() => {
      if (!isLoggedIn && isKcInitilised) {
        mixpanel.track('Page View: LoginForm')
        logIn()
      }
    }, [isKcInitilised, isLoggedIn])

    return (
      <AnimatePresence exitBeforeEnter initial={false}>
        {!isLoggedIn ? (
          <AnimatedDiv>
            <PageLoader />
          </AnimatedDiv>
        ) : isLoggedIn && roleStatus !== ROLE_STATUS.INVALID ? (
          <AnimatedDiv>
            <Component {...props} />
          </AnimatedDiv>
        ) : (
          <AnimatedDiv>
            <InvalidRole />
          </AnimatedDiv>
        )}
      </AnimatePresence>
    )
  }
}
