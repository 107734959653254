import { useEffect, useRef, useState } from 'react'

export const useVideoThumbnail = (videoFile: File) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const generateVideoThumbnail = async () => {
      if (videoRef.current) {
        try {
          videoRef.current.load()

          await new Promise((resolve) => {
            videoRef.current.addEventListener('loadeddata', resolve)
          })

          const captureTime = videoRef.current.duration / 2
          videoRef.current.currentTime = captureTime

          await new Promise((resolve) => {
            videoRef.current.addEventListener('seeked', resolve)
          })

          const canvas = document.createElement('canvas')
          if (
            videoRef.current.videoWidth > 0 &&
            videoRef.current.videoHeight > 0
          ) {
            canvas.width = videoRef.current.videoWidth
            canvas.height = videoRef.current.videoHeight
            const ctx = canvas.getContext('2d')
            if (ctx) {
              ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
              const thumbnailURL = canvas.toDataURL()
              setThumbnail(thumbnailURL)
            }
          } else {
            return
          }
        } catch (error) {
          return
        }
      }
    }
    generateVideoThumbnail()
  }, [videoFile])

  return { thumbnail, videoRef }
}
