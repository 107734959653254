import { HiExclamation } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

import { AlertDialogBody, AlertDialogFooter, Box, Flex } from '@chakra-ui/react'

import { BasicAlertDialog, Button } from '@/components/ui'
import { enableEditMode } from '@/redux/ui/uiSlice'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const ConfirmEditModeDialog = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()
  const handleOnClick = () => {
    onClose()
    dispatch(enableEditMode())
  }
  return (
    <BasicAlertDialog
      dataTestId='ConfirmEditModeDialog'
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title='Entering Edit Mode'
    >
      <AlertDialogBody>
        <Box letterSpacing='-0.53px'>
          In{' '}
          <Box as='span' fontWeight='bold'>
            Edit Mode
          </Box>
          , you can create, edit, and delete objects in HiveWatch.
        </Box>
        <Flex alignItems='center' color='#D11D3A' flexDirection='row' mt='4'>
          <HiExclamation size='22px' />
          <Box fontSize='15px' letterSpacing='-0.53px' lineHeight='1' ml='1'>
            <Box as='span' fontWeight='bold'>
              Warning:
            </Box>{' '}
            All deletions are permanent
          </Box>
        </Flex>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          data-testid='ConfirmEditModeDialog:accept'
          onClick={handleOnClick}
        >
          Accept
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
