import React, { ChangeEvent, RefObject, useRef } from 'react'

import { HiOutlinePaperClip } from 'react-icons/hi'

import { Button, Tooltip } from '@chakra-ui/react'

interface AttachMediaButtonIProps {
  allowedExtensions: string
  handleFileSelect: (event: ChangeEvent<HTMLInputElement>) => void
  maxFiles: number
  selectedFiles: File[]
}

export const AttachMediaButton = ({
  allowedExtensions,
  handleFileSelect,
  maxFiles,
  selectedFiles,
}: AttachMediaButtonIProps) => {
  const fileInputRef: RefObject<HTMLInputElement> = useRef()
  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Tooltip
      arrow='#1b253f'
      bgColor='#1b253f'
      color='white'
      hasArrow
      isDisabled={selectedFiles.length >= maxFiles}
      label='Attach Media'
      mt='5px'
      openDelay={250}
      p='10px'
      shouldWrapChildren
    >
      <Button
        data-testid='incidentDrawer_addNote_attachMediaButton'
        isDisabled={selectedFiles.length >= maxFiles}
        onClick={openFileDialog}
        variant='secondary'
      >
        <HiOutlinePaperClip size={18} />
      </Button>
      <input
        accept={allowedExtensions}
        className='hidden'
        multiple
        onChange={handleFileSelect}
        onClick={(event) => {
          if (event.currentTarget) {
            event.currentTarget.value = ''
          }
        }}
        ref={fileInputRef}
        type='file'
      />
    </Tooltip>
  )
}
