import { Image, Text, View } from '@react-pdf/renderer'

import { formatDateTime } from '@/utils/formatDateTime'

import { styles } from '../styles/styles'
import { DownloadReportButtonIProps } from '../types/types'

export const Header = ({ incident }: DownloadReportButtonIProps) => {
  const facilityName = incident?.facility?.name

  return (
    <>
      <View style={styles.header}>
        <View
          //workaround inline styles not working
          style={{
            ...styles.flexRow,
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <Image src='/Logo.png' />
          <Text>Hive</Text>
          <Text style={styles.bold}>Watch</Text>
          <Text> Incident Report</Text>
        </View>
      </View>

      <View style={styles.mt10}>
        <Text style={{ ...styles.bold, fontSize: 20 }}>
          {incident?.displayId} {incident?.name}
        </Text>
      </View>

      <View style={{ ...styles.mt5, fontSize: 16 }}>
        <View
          style={{
            ...styles.flexRow,
            justifyContent: 'space-between',
          }}
        >
          <View style={styles.flexRow}>
            <Text style={styles.bold}>Date:</Text>
            <Text style={styles.ml15px}>
              {formatDateTime(new Date(incident?.createdAt), 'MM-dd-yyyy')}
            </Text>
          </View>

          <View style={styles.flexRow}>
            <Text style={styles.bold}>Time:</Text>
            <Text style={styles.ml15px}>
              {formatDateTime(incident?.createdAt, 'HH:mm')}
            </Text>
          </View>
        </View>

        <View style={styles.flexRow}>
          <Text style={styles.bold}>Location:</Text>
          <Text style={styles.location}>{incident?.address?.toString()}</Text>
        </View>
      </View>

      {facilityName && (
        <View wrap={false}>
          <Text style={styles.facilityName}>{facilityName}</Text>
        </View>
      )}
    </>
  )
}
