import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  selectNotifications,
  setNotifications,
} from '@/redux/notifications/notificationsSlice'
import { openIncidentDrawer, setIncident } from '@/redux/ui/uiSlice'

import { NotificationToasts } from './NotificationToasts'

const VISIBLE_NOTIFICATIONS = 5

export const Notifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(selectNotifications)

  useEffect(() => {
    if (notifications.length > VISIBLE_NOTIFICATIONS) {
      // If there are more than X. Remove oldest id and close toast
      const firstNotification = notifications[0]
      hideNotificationToast(firstNotification?.notificationTimestamp)
    }
  }, [notifications.length])

  const hideNotificationToast = (notificationTimeStamp: string) => {
    const updatedNotifications = notifications.filter(
      (d) => d.notificationTimestamp !== notificationTimeStamp
    )
    dispatch(setNotifications(updatedNotifications))
  }

  const onNotificationToastClick = (id: string, timestamp: string) => {
    hideNotificationToast(timestamp)
    dispatch(setIncident({ id }))
    dispatch(openIncidentDrawer())
  }

  return (
    <NotificationToasts
      data={notifications}
      onCancel={hideNotificationToast}
      onClick={onNotificationToastClick}
    />
  )
}
