import { Column } from 'react-table'

import { Text, Tooltip } from '@chakra-ui/react'

import {
  FacilityConnection,
  FacilityEdge,
  User,
} from '@/graphql/generated/schemas'
import { formatDate, formatTimeDistance } from '@/utils/formatDateTime'

import { SOPsListItemCreator } from '../types/types'
import { FacilitiesCell } from './FacilitiesCell'

type SOPItem = {
  name: string
  date: string
  creator: User
  facilities: FacilityConnection[]
}

export const columns: Column<SOPItem>[] = [
  {
    Header: 'SOP Name',
    disableSortBy: false,
    accessor: 'name',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={value}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='sopsPage_table_nameCell' isTruncated>
          {value}
        </Text>
      </Tooltip>
    ),
    width: 150,
  },
  {
    Header: 'Date Created',
    disableSortBy: false,
    accessor: 'date',
    Cell: ({ value }: { value: string }) => (
      <Tooltip
        hasArrow
        label={`${formatDate(value)} • ${formatTimeDistance(value)}`}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='sopsPage_table_dateCell' isTruncated>
          {formatDate(value)}
        </Text>
      </Tooltip>
    ),
    width: 90,
  },
  {
    Header: 'Creator',
    disableSortBy: false,
    accessor: 'creator',
    Cell: ({ value }: { value: SOPsListItemCreator }) => (
      <Tooltip
        hasArrow
        label={`${value?.firstName} ${value?.lastName}`}
        openDelay={500}
        placement='auto'
        shouldWrapChildren
      >
        <Text data-testid='sopsPage_table_creatorCell' isTruncated>
          {`${value?.firstName} ${value?.lastName}`}
        </Text>
      </Tooltip>
    ),
    width: 120,
  },
  {
    Header: 'Facilities',
    disableSortBy: true,
    accessor: 'facilities',
    Cell: ({
      value,
      onEdit,
      onRemove,
    }: {
      value: FacilityEdge[]
      onEdit: () => void
      onRemove: () => void
    }) => {
      return (
        <FacilitiesCell
          facilities={value}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      )
    },
    width: 200,
  },
]
