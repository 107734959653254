import { useSelector } from 'react-redux'

import {
  selectCreatedAtFilter,
  selectFacilityIdsFilter,
} from '@/redux/ui/uiSlice'

export function useFacilitiesFilter() {
  const idsFilterValue = useSelector(selectFacilityIdsFilter)
  const createdAtFilterValue = useSelector(selectCreatedAtFilter)

  const filters = {
    idIn: idsFilterValue?.length
      ? idsFilterValue?.map((f) => f.value)
      : undefined,
    createdAtGte: createdAtFilterValue?.value?.gte,
  }

  return {
    filters: JSON.parse(JSON.stringify(filters)),
  }
}
