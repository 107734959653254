import { useSelector } from 'react-redux'

import { Box } from '@chakra-ui/react'

import { selectEditMode } from '@/redux/ui/uiSlice'

export const EditModeFrame = () => {
  const isEditMode = useSelector(selectEditMode)
  return (
    <Box
      borderColor={isEditMode ? '#46ef9f' : 'transparent'}
      borderWidth={isEditMode ? '5px' : '0px'}
      data-testid={`EditModeFrame:${isEditMode ? 'active' : 'inactive'}`}
      h='full'
      left='0'
      pointerEvents='none'
      pos='absolute'
      top='0'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='all'
      w='full'
      zIndex='9'
    />
  )
}
