import { useSelector } from 'react-redux'

import { DeviceType } from '@/graphql/generated/schemas'
import {
  selectDeviceCreatedAtFilter,
  selectDeviceFacilityIdsFilter,
  selectDeviceFloorsIdsFilter,
  selectDeviceStatusFilter,
  selectDeviceTypeFilter,
} from '@/redux/ui/uiSlice'

export function useDevicesFilter() {
  const facilityIdsFilterValue = useSelector(selectDeviceFacilityIdsFilter)
  const floorsIdsFilterValue = useSelector(selectDeviceFloorsIdsFilter)
  const createdAtFilterValue = useSelector(selectDeviceCreatedAtFilter)
  const deviceStatusFilterValue = useSelector(selectDeviceStatusFilter)
  const deviceTypeFilterValue = useSelector(selectDeviceTypeFilter)
  const filters = {
    facilityIdIn: facilityIdsFilterValue?.length
      ? facilityIdsFilterValue?.map((f) => f.value)
      : undefined,
    floorIdIn: floorsIdsFilterValue?.length
      ? floorsIdsFilterValue?.map((f) => f.value)
      : undefined,
    createdAtGte: createdAtFilterValue?.value?.gte,
    statusIn: deviceStatusFilterValue?.length
      ? deviceStatusFilterValue?.map((s) => s.value)
      : undefined,
    typeIn: deviceTypeFilterValue?.length
      ? deviceTypeFilterValue?.map((d) => d.value)
      : undefined,
    typeNot: DeviceType.Checkpoint,
  }

  return {
    filters: JSON.parse(JSON.stringify(filters)),
  }
}
