import React from 'react'

import { HiChevronUp } from 'react-icons/hi'

import { Icon } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

const Container = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`

const ChevronIcon = () => (
  <Icon
    as={HiChevronUp}
    fill='#4C535F'
    height='100%'
    position='absolute'
    width='100%'
  />
)

export const DrawerToggleButton = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Container data-testid='notificationsTab_drawerToggleButton' role='button'>
      <motion.div
        animate={{ rotateX: isOpen ? 180 : 0, translateY: isOpen ? '-10%' : 0 }}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        transition={{ duration: 0.2 }}
      >
        <ChevronIcon />
      </motion.div>
      <motion.div
        animate={{ translateY: isOpen ? '10%' : 0 }}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        transition={{ duration: 0.2 }}
      >
        <ChevronIcon />
      </motion.div>
    </Container>
  )
}
