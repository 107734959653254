import React from 'react'

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

import { Box, Text } from '@chakra-ui/react'

export interface IProps {
  isReal?: boolean
}

export const ResolveLabelValue = ({ isReal }: IProps) => {
  return (
    <Box alignItems='center' display='flex' flexDirection='row' ml='10px'>
      {isReal ? (
        <FaCheckCircle color='#2fb2b0' size='22px' />
      ) : (
        <FaTimesCircle color='#D01030' size='22px' />
      )}
      <Text
        color='#5A6E98 '
        data-testid='ResolveLabelValue:title'
        fontSize='16px'
        fontWeight='bold'
        ml='5px'
      >
        {isReal ? 'Real' : 'False'}
      </Text>
    </Box>
  )
}
