import { Box } from '@chakra-ui/react'

export const MediaError = () => {
  return (
    <Box
      alignItems='center'
      display='flex'
      fontSize='18px'
      fontWeight={500}
      h='324px'
      justifyContent='center'
      w='100%'
    >
      Error loading image
    </Box>
  )
}
