import { useCreateFacilityMutation } from '@/graphql/generated/hooks'
import { CreateFacilityInput, FacilityType } from '@/graphql/generated/schemas'

import { LabelValueIProps } from '../types/types'

const facilityTypes: LabelValueIProps[] = [
  { label: 'Physical', value: FacilityType.Normal },
  { label: 'Market', value: FacilityType.Market },
]

export const useCreateFacility = () => {
  const [createFacility] = useCreateFacilityMutation()

  const create = async (input: CreateFacilityInput) => {
    await createFacility({
      variables: { input },
      refetchQueries: ['Facilities'],
    })
  }

  return {
    facilityTypes,
    create,
  }
}
