import { motion } from 'framer-motion'

export const AnimatedPanel = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      animate={{ opacity: 1, translateX: 0, translateY: 0 }}
      initial={{
        opacity: 0,
        translateX: 0,
        translateY: -20,
      }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  )
}
