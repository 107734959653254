import { OptionI } from '@/utils/forms/optionBuilders'

export type GuardsFormInputs = {
  guards: OptionI<string | null>[] | OptionI<string | null>
}

export const allGuardsOption: OptionI = {
  value: null,
  label: 'All Guards',
}
