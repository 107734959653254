import { useState } from 'react'

import { useUploadAudioFile } from '../hooks/useUploadAudioFile'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'

import { Box, Icon, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'

import { VoiceMessage } from '@/components/icons'
import { Button, VoiceRecorder } from '@/components/ui'
import {
  IncidentDocument,
  useCreateAudioMessageMutation,
  useFacilityGuardsQuery,
} from '@/graphql/generated/hooks'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../../types/types'
import { RadioGuardHeader } from './RadioGuardHeader'

interface RadioGuardsIProps {
  facilityId?: string
  incidentId?: string
  mixpanelData?: MixpanelDataIProps
  shouldDispatch?: boolean
  onActionComplete?: () => void
}

export const RadioGuards = ({
  facilityId,
  incidentId,
  mixpanelData,
  onActionComplete,
}: RadioGuardsIProps) => {
  const { shouldEnableIncidentRadioGuards } = usePermissions()
  const { showSuccess, showError } = useToasts()
  const [isLoading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const uploadAudioFile = useUploadAudioFile()

  const [createAudioMessage] = useCreateAudioMessageMutation()

  const { data: guardsData, loading: isGuardsLoading } = useFacilityGuardsQuery(
    {
      variables: { facilityId },
      fetchPolicy: 'network-only',
    }
  )
  const guards = guardsData?.checkedInUsers?.edges.map((edge) => edge.node)

  const generateFile = ({ blob }: { blob: BlobPart }) => {
    const file = new File([blob], new Date().valueOf().toString(), {
      type: 'audio/wav',
    })
    setFile(file)
  }

  const incidentQuery = {
    query: IncidentDocument,
    variables: {
      id: incidentId ?? '',
    },
  }

  const onRecording = () => {
    mixpanel.track(`${BUTTON_PRESS} Radio Guards - Recording`, mixpanelData)
  }

  const onSuccess = (audioMessageId: string) => {
    showSuccess(`Audio message successfully created.`)
    setLoading(false)
    setFile(null)
    mixpanel.track(`${BUTTON_PRESS} Radio Guards - Send Message`, {
      ...mixpanelData,
      audioMessageId,
    })
    onActionComplete?.()
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const audioClipId = (await uploadAudioFile({
        file,
        facilityId,
        incidentId,
      })) as string
      try {
        const { data: audioMessageData } = await createAudioMessage({
          variables: {
            input: {
              facilityId,
              incidentId,
              audioClipId,
            },
          },
          refetchQueries: [incidentQuery],
        })
        onSuccess(audioMessageData?.createAudioMessage?.audioMessage?.id)
      } catch (error) {
        showError()
        setLoading(false)
      }
    } catch (error) {
      showError()
      setLoading(false)
    }
  }

  const onCancel = () => {
    mixpanel.track(`${BUTTON_PRESS} Radio Guards - Cancel Button`, mixpanelData)
    onActionComplete?.()
  }

  return (
    <Box mb={8}>
      <RadioGuardHeader
        dateTime={format(new Date(), 'MM/dd/yyyy')}
        guards={guards}
        header='Radio Guards'
        isGuardsLoading={isGuardsLoading}
        subHeader='Send a voice message to the guards'
      />
      <VoiceRecorder
        getAudioFile={generateFile}
        hasFile={!!file}
        onRecording={onRecording}
      />
      <Stack align='center' direction='row' mt={6} spacing={3}>
        <Button
          data-testid='incidentDrawer_radioGuards_cancel'
          onClick={onCancel}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          data-testid='incidentDrawer_radioGuards_sendVoiceMessage'
          isDisabled={!file || isLoading || !shouldEnableIncidentRadioGuards}
          isLoading={isLoading}
          leftIcon={<Icon as={VoiceMessage} boxSize={5} />}
          loadingText='Sending message...'
          onClick={onSubmit}
        >
          Send message
        </Button>
      </Stack>
    </Box>
  )
}
