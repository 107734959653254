import { UploadError } from '@/hooks/useUpload'
import {
  FILENAME_INCORRECT_CHARACTERS,
  FILENAME_TOO_LONG,
  FILE_INCORRECT_EXTENSION,
  FILE_INCORRECT_TYPE,
  FILE_TOO_LARGE,
} from '@/hooks/useUpload/error'
import { getExtension } from '@/hooks/useUpload/helpers'

import { ALLOWED_CHARS_REGEX } from '../constants/constants'

export const validateFileType = (
  file: File,
  allowedExtensions: string[],
  allowedMimes: string[]
) => {
  const fileName = file.name.toLowerCase()
  const fileExtension = getExtension(fileName)

  // Validate file extension
  if (!allowedExtensions.includes(fileExtension)) {
    throw new Error(FILE_INCORRECT_EXTENSION(fileExtension))
  }

  // Validate file MIME type
  if (!allowedMimes.includes(file.type)) {
    throw new UploadError(FILE_INCORRECT_TYPE)
  }
}

export const validateFileName = (file: File, maxLength: number) => {
  const fileName = file.name

  if (!ALLOWED_CHARS_REGEX.test(fileName)) {
    throw new UploadError(FILENAME_INCORRECT_CHARACTERS)
  }
  if (fileName.length > maxLength) {
    throw new UploadError(FILENAME_TOO_LONG(maxLength))
  }
}

export const validateFileSize = (file: File, maxSizeInBytes: number) => {
  const fileSize = file.size

  if (fileSize > maxSizeInBytes) {
    throw new UploadError(FILE_TOO_LARGE)
  }
}
