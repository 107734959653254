import { Text } from '@chakra-ui/react'

import { IncidentNoteMediaIProps } from '../types/types'

export const FileDescription = ({ files }: IncidentNoteMediaIProps) => {
  if (files.length === 1) {
    return (
      <Text color='gray.500' fontSize='sm' mr='10px'>
        {files[0].name}
      </Text>
    )
  } else {
    return (
      <Text color='gray.500' fontSize='sm' mr='10px'>
        {files.length} files
      </Text>
    )
  }
}
