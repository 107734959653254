import { Box, Grid, ModalBody, ModalFooter } from '@chakra-ui/react'

import { Button } from '@/components/ui'

interface FacilityErrorViewIProps {
  onClose: () => void
}

export const FacilityErrorView = ({ onClose }: FacilityErrorViewIProps) => {
  return (
    <>
      <ModalBody p='0'>
        <Box px='10' py='6'>
          <Grid gap='10px'>
            <Box>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
                mb='3'
              >
                Delete Facility
              </Box>
              <Box
                color='#2D2E41'
                fontSize='17px'
                letterSpacing='-0.4px'
                mt='3'
              >
                This facility is not found.
              </Box>
            </Box>
          </Grid>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          data-testid='facilitiesPage_removeFacilityModal_closeButton'
          onClick={onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  )
}
