import { useStandardOperatingProcedure } from '../hooks/useStandardOperatingProcedure'
import { useSelector } from 'react-redux'

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'

import { selectSOP } from '@/redux/ui/uiSlice'

import { SOPDocument } from './SOPDocument'
import { SOPDrawerHeader } from './SOPDrawerHeader'

interface SopDrawerIProps {
  isOpen: boolean
  onClose: () => void
}

export const SOPDrawer = ({ isOpen, onClose }: SopDrawerIProps) => {
  const { id } = useSelector(selectSOP)
  const { data, isLoading } = useStandardOperatingProcedure({ id })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom='1px solid #D5DCE4' p={0}>
          <SOPDrawerHeader isLoading={isLoading} onClose={onClose} sop={data} />
        </DrawerHeader>
        <DrawerBody p={0}>
          <SOPDocument isLoading={isLoading} sop={data} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
