import { motion } from 'framer-motion'

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

interface AnimatedDivIProps {
  children: React.ReactChild | React.ReactChild[]
}

export const AnimatedDiv = ({ children }: AnimatedDivIProps) => {
  return (
    <motion.div
      animate='enter'
      exit='exit'
      initial='hidden'
      key='children'
      transition={{ type: 'linear' }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}
