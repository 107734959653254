import { useCreateCase } from '../hooks/useCreateCase'
import { usePermissions } from '@/hooks/usePermissions'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { Box } from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  FormTextareaControl,
} from '@/components/ui'

import { CreateCaseFormInputsIProps } from '../types/types'

interface CreateCaseFormIProps {
  incidentId?: string
  onClose: () => void
}

export const CreateCaseForm = ({
  incidentId,
  onClose,
}: CreateCaseFormIProps) => {
  const { shouldEnableCaseManagement } = usePermissions()
  const { onCreateCase, isLoading, facilityOptions } = useCreateCase({
    incidentId,
    onClose,
  })

  const { handleSubmit, register, control, errors, formState } =
    useForm<CreateCaseFormInputsIProps>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
  const { isValid } = formState

  return (
    <Box>
      <Box py={2}>
        <Box mb={3}>
          <FormInputControl
            errorMessage={errors.name && errors.name.message}
            id='name'
            inputRef={register({
              required: 'Case name is required',
            })}
            isDisabled={isLoading}
            isInvalid={!!errors.name}
            label='Case Name'
            placeholder='Enter a name for this Case'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            defaultValue={null}
            errorMessage={!!errors?.facilities && 'Facilities are required'}
            id='facilities'
            isClearable
            isDisabled={isLoading}
            isInvalid={!!errors.facilities}
            isMulti
            isSearchable
            label='Facilities'
            options={facilityOptions}
            placeholder='Add facilities where this Case applies'
            rules={{ required: true }}
          />
        </Box>
        <Box mb={3}>
          <FormTextareaControl
            errorMessage={errors.description && errors.description.message}
            id='description'
            isInvalid={!!errors.description}
            label='Brief Summary'
            labelFontWeight='500'
            placeholder='Enter brief summary'
            textareaRef={register({
              required: 'Brief summary is required',
            })}
          />
        </Box>
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <Button mr={3} onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!shouldEnableCaseManagement || !isValid}
          isLoading={isLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          loadingText='Creating Case'
          onClick={handleSubmit(onCreateCase)}
        >
          Create Case
        </Button>
      </Box>
    </Box>
  )
}
