import { useToasts } from '@/hooks/useToasts'
import { useUpload } from '@/hooks/useUpload'

import { UploadedFileType } from '@/graphql/generated/schemas'

import { MAX_FILENAME_LENGTH, MAX_FILE_SIZE } from '../constants/constants'

export const useFileUpload = () => {
  const [upload] = useUpload()
  const { showError } = useToasts()

  const handleFileUpload = async (selectedFiles: File[]) => {
    const results = await Promise.allSettled(
      selectedFiles.map(async (file) => {
        try {
          const fileId = await upload({
            file,
            fileType: UploadedFileType.IncidentNoteMedia,
            validationOptions: {
              maxFileSizeInBytes: MAX_FILE_SIZE,
              maxFileNameLength: MAX_FILENAME_LENGTH,
            },
          })
          return { storedAssetId: fileId, name: file.name }
        } catch (error) {
          showError(error.message)
          return
        }
      })
    )
    return results
  }

  return { handleFileUpload }
}
