import React, { useState } from 'react'

import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Collapse, Flex, Icon } from '@chakra-ui/react'

import { IncidentNoteMediaIProps } from '../types/types'
import { FileDescription } from './FileDescription'
import { MediaGrid } from './MediaGrid'

export const IncidentNoteMedia = ({ files }: IncidentNoteMediaIProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropDown = () => setIsOpen(!isOpen)

  return (
    <Flex as={Box} direction='column' px={3} py={2} transition='all 0.2s'>
      <Flex mr='10px'>
        <FileDescription files={files} />
        <Icon
          as={ChevronRightIcon}
          boxSize='20px'
          color='gray.500'
          onClick={toggleDropDown}
          transform={isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
          transition='0.2s'
        />
      </Flex>
      {files.length > 0 && (
        <Collapse in={isOpen}>
          <Flex mt='10px' padding='5px'>
            <MediaGrid files={files} />
          </Flex>
        </Collapse>
      )}
    </Flex>
  )
}
