import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler } from 'react-hook-form'

import {
  useAssociateSingleIncidentToMultipleCasesMutation,
  useCasesQuery,
} from '@/graphql/generated/hooks'
import { AssociateIncidentToCasesInput } from '@/graphql/generated/schemas'
import { OptionI, getCaseOptions } from '@/utils/forms/optionBuilders'

interface UseAssociateCaseIProps {
  incidentId: string
  onClose: () => void
}

export const useAssociateCases = ({
  incidentId,
  onClose,
}: UseAssociateCaseIProps) => {
  const { data: casesData, loading: isLoading } = useCasesQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [associateIncidentToCases, { loading: isAssociationLoading }] =
    useAssociateSingleIncidentToMultipleCasesMutation()
  const { showSuccess, showError } = useToasts()

  const associateIncident: SubmitHandler<{ cases: OptionI[] }> = async (
    options
  ) => {
    const caseIds = options?.cases?.map((c) => c.value) || []
    const input: AssociateIncidentToCasesInput = {
      caseIds,
      incidentId,
    }
    try {
      await associateIncidentToCases({ variables: { input } })
      const caseText = caseIds?.length > 1 ? 'cases' : 'case'
      showSuccess(`Successfully associated incident with ${caseText}`)
      onClose()
    } catch (e) {
      showError(e.message)
    }
  }

  const caseOptions = getCaseOptions(casesData)

  return {
    caseOptions,
    isLoading,
    isAssociationLoading,
    associateIncident,
  }
}
