import { Text } from '@chakra-ui/react'

import { IncidentStatus as IncidentStatusType } from '@/graphql/generated/schemas'
import {
  getIncidentStatusColor,
  getIncidentStatusDisplayName,
} from '@/utils/incidents'

export const IncidentStatusTag = ({
  status,
}: {
  status: IncidentStatusType
}) => (
  <Text
    backgroundColor={getIncidentStatusColor(status)}
    borderRadius='4px'
    color='white'
    fontSize='14px'
    fontWeight='bold'
    minWidth='100px'
    padding='2px 7px'
    textAlign='center'
  >
    {getIncidentStatusDisplayName(status)}
  </Text>
)
