import { As, Icon, Skeleton, Text, VStack } from '@chakra-ui/react'

export interface ColumnCellIProps {
  count: number
  isLoaded?: boolean
  name: string
  icon: As<any>
  hasIncidents?: boolean
  handleOnClick?: () => void
}

export const ColumnCell = ({
  count,
  isLoaded = true,
  name,
  icon,
  hasIncidents = false,
  handleOnClick,
}: ColumnCellIProps) => {
  const bgColor = hasIncidents && count ? '#CE4043' : 'transparent'
  const hoverBgColor = count
    ? hasIncidents
      ? '#A71C1F'
      : 'whitesmoke'
    : 'transparent'
  const textCountColour = count ? (hasIncidents ? '#FFF' : '#000') : '#8993A5'
  const titleColour = count ? (hasIncidents ? '#FFF' : '#AFAFB5') : '#8993A5'
  return (
    <VStack
      _hover={{
        bgColor: hoverBgColor,
      }}
      bgColor={bgColor}
      cursor={count ? 'pointer' : 'not-allowed'}
      flexShrink='1'
      height='calc(100% - 14px)'
      isTruncated
      justifyContent='center'
      onClick={count ? handleOnClick : undefined}
      rounded='4px'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='background-color'
      w='full'
    >
      <VStack flexShrink='1' isTruncated spacing='0' w='full'>
        <Skeleton h='24px' isLoaded={isLoaded} minW='20px' textAlign='center'>
          <Text
            color={textCountColour}
            fontSize='20px'
            fontWeight='bold'
            isTruncated
            letterSpacing='-0.67px'
            lineHeight='24px'
          >
            {count ? count : '--'}
          </Text>
        </Skeleton>
        <Skeleton isLoaded={isLoaded} my='5px!important'>
          <Text
            color={titleColour}
            fontSize='12px'
            fontWeight='normal'
            isTruncated
            letterSpacing='-0.5px'
            lineHeight='15px'
            pb='1'
          >
            {name}
          </Text>
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          <Icon as={icon} boxSize='18px' strokeColor={textCountColour} />
        </Skeleton>
      </VStack>
    </VStack>
  )
}
