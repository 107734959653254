import {
  OrderDirection,
  StandardOperatingProcedureOrderField,
} from '@/graphql/generated/schemas'

const orderByMapping = {
  name: StandardOperatingProcedureOrderField.Name,
  date: StandardOperatingProcedureOrderField.CreatedAt,
  creator: StandardOperatingProcedureOrderField.CreatorId,
}

export const getOrderBy = (sortedColumn: {
  id?: string
  isSortedDesc?: boolean
}): {
  direction: OrderDirection
  field: StandardOperatingProcedureOrderField
}[] => {
  if (!sortedColumn?.id) {
    // Apply default sorting by date
    return [
      {
        direction: OrderDirection.Desc,
        field: StandardOperatingProcedureOrderField.CreatedAt,
      },
    ]
  } else {
    const direction = sortedColumn.isSortedDesc
      ? OrderDirection.Desc
      : OrderDirection.Asc
    const field = orderByMapping[sortedColumn.id]
    return [
      {
        direction,
        field,
      },
    ]
  }
}

export const getSortedColumn = (
  tableColumns: {
    id: string
    canSort: boolean
    isSorted?: boolean
    isSortedDesc?: boolean
  }[]
): { id?: string; isSortedDesc?: boolean } =>
  tableColumns
    .filter((c) => c.canSort && c.isSorted)
    .map((c) => ({
      id: c.id,
      isSortedDesc: c.isSortedDesc,
    }))?.[0] ?? {}
