import { Box } from '@chakra-ui/react'

import { Notification } from '@/graphql/appsync-notifications'
import { roleName } from '@/utils/roles'

export const ClearNotificationTitle = ({
  notification,
}: {
  notification: Notification
}) => {
  return (
    <Box fontSize='16px' fontWeight='bold' noOfLines={2}>
      <Box as='span' color='#2E6CDB' data-testid='ClearNotificationTitle:title'>
        {roleName(notification?.incidentActionUserRole)}{' '}
        {notification?.incidentActionUserName}{' '}
      </Box>{' '}
      cleared{' '}
      <Box
        as='span'
        color='#2E6CDB'
        data-testid='ClearNotificationTitle:details'
      >
        {notification?.incidentDisplayId} {notification?.incidentName}
      </Box>
    </Box>
  )
}
