import { Box } from '@chakra-ui/react'

import { SearchInput } from '@/components/ui'

interface FilterHeaderIProps {
  filter?: string
  setFilter?: React.Dispatch<React.SetStateAction<string>>
}

export const FilterHeader = ({ filter, setFilter }: FilterHeaderIProps) => {
  return (
    <Box
      bgColor='white'
      borderBottom='1px solid #D5DCE4'
      boxShadow='md'
      mb='5px'
      px={{ md: 0, lg: 10 }}
      py='6'
      w='full'
    >
      <Box maxW='300px' w='full'>
        <SearchInput
          dataTestId='submittedToursPage_tableHeader_searchInput'
          onChange={(e) => setFilter(e.target.value)}
          placeholder='Search or Filter'
          value={filter}
        />
      </Box>
    </Box>
  )
}
