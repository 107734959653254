import { ChangeEvent } from 'react'

import { BiFilter } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import { Box, Circle, Flex, useDisclosure } from '@chakra-ui/react'

import { Button } from '@/components/ui'
import { SearchInput } from '@/components/ui'
import { deviceFilterCountSelector } from '@/redux/ui/uiSlice'

import { DevicesFilterDrawer } from '../devices-filter-drawer'

interface IProps {
  filter: string
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const DeviceSearchFilterHeader = ({ filter, handleChange }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const filterCount = useSelector(deviceFilterCountSelector)
  return (
    <>
      <Box
        bgColor='white'
        borderBottom='1px solid #D5DCE4'
        boxShadow='lg'
        px='6'
        py='4'
        w='full'
      >
        <Flex
          alignItems='center'
          justifyContent='space-between'
          m='auto'
          maxW='1100px'
        >
          <Box maxW='300px' w='full'>
            <SearchInput
              dataTestId='devicesPage_tableHeader_searchInput'
              onChange={handleChange}
              placeholder='Search or Filter'
              value={filter}
            />
          </Box>
          <Button
            data-testid='devicesPage_tableHeader_filtersButton'
            h='40px'
            leftIcon={
              filterCount > 0 && (
                <Circle
                  bgColor='#fff'
                  color='#353849'
                  fontSize='11px'
                  h='20px'
                  minW='20px'
                  w='auto'
                >
                  {filterCount}
                </Circle>
              )
            }
            onClick={onOpen}
            px='6'
            rightIcon={<BiFilter size={24} />}
          >
            Filters
          </Button>
        </Flex>
      </Box>
      <DevicesFilterDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
