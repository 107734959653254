import React from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { IncidentNoteVideoPlayer } from '../../add-note/components/IncidentNoteVideoPlayer'
import { IncidentNotesMediaType } from '../types/types'

interface MediaPreviewerModalIProps {
  onClose: () => void
  isOpen: boolean
  file: IncidentNotesMediaType
}

const modalBodyStyles = {
  padding: '20px',
  maxWidth: '900px',
  maxHeight: '900px',
  objectFit: 'contain',
}

export const MediaPreviewerModal = ({
  file,
  onClose,
  isOpen,
}: MediaPreviewerModalIProps) => {
  const { mimeType, url, name } = file

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='16px'>{name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody sx={modalBodyStyles}>
          {mimeType.includes('video') ? (
            <IncidentNoteVideoPlayer videoUrl={url} />
          ) : mimeType.includes('image') ? (
            <img
              alt={name}
              src={url}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
              }}
            />
          ) : (
            mimeType.includes('pdf') && (
              <embed
                height={800}
                src={url}
                type='application/pdf'
                width='100%'
              />
            )
          )}
        </ModalBody>
      </ModalContent>
      <ModalFooter />
    </Modal>
  )
}
