import { useNavigation } from '@/hooks/useNavigation'
import { useDispatch } from 'react-redux'

import { DeviceType, IncidentType } from '@/graphql/generated/schemas'
import {
  closeIncidentDrawer,
  openDeviceDrawer,
  setDevice,
} from '@/redux/ui/uiSlice'

import { getDescriptionMessage } from '../utils/utils'

interface UseIncidentDescriptionIProps {
  deviceId: string
  deviceType: DeviceType
  floorId: string
  type: IncidentType
}

export const useIncidentDescription = ({
  deviceId,
  deviceType,
  floorId,
  type,
}: UseIncidentDescriptionIProps) => {
  const dispatch = useDispatch()
  const { goToFloorPlan } = useNavigation()

  const onDeviceClick = () => {
    dispatch(setDevice({ id: deviceId, type: deviceType }))
    dispatch(openDeviceDrawer())
  }

  const onFloorClick = () => {
    dispatch(closeIncidentDrawer())
    goToFloorPlan(floorId)
  }
  const message = getDescriptionMessage(type)

  return {
    message,
    onDeviceClick,
    onFloorClick,
  }
}
