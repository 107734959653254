import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'

import {
  useFacilityQuery,
  useUpdateFacilityMutation,
} from '@/graphql/generated/hooks'
import { UpdateFacilityInput } from '@/graphql/generated/schemas'

import { getDefaultFloorOption, getFloorOptions } from '../utils/utils'

export const useEditFacility = (id: string) => {
  const [updateFacility] = useUpdateFacilityMutation()
  const {
    data,
    error,
    loading: isLoading,
  } = useFacilityQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })
  const isEmergencyCallEnabled = useFeatureFlag(FeatureFlag.emergencyCall)
  const isEmergencyCallConfigured = Boolean(
    data?.facility?.emergencyCalling?.outboundPhoneNumber
  )

  const update = async (input: UpdateFacilityInput) => {
    await updateFacility({
      variables: { input },
      refetchQueries: ['Facilities'],
    })
  }

  const floors = getFloorOptions(data?.facility)
  const defaultFloor = getDefaultFloorOption(data?.facility)
  return {
    update,
    facility: data?.facility,
    floors,
    defaultFloor,
    isLoading,
    error,
    isEmergencyCallEnabled,
    isEmergencyCallConfigured,
  }
}
