import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

import { CaseModalHeader, CreateCaseForm } from '@/features/case-crud'

import { AssociateExisitingCaseForm } from './AssociateExisitingCaseForm'

interface AssociateCaseModalIProps {
  incidentId: string
  isOpen: boolean
  onClose: () => void
}

export const AssociateCaseModal = ({
  incidentId,
  isOpen,
  onClose,
}: AssociateCaseModalIProps) => {
  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <CaseModalHeader pb={0} title='Associate incident with a case' />

        <ModalBody pt={0} px={6} py={2}>
          <Tabs isFitted isLazy variant='primary'>
            <TabList>
              <Tab>New Case</Tab>
              <Tab>Existing Case</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <CreateCaseForm incidentId={incidentId} onClose={onClose} />
              </TabPanel>
              <TabPanel px={0}>
                <AssociateExisitingCaseForm
                  incidentId={incidentId}
                  onClose={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
