import { Device } from '@/graphql/generated/schemas'

import { DevicesListNode, FacilityGroupsI } from '../../types/types'

export const filterDevices = (
  devices: DevicesListNode[],
  filter: string,
  activeIncidentsFilterValue: {
    value: boolean
    label: string
  }
): DevicesListNode[] =>
  devices
    ?.filter(
      (d) =>
        d?.name?.toLocaleLowerCase()?.includes(filter.toLocaleLowerCase()) ||
        filter === ''
    )
    .filter((d) =>
      // IF activeIncidentsFilterValue true return devices with incidents
      // IF activeIncidentsFilterValue false return devices with no incidents
      // IF null return all
      activeIncidentsFilterValue?.value === true
        ? d.incidents?.totalCount > 0
        : activeIncidentsFilterValue?.value === false
        ? d.incidents?.totalCount === 0
        : true
    )

export const groupDevices = (devices: DevicesListNode[]): FacilityGroupsI => {
  const deviceGroups = devices?.reduce((groups, device) => {
    if (device?.facility?.name) {
      groups[device.facility.name] = groups[device.facility.name] || []
      groups[device.facility.name].push(device)
    }
    return groups
  }, {})
  return Object.entries(deviceGroups).map(
    ([facilityName, devices]: [string, Device[]]) => ({
      facilityName,
      devices,
    })
  )
}
