import { Box, Skeleton, VStack } from '@chakra-ui/react'

interface SOPInfoBoxIProps {
  title: string
  isLoading?: boolean
  children?: React.ReactNode
}

export const SOPStatusBox = ({
  title,
  isLoading,
  children,
}: SOPInfoBoxIProps) => {
  return (
    <VStack height='100%' justifyContent='center'>
      {isLoading ? (
        <Skeleton h='27px' w='108px' />
      ) : (
        <Box fontSize='18px' fontWeight='semibold' letterSpacing='-0.53px'>
          {title}
        </Box>
      )}
      {children}
    </VStack>
  )
}
