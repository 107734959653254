import { HiOutlineOfficeBuilding as OfficeBuilding } from 'react-icons/hi'
import { MdLocationOn } from 'react-icons/md'

import {
  ClusterIcon as FacilityClusterIcon,
  ClusterIcon as MarketClusterIcon,
} from './ClusterIcon'

interface ClusterInfoIProps {
  hasIncidents: boolean
  facilityCount: number
  marketIncidentsCount: number
}

export const ClusterInfo = ({
  hasIncidents,
  facilityCount,
  marketIncidentsCount,
}: ClusterInfoIProps) => {
  const shouldShowFacilityCount = facilityCount > 0
  const shouldShowMarketIncidentCount = marketIncidentsCount > 0
  const nRows =
    Number(shouldShowFacilityCount) + Number(shouldShowMarketIncidentCount)

  const fontColor = hasIncidents ? '#fff' : '#000'
  const fontSize = nRows > 1 ? '12px' : '10px'
  const iconSize = nRows > 1 ? '14px' : '12px'

  return (
    <>
      {shouldShowFacilityCount && (
        <FacilityClusterIcon
          count={facilityCount > 999 ? '1k+' : facilityCount}
          fontColor={fontColor}
          fontSize={fontSize}
          icon={<OfficeBuilding color={fontColor} size={iconSize} />}
          testId='globalPage_clusterIcon_facility'
        />
      )}
      {shouldShowMarketIncidentCount && (
        <MarketClusterIcon
          count={marketIncidentsCount > 999 ? '1k+' : marketIncidentsCount}
          fontColor={fontColor}
          fontSize={fontSize}
          icon={<MdLocationOn color={fontColor} size={iconSize} />}
          testId='globalPage_clusterIcon_market'
        />
      )}
    </>
  )
}
