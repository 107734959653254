import { useState } from 'react'

import { useToasts } from '@/hooks/useToasts'

import {
  ALLOWED_MIME_TYPES,
  FILE_TYPES,
  MAX_FILENAME_LENGTH,
  MAX_FILES,
  MAX_FILE_SIZE,
} from '../constants/constants'
import {
  validateFileName,
  validateFileSize,
  validateFileType,
} from '../utils/validation'

export const useFileSelect = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [previewFile, setPreviewFile] = useState<File | null>(null)
  const { showError } = useToasts()

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || [])
    const remainingCapacity = MAX_FILES - selectedFiles.length

    if (files.length > remainingCapacity) {
      showError(`You can only add up to ${MAX_FILES} files`)
      return
    }

    for (const file of files) {
      try {
        validateFileSize(file, MAX_FILE_SIZE)
        validateFileType(file, FILE_TYPES, ALLOWED_MIME_TYPES)
        validateFileName(file, MAX_FILENAME_LENGTH)

        if (
          selectedFiles.find((selectedFile) => selectedFile.name === file.name)
        ) {
          showError(`File "${file.name}" is already added`)
          return
        }

        setSelectedFiles((prevFiles) => [...prevFiles, file])
      } catch (error) {
        showError(error.message)
        return
      }
    }
  }

  const removeFile = (fileToRemove: File) => {
    const fileIndex = selectedFiles.findIndex(
      (file) => file.name === fileToRemove.name
    )
    if (fileIndex === -1) return
    selectedFiles.splice(fileIndex, 1)
    setSelectedFiles([...selectedFiles])
    if (previewFile?.name === fileToRemove.name) setPreviewFile(null)
  }

  return {
    selectedFiles,
    setSelectedFiles,
    handleFileSelect,
    removeFile,
    previewFile,
    setPreviewFile,
  }
}
