import { Box, Grid, Skeleton } from '@chakra-ui/react'

const FeedItemSkeleton = () => {
  return (
    <Grid
      borderRight='1px solid #D5DCE4'
      gap='2'
      mb='1'
      p='10px'
      templateColumns='auto 1fr'
      w='full'
    >
      <Skeleton h='30px' w='30px' />
      <Box>
        <Skeleton h='20px' maxW='320px' w='full' />
        <Skeleton h='20px' maxW='220px' mt='10px' w='full' />
      </Box>
    </Grid>
  )
}

export const FeedItemsSkeleton = () => {
  return (
    <>
      <FeedItemSkeleton />
      <FeedItemSkeleton />
      <FeedItemSkeleton />
    </>
  )
}
