import { HiOutlineExclamation } from 'react-icons/hi'

import {
  Box,
  Circle,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Square,
  VStack,
} from '@chakra-ui/react'

import {
  AccessControl,
  Camera,
  Floor,
  SecurityDevice,
} from '@/components/icons'
import { useFacilityDevicesQuery } from '@/graphql/generated/hooks'
import { DeviceStatus } from '@/graphql/generated/schemas'
import { getDeviceStatusColor } from '@/utils/devices'

interface IProps {
  facilityId: string
  isOpen: boolean
  onClose: () => void
}

const ListItemSkeleton = () => {
  return (
    <Grid
      alignItems='center'
      gap='2'
      py='2'
      templateColumns='48px 1fr'
      w='full'
    >
      <Skeleton h='48px' rounded='full' w='48px' />
      <Box>
        <Skeleton h='16px' maxW='220px' w='full' />
      </Box>
    </Grid>
  )
}

const ListItem = ({
  title,
  deviceHealth,
  deviceType,
  alertCount,
  floorName,
  index,
}: {
  title: string
  deviceHealth: DeviceStatus
  deviceType: string
  alertCount: number
  floorName: string
  index: number
}) => {
  return (
    <Grid
      alignItems='center'
      border='1px solid #D5DCE4'
      boxShadow='base'
      data-testid={`facilitiesPage_devicesModal_item${index}`}
      gap='4'
      p='2'
      rounded='4px'
      templateColumns='45px 1fr auto'
      w='full'
    >
      <Circle bg={getDeviceStatusColor(deviceHealth)} size='45px'>
        {deviceType === 'CAMERA' ? (
          <Icon as={Camera} boxSize='22px' strokeColor='#fff' />
        ) : (
          <Icon as={AccessControl} boxSize='22px' strokeColor='#fff' />
        )}
      </Circle>
      <Box isTruncated>
        <Box
          fontSize='18px'
          fontWeight='bold'
          isTruncated
          letterSpacing='-0.4px'
        >
          {title}
        </Box>
        <Flex>
          <Icon as={Floor} boxSize='16px' strokeColor='#000' />
          <Box
            color='#2D2E41'
            fontSize='14px'
            letterSpacing='-0.4px'
            lineHeight='1'
            ml='2'
          >
            {floorName}
          </Box>
        </Flex>
      </Box>

      {alertCount > 0 && (
        <Square
          bgColor='#D01030'
          boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
          rounded='4px'
          size='40px'
        >
          <Flex
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
          >
            <Box
              color='#fff'
              fontSize='12px'
              fontWeight='medium'
              letterSpacing='-0.67px'
            >
              {alertCount}
            </Box>
            <HiOutlineExclamation color='#fff' size={18} />
          </Flex>
        </Square>
      )}
    </Grid>
  )
}

export const FacilityDevicesModal = ({
  isOpen,
  onClose,
  facilityId,
}: IProps) => {
  const { data, loading: isLoading } = useFacilityDevicesQuery({
    variables: {
      id: facilityId,
    },
    fetchPolicy: 'network-only',
  })
  return (
    <Modal
      id='facilitiesPage_devicesModal'
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='inside'
      size='md'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader borderBottom='1px solid #D5DCE4' px='4'>
          <Box alignItems='center' d='flex'>
            <Icon as={SecurityDevice} boxSize={6} strokeColor='#000' />
            <Box ml={3}>
              <Box
                color='brand.500'
                fontSize='2xl'
                fontWeight='bold'
                lineHeight='1'
              >
                Devices
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' mt='5px' opacity={0.4} />
        <ModalBody p='4'>
          {!data || isLoading ? (
            <>
              <ListItemSkeleton />
              <ListItemSkeleton />
              <ListItemSkeleton />
            </>
          ) : (
            <VStack spacing='2'>
              {data.facilityDevices?.edges.map((d, i) => (
                <ListItem
                  alertCount={d?.node?.incidents?.totalCount}
                  deviceHealth={d?.node?.status}
                  deviceType={d?.node?.type}
                  floorName={d?.node?.floor?.name}
                  index={i}
                  key={i}
                  title={d?.node?.name}
                />
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
