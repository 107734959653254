import { OptionI } from '@/utils/forms/optionBuilders'
import { getOptionsArray } from '@/utils/forms/optionHelpers'

import { GuardsFormInputs } from '../types/types'

// Returns false for "All guards" options; true otherwise
export const isSpecificGuardOption = (option: OptionI) =>
  option?.value !== null && option?.value !== undefined

export const isAllGuardsOption = (option: OptionI) => option?.value === null

// Constructs dispatchedGuardIds array for GraphQL muation based on form inputs
export const getDispatchedGuardIds = (values: GuardsFormInputs) =>
  getOptionsArray(values?.guards)
    .filter(isSpecificGuardOption)
    .map((o) => o.value)
