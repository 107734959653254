import { Box } from '@chakra-ui/react'

export const NotificationTitle = ({
  title,
  subTitle,
}: {
  title: string
  subTitle: string
}) => {
  return (
    <Box fontSize='16px' fontWeight='bold' noOfLines={1}>
      <Box as='span' data-testid='NotificationTitle:title'>
        {title}
      </Box>{' '}
      -{' '}
      <Box as='span' data-testid='NotificationTitle:details'>
        {subTitle}
      </Box>
    </Box>
  )
}
