import React from 'react'

import Image from 'next/image'

interface ImageThumbnailIProps {
  imgUrl: string
}

export const ImageThumbnail = ({ imgUrl }: ImageThumbnailIProps) => {
  return (
    <Image
      alt='Image'
      className='rounded-md'
      height={50}
      src={imgUrl}
      style={{
        maxWidth: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
      width={50}
    />
  )
}
