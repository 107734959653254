import { CSSProperties } from 'react'

import { usePermissions } from '@/hooks/usePermissions'

import { Box } from '@chakra-ui/react'

import { Switch } from '@/components/ui'

import { MixpanelDataIProps } from '../../types/types'
import { ResolveLabelValue } from './ResolveLabelValue'

export interface IProps {
  incidentId: string
  isReal: boolean
  mixpanelData?: MixpanelDataIProps
  style?: CSSProperties
  onChange: (isReal: boolean) => void
}

export const ResolveToggle = ({ isReal, style, onChange }: IProps) => {
  const { shouldEnableIncidentActions } = usePermissions()

  return (
    <Box alignItems='center' display='flex' style={style}>
      <Switch
        backgroundColor='#2fb2b0'
        ballHeight='22px'
        ballPadding='4px'
        checked={isReal}
        dataTestId='incidentDrawer_resolve_setRealFalseToggle'
        disabled={!shouldEnableIncidentActions}
        height='30px'
        onChange={(e) => onChange(e.target.checked)}
        width='80px'
      />
      <ResolveLabelValue isReal={isReal} />
    </Box>
  )
}
