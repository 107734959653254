import { useDeleteFacility } from '../hooks/useDeleteFacility'
import { HiOutlineTrash } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import { Button } from '@/components/ui'

import { ListItemSkeleton } from '../../components/ListItemSkeleton'
import { FacilityErrorView } from './FacilityErrorView'
import { ModalBodyConent } from './ModalBodyConent'

interface DeleteFacilityModalIProps {
  facilityId: string
  isOpen: boolean
  onClose: () => void
}

export const DeleteFacilityModal = ({
  facilityId,
  isOpen,
  onClose,
}: DeleteFacilityModalIProps) => {
  const { data, error, deleteFacility, hasUnresolvedIncidents, dataLoaded } =
    useDeleteFacility({ id: facilityId, onClose })

  return (
    <Modal
      id='facilitiesPage_removeFacilityModal'
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' overflow='hidden'>
        <ModalCloseButton color='#000' opacity={0.4} />
        {error ? (
          <FacilityErrorView onClose={onClose} />
        ) : (
          <>
            <ModalBody p='0'>
              {!dataLoaded ? (
                <ListItemSkeleton />
              ) : (
                <ModalBodyConent
                  facilityName={data?.name}
                  hasUnresolvedIncidents={hasUnresolvedIncidents}
                />
              )}
            </ModalBody>
            {dataLoaded && (
              <Box px='4'>
                <ModalFooter px='0'>
                  <Button
                    data-testid='facilitiesPage_removeFacilityModal_cancelButton'
                    mr={3}
                    onClick={onClose}
                    variant='ghost'
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid='facilitiesPage_removeFacilityModal_removeButton'
                    isDisabled={hasUnresolvedIncidents}
                    leftIcon={<HiOutlineTrash size='24px' />}
                    loadingText='Removing Facility'
                    onClick={deleteFacility}
                    variant='danger'
                  >
                    Delete
                  </Button>
                </ModalFooter>
              </Box>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
