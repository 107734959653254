import { useDispatch, useSelector } from 'react-redux'

import { useListNotifications } from '@/graphql/appsync-notifications'
import {
  selectNotificationsUnread,
  toggleNotificationsUnread,
} from '@/redux/ui/uiSlice'

import { DrawerContainer } from '../../components/DrawerContainer'
import { InboxDrawerTitle } from './InboxDrawerTitle'
import { InboxList } from './InboxList'

export const InboxDrawer = () => {
  const dispatch = useDispatch()
  const shouldShowUnread = useSelector(selectNotificationsUnread)
  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useListNotifications(shouldShowUnread, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  })

  const onLoadMore = () => {
    if (data?.listNotifications?.nextToken !== null && !isLoading) {
      fetchMore({
        variables: {
          nextToken: data?.listNotifications?.nextToken,
        },
      })
    }
  }

  const notifications = data?.listNotifications?.items.filter((notification) =>
    shouldShowUnread ? !notification?.read : true
  )

  return (
    <DrawerContainer
      title={
        <InboxDrawerTitle
          checked={shouldShowUnread}
          onChange={() => dispatch(toggleNotificationsUnread())}
          title='Messages'
        />
      }
    >
      <InboxList
        isLoading={isLoading}
        notifications={notifications}
        onEndReached={onLoadMore}
        onEndReachedThreshold={0.95}
      />
    </DrawerContainer>
  )
}
