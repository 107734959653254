import { useSOPsFilters } from '../hooks/useSOPsFilters'
import { useDispatch, useSelector } from 'react-redux'

import {
  openSOPDrawer,
  selectSOPPageSize,
  setSOP,
  setSOPPageSize,
} from '@/redux/ui/uiSlice'

import { RefetchSOPFnType, SOPsListType } from '../types/types'
import { columns } from './Columns'
import { DataTable } from './DataTable'

export const SOPsTable = ({
  sops,
  totalSops,
  refetch,
}: {
  sops?: SOPsListType
  totalSops?: number
  refetch?: RefetchSOPFnType
}) => {
  const dispatch = useDispatch()
  const pageSize = useSelector(selectSOPPageSize)
  const { filters } = useSOPsFilters()

  const onItemClick = (id: string) => {
    dispatch(setSOP({ id }))
    dispatch(openSOPDrawer())
  }

  const onPageResize = (pageIndex: number) => {
    dispatch(setSOPPageSize(pageIndex))
  }

  const tableData = sops.map((e) => {
    return {
      id: e?.node?.id,
      name: e?.node?.name,
      date: e?.node?.createdAt,
      creator: e?.node?.creator,
      facilities: e?.node?.facilities?.edges,
    }
  })

  return tableData ? (
    <DataTable
      columns={columns}
      data={tableData}
      filters={filters}
      onItemClick={onItemClick}
      onPageResize={onPageResize}
      pageSize={pageSize}
      refetch={refetch}
      total={totalSops}
    />
  ) : null
}
