import { HiArrowLeft } from 'react-icons/hi'

import {
  Divider,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'

import { DrawerBreadcrumb } from '@/components/ui'
import { StandardOperatingProcedureQuery } from '@/graphql/generated/operations'
import { formatDate } from '@/utils/formatDateTime'

interface SOPDrawerHeaderIProps {
  sop: StandardOperatingProcedureQuery['standardOperatingProcedure'] | undefined
  isLoading: boolean
  onClose: () => void
}

export const SOPDrawerHeader = ({
  sop,
  isLoading,
  onClose,
}: SOPDrawerHeaderIProps) => {
  const creatorFullName = sop?.creator
    ? `${sop?.creator?.firstName} ${sop?.creator?.lastName}`
    : ''

  return (
    <Flex
      alignItems='center'
      flexDirection='row'
      h='64px'
      pl='24px'
      pr='40px'
      w='full'
    >
      <HStack borderRight='1px solid #D5DCE4' height='80%' pr={3}>
        <IconButton
          aria-label='Back button'
          borderColor='transparent'
          borderRadius='md'
          h='30px'
          icon={<HiArrowLeft size={18} />}
          minWidth='30px'
          onClick={onClose}
          variant='ghost'
          w='30px'
        />
        <DrawerBreadcrumb
          hideDivider={true}
          isLoading={isLoading}
          label={sop?.name}
        />
      </HStack>
      <Spacer />
      <HStack height='80%' spacing={4}>
        <InfoBox
          isLoading={isLoading}
          label='Creator'
          showTooltip={true}
          text={creatorFullName}
        />
        <Divider borderColor='#D7D4D4' orientation='vertical' w='1px' />
        <InfoBox
          isLoading={isLoading}
          label='Date Created'
          text={formatDate(sop?.createdAt)}
        />
      </HStack>
    </Flex>
  )
}

const InfoBox = ({
  label,
  text,
  isLoading,
  showTooltip = false,
}: {
  label: string
  text: string
  isLoading: boolean
  showTooltip?: boolean
}) => (
  <VStack
    align='start'
    justifyContent='center'
    letterSpacing='-0.37px'
    spacing={0}
  >
    {isLoading ? (
      <Skeleton h='16px' w='80px' />
    ) : (
      <>
        <Text fontSize='11px' fontWeight='300'>
          {label}
        </Text>
        <Tooltip
          bgColor='#fff'
          boxShadow='md'
          color='#1a202c'
          display={showTooltip ? 'auto' : 'none'}
          fontSize='16px'
          fontWeight='semibold'
          label={text}
          letterSpacing='-0.65px'
          p='2'
          placement='bottom'
          rounded='5px'
        >
          <Text
            fontSize='14px'
            fontWeight='500'
            maxW='170px'
            minH='14px'
            noOfLines={1}
          >
            {text}
          </Text>
        </Tooltip>
      </>
    )}
  </VStack>
)
