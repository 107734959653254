import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Icon,
  Square,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Locked, Unlocked } from '@/components/icons'
import {
  selectConfirmEditMode,
  selectEditMode,
  selectSideNavOpen,
} from '@/redux/ui/uiSlice'
import { toggleEditMode } from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'

import { ConfirmEditModeDialog } from '../EditMode'

const StyledSideBarEditMode = styled.div<{ $show: boolean }>`
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 5px;
  align-items: center;
  transition: 0.3s;
  width: ${(p) => (p.$show ? '240px' : '60px')};

  &:hover {
    background-color: #1b253f;

    .chakra-icon {
      color: #fff;
    }
  }
`

export const SideBarEditMode = () => {
  const toast = useToast()
  const id = 'edit-mode-toast'
  const dispatch = useDispatch()
  const isEditMode = useSelector(selectEditMode)
  const isSideNavOpen = useSelector(selectSideNavOpen)
  const hasConfirmedEditMode = useSelector(selectConfirmEditMode)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleToggleEditMode = () => {
    if (!isEditMode && !toast.isActive(id)) {
      toast({
        id,
        position: 'top',
        duration: 2000,
        render: () => (
          <Box
            bg='#fff'
            borderRadius='5px'
            boxShadow='3px 3px 5px 6px rgba(0, 0, 0, 0.2)'
            color='#353849'
            fontWeight='bold'
            letterSpacing='-0.4px'
            mt='75px'
            py='2'
            textAlign='center'
          >
            Now in edit mode <span>🔏</span>
          </Box>
        ),
      })
    }

    dispatch(toggleEditMode())
  }
  const togglingEditMode = () => {
    handleToggleEditMode()
    const date = new Date().toUTCString()
    mixpanel.track('Edit Mode Pressed', {
      user_id: '1',
      facility_id: '1',
      event_at: date,
      total_edit_clicks_no_editmode: '1',
    })
  }
  const handleOnClick = () =>
    hasConfirmedEditMode ? togglingEditMode() : onOpen()
  useEffect(() => {
    if (isEditMode) {
      const date = new Date().toUTCString()
      mixpanel.track('Enters Edit Mode', {
        user_id: '1',
        facility_id: '1',
        event_at: date,
        total_edit_clicks_no_editmode: '1',
      })
    }
  }, [isEditMode])
  return (
    <>
      <ConfirmEditModeDialog isOpen={isOpen} onClose={onClose} />
      <StyledSideBarEditMode
        $show={isSideNavOpen}
        data-testid='SideBarEditMode'
        onClick={handleOnClick}
      >
        <Tooltip
          bgColor='#1b253f'
          color='#fff'
          fontSize='16px'
          fontWeight='semibold'
          isDisabled={isSideNavOpen}
          label='Edit Mode'
          letterSpacing='-0.65px'
          p='2'
          placement='right'
          rounded='5px'
        >
          <Square cursor='pointer' size='60px'>
            <Icon
              as={isEditMode ? Unlocked : Locked}
              boxSize='24px'
              strokeColor={isEditMode ? '#2c987f' : '#5a6c9a'}
            />
          </Square>
        </Tooltip>
        {isSideNavOpen && (
          <Box
            color='#fff'
            fontSize='16px'
            fontWeight='medium'
            letterSpacing='-0.65px'
          >
            Edit Mode
          </Box>
        )}
      </StyledSideBarEditMode>
    </>
  )
}
