import {
  Box,
  ModalCloseButton,
  ModalHeader,
  ModalHeaderProps,
  Spacer,
} from '@chakra-ui/react'

import { CaseManagementIcon } from './CaseManagementIcon'

interface CaseModalHeaderIProps extends ModalHeaderProps {
  title: string
}

export const CaseModalHeader = ({
  title,
  ...headerProps
}: CaseModalHeaderIProps) => {
  return (
    <ModalHeader {...headerProps}>
      <Box align-items='center' display='flex' m='20px auto'>
        <Box display='grid' placeItems='center'>
          <CaseManagementIcon />
        </Box>
        <Box
          color='#353849'
          fontSize='26px'
          fontWeight='extrabold'
          letterSpacing='-0.4px'
          ml={3}
        >
          {title}
        </Box>
        <Spacer />
        <ModalCloseButton color='#000' opacity={0.4} position='initial' />
      </Box>
    </ModalHeader>
  )
}
