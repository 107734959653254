import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { usePermissions } from '@/hooks/usePermissions'
import { useSelector } from 'react-redux'

import { IncidentQueryResult } from '@/graphql/generated/hooks'
import {
  IncidentStatus,
  LastIncidentDispatchType,
} from '@/graphql/generated/schemas'
import { selectMe } from '@/redux/me/meSlice'

interface UseAvailableIncidentActionsIProps {
  incident: IncidentQueryResult['data']['incident']
}

export const useAvailableIncidentActions = ({
  incident,
}: UseAvailableIncidentActionsIProps) => {
  const incidentStatus = incident?.status
  const incidentCategory = incident?.manualIncidentCategory
  const ownerId = incident?.owner?.id
  const outboundPhoneNumber =
    incident?.facility?.emergencyCalling?.outboundPhoneNumber
  const isDispatched = incident?.dispatched
  const isDispatchedToAllGuards =
    incident?.lastIncidentDispatchType === LastIncidentDispatchType.Broadcast

  const isEmergencyCallConfigured = Boolean(outboundPhoneNumber)
  const me = useSelector(selectMe)
  const { shouldShowDownloadReportButton, shouldEnableCaseManagement } =
    usePermissions()

  const isEmergencyCallFlagEnabled =
    useFeatureFlag(FeatureFlag.emergencyCall) || false
  const isCaseManagementFlagEnabled =
    useFeatureFlag(FeatureFlag.caseManagement) || false

  // Ownership
  const isOwner = ownerId === me?.id
  const hasOwner = Boolean(ownerId)

  // Incident status & type
  const isActive = incidentStatus === IncidentStatus.Active
  const isInProgress = incidentStatus === IncidentStatus.InProgress
  const isResolved = incidentStatus === IncidentStatus.Resolved
  const isDeviceHealthIncident = incidentCategory?.name === 'Device Health'

  const shouldShowCaseManagement =
    isCaseManagementFlagEnabled && shouldEnableCaseManagement
  const isEmergencyCallEnabled =
    isInProgress && isEmergencyCallFlagEnabled && isEmergencyCallConfigured
  const isAddNoteEnabled = isActive || isInProgress || isResolved
  const isDispatchEnabled = isInProgress && isOwner && !isDispatchedToAllGuards
  const isRadioGuardsEnabled = isInProgress && isDispatched
  const isOrderRepairEnabled = isInProgress && isDeviceHealthIncident && isOwner
  const isResolveEnabled = isInProgress
  const isCanReleaseEnabled = isInProgress && isOwner
  const isTakeOverEnabled = isInProgress && !isOwner
  const isAcknowledgeEnabled = isActive && !hasOwner
  const isDownloadReportEnabled = isResolved && shouldShowDownloadReportButton
  const isAdditionalOptionsEnabled =
    shouldShowCaseManagement || isDownloadReportEnabled

  // Number of visible tabs
  const availableTabsCount =
    Number(isAddNoteEnabled) +
    Number(isDispatchEnabled || isRadioGuardsEnabled) +
    Number(isOrderRepairEnabled) +
    Number(isEmergencyCallEnabled) +
    Number(isResolveEnabled) +
    Number(isAdditionalOptionsEnabled)

  return {
    isEmergencyCallEnabled,
    isAddNoteEnabled,
    isDispatchEnabled,
    isRadioGuardsEnabled,
    isOrderRepairEnabled,
    isResolveEnabled,
    isCanReleaseEnabled,
    isTakeOverEnabled,
    isAcknowledgeEnabled,
    isDownloadReportEnabled,
    isAdditionalOptionsEnabled,
    availableTabsCount,
  }
}
