import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'

import { Box, Center } from '@chakra-ui/react'

interface TableEditRemoveButtonIProps {
  handleOnEdit: () => void
  handleOnRemove: () => void
}

export const TableEditRemoveButton = ({
  handleOnEdit,
  handleOnRemove,
}: TableEditRemoveButtonIProps) => {
  return (
    <Box display='flex'>
      <Center
        _hover={{
          bgColor: '#d2d2d4',
        }}
        bgColor='#E5E8EE'
        boxShadow='0 2px 7px 0 rgba(50,121,199,0.5)'
        cursor='pointer'
        data-testid='TableEditRemoveButton:edit'
        h='25px'
        mr='5px'
        onClick={(e) => {
          e.stopPropagation()
          handleOnEdit()
        }}
        rounded='5px'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='background-color'
        w='30px'
      >
        <HiOutlinePencil color='#3279C7' size='20px' />
      </Center>
      <Center
        _hover={{
          bgColor: '#b40e2a',
        }}
        bgColor='#D01030'
        boxShadow='0 2px 7px 0 rgba(189,25,25,0.5)'
        cursor='pointer'
        data-testid='TableEditRemoveButton:remove'
        h='25px'
        onClick={(e) => {
          e.stopPropagation()
          handleOnRemove()
        }}
        rounded='3px'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='background-color'
        w='30px'
      >
        <HiOutlineTrash color='#fff' size='20px' />
      </Center>
    </Box>
  )
}
