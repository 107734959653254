import { HiExclamation } from 'react-icons/hi'

import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import { Share } from '@/components/icons'
import { Button } from '@/components/ui'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  incidentName: string
  displayId: string
  isLoading: boolean
}

export const ReleaseIncidentModal = ({
  isOpen,
  onClose,
  onSubmit,
  incidentName,
  displayId,
  isLoading,
}: IProps) => {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' overflow='hidden'>
        <ModalCloseButton color='#000' opacity={0.4} />

        <>
          <ModalBody p='0'>
            <Box px='10' py='6'>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                Release Incident
              </Box>
              <Box
                color='#8c8d97'
                fontSize='17px'
                letterSpacing='-0.4px'
                mt='6'
              >
                Incident Name:
              </Box>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
              >
                {`${displayId} ${incidentName}`}
              </Box>
            </Box>
          </ModalBody>
          <Box px='4'>
            <Flex
              alignItems='center'
              color='#D11D3A'
              flexDirection='row'
              pb='5'
            >
              <HiExclamation size='24px' />
              <Box
                data-testid='RelaseIncidentModal:message'
                fontSize='16px'
                fontWeight='medium'
                letterSpacing='-0.53px'
                ml='1'
              >
                This Action will{' '}
                <Box as='span' fontWeight='bold'>
                  RELEASE
                </Box>{' '}
                you as owner of this Incident
              </Box>
            </Flex>
            <ModalFooter px='0'>
              <Button mr={3} onClick={onClose} variant='secondary'>
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                leftIcon={<Icon as={Share} boxSize={6} strokeColor='#fff' />}
                loadingText='Releasing Incident'
                onClick={onSubmit}
                variant='danger'
              >
                Release Incident
              </Button>
            </ModalFooter>
          </Box>
        </>
      </ModalContent>
    </Modal>
  )
}
