import React from 'react'

import { Text } from '@chakra-ui/react'

interface FacilityBadgeIProps {
  facilityName: string
}

export const FacilityBadge = ({ facilityName }: FacilityBadgeIProps) => {
  return (
    <Text
      bg='#ebeff9'
      borderRadius='20px'
      color='brand.600'
      fontSize='11px'
      mr='4px'
      noOfLines={1}
      px='8px'
      width='min'
    >
      {facilityName}
    </Text>
  )
}
