import { Text, View } from '@react-pdf/renderer'

import { IncidentActionType } from '@/graphql/generated/schemas'

import { styles } from '../styles/styles'
import { DownloadReportButtonIProps } from '../types/types'
import { calculateResolutionTimeInDays } from '../utils/utils'

export const Resolution = ({ incident }: DownloadReportButtonIProps) => {
  const resolutionAction = incident?.actions?.find(
    (i) => i.type === IncidentActionType.Resolved
  )

  const resolutionTimeInDays = calculateResolutionTimeInDays(
    resolutionAction?.createdAt,
    incident?.createdAt
  )
  return (
    <View style={styles.mt5}>
      <Text style={styles.bold}>Resolution Description</Text>
      <Text style={styles.mt2}>{resolutionAction?.message}</Text>
      <View
        style={{
          ...styles.flexRow,
          ...styles.mt2,
        }}
      >
        <Text>Incident marked as </Text>
        <Text style={styles.bold}>{incident?.real ? 'Real' : 'False'} </Text>
        <Text>Incident</Text>
      </View>
      {resolutionAction && (
        <Text style={{ ...styles.bold, fontSize: 18, ...styles.mt2 }}>
          Time To Resolve {resolutionTimeInDays}
        </Text>
      )}
    </View>
  )
}
