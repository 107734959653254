import { Box, Tooltip } from '@chakra-ui/react'

import { getAdddressOrCoors } from '../../mapbox/utils/points'

interface IncidentMarkerToolTipIProps {
  displayId: string
  name: string
  facilityName: string
  address: string | string[]
  children?: React.ReactNode
  latitude?: number
  longitude?: number
}

export const IncidentMarkerToolTip = ({
  displayId,
  facilityName,
  name,
  address,
  latitude,
  longitude,
  children,
}: IncidentMarkerToolTipIProps) => {
  return (
    <Tooltip
      bgColor='#1b253f'
      color='#fff'
      fontWeight={400}
      label={
        <Box>
          <Box
            data-testid='globalPage_incidentMarker_idName'
            fontSize='12px'
            fontWeight={600}
            lineHeight='18px'
          >
            {displayId} {name}
          </Box>
          <Box
            data-testid='globalPage_incidentMarker_facilityName'
            fontSize='10px'
            lineHeight='16px'
            mt='4px'
          >
            {facilityName}
          </Box>
          <Box
            data-testid='globalPage_incidentMarker_location'
            fontSize='10px'
            lineHeight='16px'
          >
            {getAdddressOrCoors(address, latitude, longitude)}
          </Box>
        </Box>
      }
      p='2'
      placement='right'
      rounded='5px'
    >
      {children}
    </Tooltip>
  )
}
