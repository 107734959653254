import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdQrCode } from 'react-icons/md'

import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { format } from 'date-fns'

export interface CheckpointFlatListItemIProps {
  label: string
  subLabel: string
  time: string
  scanned?: boolean
}

export const CheckpointFlatListItem = ({
  label,
  subLabel,
  time,
  scanned,
}: CheckpointFlatListItemIProps) => {
  return (
    <HStack
      backgroundColor='#fff'
      borderBottom='1px solid #CCCCCC'
      justifyContent='space-between'
      role='group'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='all'
    >
      <HStack
        alignItems='flex-start'
        flexShrink={1}
        isTruncated
        minWidth='0'
        p='8px'
        spacing='12px'
      >
        <Box>
          <Icon
            as={FaMapMarkerAlt}
            boxSize='24px'
            color={scanned ? '#44546F' : '#8993A5'}
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='color'
          />
        </Box>

        <VStack align='flex-start' flexShrink={1} isTruncated spacing='0'>
          <Text
            color={scanned ? '#172B4D' : '#8993A5'}
            fontSize='14px'
            fontWeight='medium'
            isTruncated
            lineHeight='20px'
            transition='ease-in-out'
            transitionDuration='300ms'
            transitionProperty='color'
            w='full'
          >
            {label}
          </Text>

          <Box w='full'>
            <HStack flexShrink={1} spacing='2px' w='full'>
              <Box>
                <Icon
                  as={MdQrCode}
                  boxSize='12px'
                  color={scanned ? '#44546F' : '#8993A5'}
                  transition='ease-in-out'
                  transitionDuration='300ms'
                  transitionProperty='color'
                />
              </Box>
              <Text
                color={scanned ? '#172B4D' : '#8993A5'}
                fontSize='12px'
                fontWeight={400}
                isTruncated
                lineHeight='18px'
                transition='ease-in-out'
                transitionDuration='300ms'
                transitionProperty='color'
                w='full'
              >
                {subLabel}
              </Text>
            </HStack>
          </Box>
        </VStack>
      </HStack>
      <Text
        color={scanned ? '#172B4D' : '#8993A5'}
        fontSize='14px'
        fontWeight={400}
        lineHeight='20px'
        pr='15px'
        transition='ease-in-out'
        transitionDuration='300ms'
        transitionProperty='color'
      >
        {time ? format(new Date(time), 'h:mm aa') : '--'}
      </Text>
    </HStack>
  )
}
