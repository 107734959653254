import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { CaseQuery } from '@/graphql/generated/operations'

import { CaseModalHeader } from './CaseModalHeader'
import { UpdateCaseForm } from './UpdateCaseForm'

interface UpdateCaseModalIProps {
  isOpen: boolean
  onClose: () => void
  caseData: CaseQuery
}

export const UpdateCaseModal = ({
  isOpen,
  onClose,
  caseData,
}: UpdateCaseModalIProps) => {
  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <CaseModalHeader title='Update Case' />

        <ModalBody pb={6} px={6} py={2}>
          <UpdateCaseForm caseData={caseData} onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
