import { useToasts } from '@/hooks/useToasts'

import { ConfirmRemovalDialog } from '@/components/ui'
import { useDeleteStandardOperatingProcedureMutation } from '@/graphql/generated/hooks'

interface RemoveSOPModalIProps {
  isOpen: boolean
  onClose: () => void
  sop: {
    id: string
    name: string
  }
  refetch: () => void
}

export const RemoveSOPModal = ({
  isOpen,
  onClose,
  sop,
  refetch,
}: RemoveSOPModalIProps) => {
  const { showSuccess, showError } = useToasts()

  const [deleteSOP, { loading: isDeleteSOPLoading }] =
    useDeleteStandardOperatingProcedureMutation()

  const onSubmit = async () => {
    try {
      await deleteSOP({
        variables: {
          input: {
            id: sop?.id,
          },
        },
      })
      refetch?.()
      showSuccess(`SOP ${sop.name} removed.`)
      onClose()
    } catch {
      showError()
    }
  }

  return (
    <ConfirmRemovalDialog
      handleOnClick={onSubmit}
      isLoading={isDeleteSOPLoading}
      isOpen={isOpen}
      itemTitle={sop.name}
      onClose={onClose}
      title='Remove SOP'
    />
  )
}
