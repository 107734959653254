import { Box } from '@chakra-ui/react'

export const NotificationDescription = ({ desc }: { desc: string }) => {
  return (
    <Box
      color='#2D2E41'
      fontSize='14px'
      fontWeight='bold'
      letterSpacing='-0.53px'
      lineHeight='17px'
      mt='5px'
      noOfLines={2}
      opacity='0.5'
    >
      {desc}
    </Box>
  )
}
