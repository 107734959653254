import { CSSProperties } from 'react'

import { Button } from '@chakra-ui/react'

export interface ArrowButtonIProps {
  onClick: () => void
  isDisabled: boolean
  children?: React.ReactNode
  style?: CSSProperties
}

export const ArrowButton = ({
  onClick,
  isDisabled,
  style,
  children,
}: ArrowButtonIProps) => {
  return (
    <Button
      _active={{
        _disabled: {
          bg: 'transparent',
        },
      }}
      _focus={{
        boxShadow: 'none',
      }}
      _hover={{
        bg: 'transparent',
        color: 'button.100',
      }}
      bg='transparent'
      color='bg.100'
      disabled={isDisabled}
      onClick={onClick}
      position='absolute'
      style={style}
      top='calc(50% - 26px)'
      zIndex={1}
    >
      {children}
    </Button>
  )
}
