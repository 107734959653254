import { useToasts } from '@/hooks/useToasts'

import { useCreateIncidentActionMutation } from '@/graphql/generated/hooks'
import { IncidentActionType } from '@/graphql/generated/schemas'
import { TOGGLE, mixpanel } from '@/utils/analytics'

import { MixpanelDataIProps } from '../types/types'

export const useSetIsIncidentReal = () => {
  const [createIncidentAction] = useCreateIncidentActionMutation()
  const { showSuccess, showError } = useToasts({ preventDuplicate: true })

  const handleUpdateIncidentIsReal = async (
    incidentId: string,
    isReal: boolean,
    showToasts: boolean,
    mixpanelData?: MixpanelDataIProps
  ) => {
    try {
      const realValue = isReal
        ? IncidentActionType.SetRealToTrue
        : IncidentActionType.SetRealToFalse
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: realValue,
          },
        },
      })
      const toggleTag = isReal ? 'Real' : 'False'
      mixpanel.track(`${TOGGLE} Mark Incident ${toggleTag}`, mixpanelData)
      if (showToasts) {
        showSuccess('Incident updated.')
      }
    } catch {
      if (showToasts) {
        showError()
      }
    }
  }
  return handleUpdateIncidentIsReal
}
