import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { CaseModalHeader } from './CaseModalHeader'
import { CreateCaseForm } from './CreateCaseForm'

interface CreateCaseModalIProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateCaseModal = ({ isOpen, onClose }: CreateCaseModalIProps) => {
  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <CaseModalHeader title='Create Case' />

        <ModalBody pb={6} px={6} py={2}>
          <CreateCaseForm onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
