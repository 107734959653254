import { Box, Spinner, Text, VStack } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { CheckpointFlatListItem } from '@/features/guard-checkpoint-flat-list'
import { SubmittedTourStatus } from '@/features/submitted-tours-table'

import { CheckpointIProps } from '../types/types'

export interface SubmittedDrawerTourContentIProps {
  isLoading: boolean
  drawerData: {
    id: string
    name: string
    startedAt: string
    submittedAt: string
    guardFullName: string
    status: SubmittedTourStatus
    checkpoints: [string, unknown][]
  }
}

export const SubmittedDrawerTourContent = ({
  isLoading,
  drawerData,
}: SubmittedDrawerTourContentIProps) => {
  return (
    <AnimatePresence>
      <VStack align='stretch' spacing='30px'>
        {isLoading ? (
          <VStack
            align='center'
            animate={{ opacity: 1 }}
            as={motion.div}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <Spinner />
          </VStack>
        ) : drawerData?.checkpoints.length > 0 ? (
          drawerData?.checkpoints?.map((floorPlan) => {
            const floorName = `Floor ${floorPlan[0]}`
            const floorCheckpoints = floorPlan[1] as CheckpointIProps[]
            return (
              <VStack
                align='stretch'
                animate={{ opacity: 1 }}
                as={motion.div}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                key={floorName}
                spacing='20px'
              >
                <Text
                  color='#44546F'
                  fontSize='16px'
                  fontWeight={500}
                  lineHeight='24px'
                >
                  {floorName}
                </Text>
                {floorCheckpoints.map((c) => (
                  <Box
                    animate={{ opacity: 1 }}
                    as={motion.div}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    key={c.id}
                    w='full'
                  >
                    <CheckpointFlatListItem
                      label={c.name}
                      scanned={c.scanned}
                      subLabel={c.displayId}
                      time={c.time}
                    />
                  </Box>
                ))}
              </VStack>
            )
          })
        ) : (
          <Text
            animate={{ opacity: 1 }}
            as={motion.div}
            color='#9b9b9b'
            exit={{ opacity: 0 }}
            fontSize='14px'
            fontWeight={500}
            initial={{ opacity: 0 }}
            letterSpacing='-0.4px'
            lineHeight='16px'
            textAlign='center'
          >
            List is empty
          </Text>
        )}
      </VStack>
    </AnimatePresence>
  )
}
