import { Flex, Spacer } from '@chakra-ui/react'

interface ClusterIconIProps {
  icon: React.ReactElement
  count: number | string
  fontSize?: string
  fontColor?: string
  testId?: string
}

export const ClusterIcon = ({
  icon,
  count,
  fontSize = '12px',
  fontColor = '#fff',
  testId,
}: ClusterIconIProps) => {
  return (
    <Flex
      alignItems='center'
      color={fontColor}
      data-testid={testId}
      direction='row'
      fontSize={fontSize}
      fontWeight={600}
      justifyContent='space-between'
    >
      {icon} <Spacer w='2px' />
      {count}
    </Flex>
  )
}
