import { FiMapPin } from 'react-icons/fi'

import { Box } from '@chakra-ui/react'

import { IncidentMapPin } from '@/components/icons'
import { StaticMapBox } from '@/components/ui'
import { getAdddressOrCoors } from '@/features/global-map'
import { IncidentQueryResult } from '@/graphql/generated/hooks'

interface MarketIncidentMapIProps {
  incident: IncidentQueryResult['data']['incident']
}

export const MarketIncidentMap = ({ incident }: MarketIncidentMapIProps) => {
  return (
    <Box data-testid='incidentsPage_drawer_marketIncidentMap' p='24px' pb='0'>
      <StaticMapBox
        height='180px'
        latitude={incident?.latitude}
        longitude={incident?.longitude}
      >
        <IncidentMapPin className='icon' />
      </StaticMapBox>

      <Box display='flex' flexDirection='row' pt='3'>
        <FiMapPin color='#4C535E' size={24} />
        <Box fontWeight='medium' ml='10px' textColor='#4C535E'>
          {getAdddressOrCoors(
            incident?.address,
            incident?.latitude,
            incident?.longitude
          )}
        </Box>
      </Box>
    </Box>
  )
}
