import { useStandardOperatingProcedureQuery } from '@/graphql/generated/hooks'
import { VirusScanStatus } from '@/graphql/generated/schemas'

import { POLL_INTERVAL } from '../types/types'

interface UseStandardOperatingProcedureIProps {
  id: string
}

export const useStandardOperatingProcedure = ({
  id,
}: UseStandardOperatingProcedureIProps) => {
  const {
    data,
    loading: isLoading,
    stopPolling,
  } = useStandardOperatingProcedureQuery({
    variables: { id },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const status = data?.standardOperatingProcedure?.status
      if (
        status === VirusScanStatus.Clean ||
        status === VirusScanStatus.Infected
      ) {
        stopPolling()
      }
    },
  })

  return {
    data: data?.standardOperatingProcedure,
    isLoading,
  }
}
