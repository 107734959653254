import { Marker } from 'react-map-gl'

import { Circle } from '@chakra-ui/react'

import { ClusterInfo } from './ClusterInfo'

interface MapClusterIProps {
  coordinates: {
    latitude: number
    longitude: number
  }
  hasFacilityIncidents: boolean
  facilityCount: number
  marketIncidentCount: number
  onClick: () => void
}

export const MapCluster = ({
  coordinates,
  hasFacilityIncidents,
  facilityCount,
  marketIncidentCount,
  onClick,
}: MapClusterIProps) => {
  const hasMarketIncidents = marketIncidentCount > 0
  return (
    <Marker
      latitude={coordinates.latitude}
      longitude={coordinates.longitude}
      offset={[-25, -25]}
    >
      <Circle
        _after={{
          content: '""',
          pos: 'absolute',
          borderRadius: '50%',
          h: '47px',
          w: '47px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderColor:
            hasFacilityIncidents || hasMarketIncidents ? '#fff' : '#4ad06c',
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
        bg={hasFacilityIncidents || hasMarketIncidents ? '#D01030' : '#fff'}
        boxShadow={
          hasFacilityIncidents || hasMarketIncidents
            ? '0 2px 7px 0 rgba(189,25,25,0.5)'
            : '4px 0 14px 0 rgba(65,71,95,0.17)'
        }
        cursor='pointer'
        flexDir='column'
        onClick={onClick}
        pos='relative'
        size='50px'
      >
        <ClusterInfo
          facilityCount={facilityCount}
          hasIncidents={hasFacilityIncidents || hasMarketIncidents}
          marketIncidentsCount={marketIncidentCount}
        />
      </Circle>
    </Marker>
  )
}
