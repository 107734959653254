import { useFacilitiesModals } from '../hooks/useFacilitiesModals'
import { usePermissions } from '@/hooks/usePermissions'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { Grid, HStack, Icon, Skeleton, Text, VStack } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import {
  AccessControl,
  Camera,
  Facility,
  Floor,
  Guards,
  Incident,
  SecurityDevice,
} from '@/components/icons'
import { EditRemoveButton } from '@/components/ui'
import { FacilityType } from '@/graphql/generated/schemas'
import { selectEditMode } from '@/redux/ui/uiSlice'

import { ColumnCell } from './ColumnCell'

export interface FacilityRowCardIProps {
  id?: string
  isLoaded?: boolean
  type?: FacilityType
  name?: string
  address?: string
  guards?: number
  devices?: number
  unresolvedIncidents?: number
  floors?: number
  cameras?: number
  ac?: number
  handleFacilityClick?: () => void
}

export const FacilityRowCard = ({
  isLoaded = true,
  id: facilityId,
  type,
  name,
  address,
  guards,
  devices,
  floors,
  unresolvedIncidents,
  cameras,
  ac,
  handleFacilityClick,
}: FacilityRowCardIProps) => {
  const isEditMode = useSelector(selectEditMode)
  const { shouldEnableDeleteFacility } = usePermissions()
  const {
    handleACClick,
    handleAlertsClick,
    handleCamerasClick,
    handleDevicesClick,
    handleFloorsClick,
    handleGuardsClick,
    handleOnEdit,
    handleOnDelete,
  } = useFacilitiesModals()

  return (
    <Grid gridTemplateColumns='1fr auto' w='full'>
      <AnimatePresence exitBeforeEnter>
        <HStack
          backgroundColor='#FFF'
          borderRadius='4px'
          boxShadow='0px 0px 5px rgba(0, 0, 0, 0.25)'
          h='116px'
          overflow='hidden'
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='all'
          w='full'
        >
          <VStack
            borderRight='1px solid #AFAFB5'
            h='full'
            justifyContent='center'
            maxW='300px'
            px='30px'
            w='full'
          >
            <HStack
              alignItems='unset'
              cursor='pointer'
              onClick={handleFacilityClick}
              w='full'
            >
              <Skeleton h='40px' isLoaded={isLoaded} w='24px'>
                {type === FacilityType.Normal ? (
                  <Icon
                    as={Facility}
                    boxSize='24px'
                    data-testid='facilitiesPage_facilityCard_typePhysical'
                    strokeColor='#000'
                  />
                ) : (
                  <Icon
                    as={FaMapMarkerAlt}
                    boxSize='24px'
                    color='#000'
                    data-testid='facilitiesPage_facilityCard_typeMarket'
                  />
                )}
              </Skeleton>

              <VStack align='start' spacing='0' w='full'>
                <Skeleton h='20px' isLoaded={isLoaded} w='full'>
                  <Text
                    color='#000'
                    fontSize='20px'
                    fontWeight='bold'
                    letterSpacing='-0.67px'
                    lineHeight='24px'
                    noOfLines={1}
                  >
                    {name}
                  </Text>
                </Skeleton>
                <Skeleton
                  h='15px'
                  isLoaded={isLoaded}
                  mt='5px!important'
                  w='full'
                >
                  <Text
                    color='#AFAFB5'
                    fontSize='12px'
                    fontWeight='normal'
                    letterSpacing='-0.5px'
                    lineHeight='15px'
                    noOfLines={3}
                  >
                    {type === FacilityType.Normal ? address : 'Market Facility'}
                  </Text>
                </Skeleton>
              </VStack>
            </HStack>
          </VStack>

          <HStack
            borderRight='1px solid #AFAFB5'
            h='full'
            maxW='250px'
            pr='8px'
            w='full'
          >
            <ColumnCell
              count={guards}
              handleOnClick={() => handleGuardsClick(facilityId)}
              icon={Guards}
              isLoaded={isLoaded}
              name='Guards'
            />
            <ColumnCell
              count={devices}
              handleOnClick={() => handleDevicesClick(facilityId)}
              icon={SecurityDevice}
              isLoaded={isLoaded}
              name='Devices'
            />
            <ColumnCell
              count={floors}
              handleOnClick={() => handleFloorsClick(facilityId)}
              icon={Floor}
              isLoaded={isLoaded}
              name='Floors'
            />
          </HStack>
          <HStack
            borderRight='1px solid #AFAFB5'
            h='full'
            maxW='180px'
            pr='8px'
            w='full'
          >
            <ColumnCell
              count={unresolvedIncidents}
              handleOnClick={() => handleAlertsClick(facilityId)}
              hasIncidents
              icon={Incident}
              isLoaded={isLoaded}
              name='Unresolved Incidents'
            />
          </HStack>
          <HStack
            borderRight='1px solid #AFAFB5'
            h='full'
            maxW='180px'
            pr='8px'
            w='full'
          >
            <ColumnCell
              count={cameras}
              handleOnClick={() => handleCamerasClick(facilityId)}
              icon={Camera}
              isLoaded={isLoaded}
              name='Cameras'
            />
          </HStack>
          <HStack h='full' maxW='180px' pr='8px' w='full'>
            <ColumnCell
              count={ac}
              handleOnClick={() => handleACClick(facilityId)}
              icon={AccessControl}
              isLoaded={isLoaded}
              name='Access Control'
            />
          </HStack>
        </HStack>
        {isEditMode && (
          <motion.div
            animate={{ width: 60, opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            initial={{ width: 0, opacity: 0 }}
            style={{ height: '100%' }}
          >
            <EditRemoveButton
              handleOnDelete={() => handleOnDelete(facilityId)}
              handleOnEdit={() => handleOnEdit(facilityId)}
              showDelete={shouldEnableDeleteFacility}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Grid>
  )
}
