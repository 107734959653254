import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { IncidentNotesMediaType } from '../types/types'

export const FeedImageThumbnail = ({
  file,
}: {
  file: IncidentNotesMediaType
}) => {
  return (
    <Flex
      _hover={{
        shadow: 'md',
      }}
      alignItems='center'
      backgroundColor='#ffff'
      border='1px solid #f2f2f2'
      borderRadius='8px'
      cursor='pointer'
      height={50}
      maxWidth='230px'
      minWidth='200px'
      padding='6px'
      width='230px'
    >
      <img
        alt={file.name}
        height='20px'
        src={file.url}
        style={{ borderRadius: '4px' }}
        width='20px'
      />
      <Box
        flex='1'
        marginLeft='8px'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        <Text fontSize='xs' fontWeight='medium' isTruncated>
          {file.name}
        </Text>
        <Text color='gray.500' fontSize='10px'>
          {file.mimeType.includes('png') ? 'PNG' : 'JPG'}
        </Text>
      </Box>
    </Flex>
  )
}
