import { HiChevronDown } from 'react-icons/hi'
import {
  RiPictureInPicture2Fill,
  RiPictureInPictureExitFill,
} from 'react-icons/ri'
import ReactPlayer from 'react-player'

import {
  AspectRatio,
  Box,
  Collapse,
  HStack,
  IconButton,
  Image,
  Spacer,
  Tooltip,
} from '@chakra-ui/react'

interface IProps {
  show: boolean
  pipActive: boolean
  onClick: () => void
  enablePIP?: () => void
  disablePIP?: () => void
  url?: string
}

export const ShowVideoButton = ({
  show,
  onClick,
  pipActive,
  enablePIP,
  disablePIP,
  url,
}: IProps) => {
  return (
    <Collapse animateOpacity in={show}>
      <Box bg='white' borderBottomWidth='1px' color='white'>
        <HStack px='24px' spacing='18px'>
          <AspectRatio
            cursor='pointer'
            minW='90px'
            onClick={onClick}
            ratio={16 / 9}
          >
            <Image
              alt='video thumbnail'
              objectFit='cover'
              src='https://storage.googleapis.com/camio_general/Camio-tailgating-detection.png'
            />
          </AspectRatio>
          <HStack color='#282424' w='full'>
            <Box fontSize='16px' fontWeight='medium' lineHeight='20px'>
              Camera view
            </Box>
            {ReactPlayer.canEnablePIP(url) && pipActive ? (
              <Tooltip label='Disable picture in picture' placement='bottom'>
                <IconButton
                  aria-label='Disable picture in picture'
                  borderRadius='full'
                  h='30px'
                  icon={<RiPictureInPictureExitFill size={18} />}
                  minWidth='30px'
                  onClick={disablePIP}
                  variant='ghost'
                  w='30px'
                />
              </Tooltip>
            ) : (
              <Tooltip label='Enable picture in picture' placement='bottom'>
                <IconButton
                  aria-label='Enable picture in picture'
                  borderRadius='full'
                  h='30px'
                  icon={<RiPictureInPicture2Fill size={18} />}
                  minWidth='30px'
                  onClick={enablePIP}
                  variant='ghost'
                  w='30px'
                />
              </Tooltip>
            )}

            <Spacer />
            <Tooltip label='Scroll to video' placement='bottom'>
              <IconButton
                aria-label='Scroll to video'
                borderRadius='full'
                h='30px'
                icon={<HiChevronDown size={24} />}
                minWidth='30px'
                onClick={() => {
                  onClick()
                  disablePIP()
                }}
                variant='ghost'
                w='30px'
              />
            </Tooltip>
          </HStack>
        </HStack>
      </Box>
    </Collapse>
  )
}
