import { Image, Text, View } from '@react-pdf/renderer'

import { styles } from '../styles/styles'
import { ReportAttachmentIProps } from '../types/types'
import { getSplitImages } from '../utils/utils'

export const Attachments = ({ incident, notes }: ReportAttachmentIProps) => {
  const { leftHalfImages, rightHalfImages } = getSplitImages(
    incident.imageUrls,
    notes
  )
  return (
    <>
      {leftHalfImages?.length && leftHalfImages.length > 0 && (
        <Text style={{ ...styles.mt5, ...styles.bold }}>Attachments</Text>
      )}

      {leftHalfImages?.length &&
        leftHalfImages.length > 0 &&
        leftHalfImages.map((url: string, i: number) => {
          return (
            <View
              data-testid='incidentReport_attachment_image'
              key={i}
              style={styles.grid}
            >
              <View style={styles.imgBg}>
                <Image
                  cache={true}
                  src={{
                    uri: url,
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                    body: '',
                  }}
                  style={styles.img}
                />
              </View>
              {rightHalfImages[i] && (
                <View style={styles.imgBg}>
                  <Image
                    cache={true}
                    src={{
                      uri: rightHalfImages[i],
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                      body: '',
                    }}
                    style={styles.img}
                  />
                </View>
              )}
            </View>
          )
        })}
    </>
  )
}
