import { Marker } from 'react-map-gl'

import { Circle } from '@chakra-ui/react'

import { IncidentMapPin } from '@/components/icons'

import { MarketIncidentPointIProps } from '../../mapbox/types/types'
import { IncidentMarkerToolTip } from './IncidentMarkerToolTip'

interface IncidentMarkerIProps {
  onClick?: () => void

  incident?: MarketIncidentPointIProps
}

export const IncidentMarker = ({ incident, onClick }: IncidentMarkerIProps) => {
  return (
    <Marker
      latitude={incident.latitude}
      longitude={incident.longitude}
      offset={[-25, -25]}
    >
      <IncidentMarkerToolTip
        address={incident?.address}
        displayId={incident?.displayId}
        facilityName={incident?.facilityName}
        latitude={incident?.latitude}
        longitude={incident?.longitude}
        name={incident?.name}
      >
        <Circle
          cursor='pointer'
          data-testid='IncidentMarker:icon'
          onClick={onClick}
          pos='absolute'
          zIndex='overlay'
        >
          <IncidentMapPin className='icon' />
        </Circle>
      </IncidentMarkerToolTip>
    </Marker>
  )
}
