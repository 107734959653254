import { BiDoorOpen } from 'react-icons/bi'

import { Icon } from '@chakra-ui/react'

import { Camera } from '@/components/icons'
import { DeviceType } from '@/graphql/generated/schemas'

export const DeviceIcon = ({ type }: { type: DeviceType }) => {
  if (type === DeviceType.Door) {
    return <Icon as={BiDoorOpen} boxSize='24px' className='icon' mr='1' />
  } else {
    return (
      <Icon
        as={Camera}
        boxSize='24px'
        className='icon'
        mr='1'
        strokeColor='#000'
      />
    )
  }
}
