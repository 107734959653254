import {
  IncidentsByMarketFacilityQuery,
  OrganizationQuery,
} from '@/graphql/generated/operations'

import { Point } from '../types/types'

export const getFacilitiesPoints = (data: OrganizationQuery) =>
  data?.facilities?.edges
    ?.map((e) => [e.node.longitude, e.node.latitude])
    .filter(([p1, p2]) => p1 !== null && p2 !== null) as Point[]

export const getFacilityIncidents = (data: IncidentsByMarketFacilityQuery) => {
  const incidents = data?.incidents?.edges
    .map((e) => e.node)
    .filter((i) => i.latitude !== null && i.longitude !== null)
  return incidents
}

export const getAdddressOrCoors = (
  address: string | string[],
  lat: number,
  lng: number
) => {
  if (address?.length > 0 && address[0] !== '') {
    return address
  }
  const latformatted = lat.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    useGrouping: false,
  })
  const lngformatted = lng.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    useGrouping: false,
  })
  return `${latformatted}, ${lngformatted}`
}
