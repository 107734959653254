import { useAssociateCases } from '../hooks/useAssociateCases'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { Box } from '@chakra-ui/react'

import { Button, FormInputSelectControl } from '@/components/ui'
import { OptionI } from '@/utils/forms/optionBuilders'

interface AssociateExisitingCaseFormIProps {
  incidentId?: string
  onClose: () => void
}

export const AssociateExisitingCaseForm = ({
  incidentId,
  onClose,
}: AssociateExisitingCaseFormIProps) => {
  const { caseOptions, isLoading, isAssociationLoading, associateIncident } =
    useAssociateCases({ incidentId, onClose })
  const { handleSubmit, control, watch } = useForm<{
    cases: OptionI[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  })
  const areCasesSelected = watch('cases')?.length > 0

  return (
    <Box>
      <Box mb={3} py={2}>
        <FormInputSelectControl
          closeMenuOnSelect={false}
          control={control}
          defaultValue={null}
          id='cases'
          isClearable
          isDisabled={isLoading}
          isMulti
          isSearchable
          label='Cases'
          options={caseOptions}
          placeholder='Add cases to associate incident with'
          rules={{ required: true }}
        />
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <Button mr={3} onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!areCasesSelected}
          isLoading={isAssociationLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          loadingText='Associating'
          onClick={handleSubmit(associateIncident)}
        >
          Associate
        </Button>
      </Box>
    </Box>
  )
}
