import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

import { Box, Skeleton } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

interface IProps {
  isLoading: boolean
  showCameraSwitcher: boolean
  canShowLeftSwitcher?: boolean
  canShowRightSwitcher?: boolean
  onClickPrevious?: () => void
  onClickNext?: () => void
  children?: React.ReactNode
  width?: string
}

export const DeviceFeed = ({
  isLoading,
  onClickPrevious,
  onClickNext,
  children,
  showCameraSwitcher = false,
  canShowLeftSwitcher = showCameraSwitcher,
  canShowRightSwitcher = showCameraSwitcher,
  width = '577px',
}: IProps) => {
  const shouldShowLeftSwitcher = canShowLeftSwitcher && showCameraSwitcher
  const shouldShowRightSwitcher = canShowRightSwitcher && showCameraSwitcher
  return (
    <Skeleton
      borderRadius='0.375rem'
      d='block'
      h='100%'
      isLoaded={!isLoading}
      maxW='100%'
      w={width}
      // minH='324px'
    >
      <Box pos='relative'>
        <Button
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            bg: 'transparent',
            color: 'button.100',
          }}
          bg='transparent'
          color='bg.100'
          data-testid='DeviceFeed:PreviousButton'
          left='0'
          onClick={onClickPrevious}
          paddingLeft='0'
          position='absolute'
          top='calc(50% - 26px)'
          visibility={shouldShowLeftSwitcher ? 'visible' : 'hidden'}
          zIndex={1}
        >
          <HiChevronLeft size='52px' />
        </Button>
        <Button
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            bg: 'transparent',
            color: 'button.100',
          }}
          bg='transparent'
          color='bg.100'
          data-testid='DeviceFeed:NextButton'
          onClick={onClickNext}
          paddingRight='0'
          position='absolute'
          right='0'
          top='calc(50% - 26px)'
          visibility={shouldShowRightSwitcher ? 'visible' : 'hidden'}
          zIndex={1}
        >
          <HiChevronRight size='52px' />
        </Button>
        {children}
      </Box>
    </Skeleton>
  )
}
