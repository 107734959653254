import { HiOutlineX } from 'react-icons/hi'

import {
  Avatar,
  Flex,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'

import { GuardTour } from '@/components/icons'
import { TourStatusBadge } from '@/features/submitted-tours-table'

import { SubmittedTourType } from '../types/types'

export interface SubmittedTourHeaderIProps {
  tourPlan: SubmittedTourType
  isLoading: boolean
  onClose: () => void
}

export const SubmittedTourHeader = ({
  tourPlan,
  isLoading,
  onClose,
}: SubmittedTourHeaderIProps) => {
  return (
    <Flex
      borderBottom='1px solid rgba(9, 30, 66, 0.14)'
      direction='column'
      padding='30px'
    >
      <VStack alignItems='start' spacing='30px'>
        <HStack justifyContent='space-between' spacing='15px' w='full'>
          <HStack spacing='15px'>
            <Icon as={GuardTour} boxSize='24px' strokeColor='#000' />
            <Text
              color='#172B4D'
              fontSize='25px'
              fontWeight={700}
              lineHeight='30px'
            >
              Submitted Tour
            </Text>
          </HStack>
          <IconButton
            aria-label='close edit checkpoint'
            h='30px'
            icon={<HiOutlineX color='#626F86' size={24} />}
            minWidth='30px'
            onClick={onClose}
            variant='ghost'
            w='30px'
          />
        </HStack>

        <VStack w='full'>
          <HStack spacing='12px' w='full'>
            <Skeleton isLoaded={!isLoading} w='20%'>
              <Text
                color='#888888'
                fontSize='12px'
                fontWeight={500}
                lineHeight='18px'
              >
                Tour Plan:
              </Text>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text
                color='#172B4D'
                fontSize='12px'
                fontWeight={600}
                isTruncated
                lineHeight='18px'
              >
                {tourPlan?.name}
              </Text>
            </Skeleton>
          </HStack>
          <HStack alignItems='center' spacing='12px' w='full'>
            <Skeleton isLoaded={!isLoading} w='20%'>
              <Text
                color='#888888'
                fontSize='12px'
                fontWeight={500}
                lineHeight='18px'
              >
                Started:
              </Text>
            </Skeleton>
            <HStack isTruncated w='80%'>
              <Skeleton isLoaded={!isLoading}>
                <Text
                  color='#172B4D'
                  fontSize='12px'
                  fontWeight={600}
                  isTruncated
                >
                  {tourPlan?.startedAt}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Avatar name={tourPlan?.guardFullName} size='xs' />
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Text
                  color='#172B4D'
                  fontSize='12px'
                  fontWeight={600}
                  isTruncated
                >
                  {tourPlan?.guardFullName}
                </Text>
              </Skeleton>
            </HStack>
          </HStack>
          <HStack spacing='12px' w='full'>
            <Skeleton isLoaded={!isLoading} w='20%'>
              <Text
                color='#888888'
                fontSize='12px'
                fontWeight={500}
                lineHeight='18px'
              >
                Submitted:
              </Text>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text
                color='#172B4D'
                fontSize='12px'
                fontWeight={600}
                isTruncated
              >
                {tourPlan?.submittedAt}
              </Text>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <TourStatusBadge
                fontSize='10px'
                minW='auto'
                status={tourPlan?.status}
                w='fit-content'
              />
            </Skeleton>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  )
}
