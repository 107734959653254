import { DeviceStatus } from '@/graphql/generated/schemas'

export const getDeviceStatusColor = (status: DeviceStatus) => {
  switch (status) {
    case DeviceStatus.Healthy:
      return 'brand.900'
    case DeviceStatus.Unhealthy:
      return 'incident.active.100'
    default:
      return '#888888'
  }
}
