import { Box, Flex, Text } from '@chakra-ui/react'

import { Switch } from '@/components/ui'

export interface InboxDrawerTitleIProps {
  title: string
  checked?: boolean
  onChange: () => void
}

export const InboxDrawerTitle = ({
  title,
  checked,
  onChange,
}: InboxDrawerTitleIProps) => {
  return (
    <Flex
      alignItems='center'
      direction='row'
      height='100%'
      justifyContent='center'
      w='full'
    >
      <Box
        alignItems='center'
        display='flex'
        flex={1}
        flexDirection='row'
        textAlign='center'
        w='full'
      >
        <Text display='flex' flex={1} textAlign='center'>
          {title}
        </Text>
        <Switch
          backgroundColor='#2fb2b0'
          checked={checked}
          dataTestId='notificationTab_inboxDrawer_filterToggle'
          onChange={onChange}
          title='Only show unread'
        />
      </Box>
    </Flex>
  )
}
