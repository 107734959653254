/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Avatar,
  Box,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  VStack,
} from '@chakra-ui/react'

import { Guards } from '@/components/icons'
import { useFacilityGuardsQuery } from '@/graphql/generated/hooks'
import { UrlContent } from '@/graphql/generated/schemas'

interface IProps {
  facilityId: string
  isOpen: boolean
  onClose: () => void
}

const ListItemSkeleton = () => {
  return (
    <Grid
      alignItems='center'
      gap='2'
      py='2'
      templateColumns='48px 1fr'
      w='full'
    >
      <Skeleton h='48px' rounded='full' w='48px' />
      <Box>
        <Skeleton h='16px' maxW='220px' w='full' />
      </Box>
    </Grid>
  )
}

const ListItem = ({
  name,
  avatar,
  index,
}: {
  name: string
  avatar: string
  index: number
}) => {
  return (
    <Grid
      alignItems='center'
      border='1px solid #D5DCE4'
      boxShadow='base'
      data-testid={`facilitiesPage_guardsModal_item${index}`}
      gap='4'
      p='2'
      rounded='4px'
      templateColumns='45px 1fr'
      w='full'
    >
      <Avatar name={name} src={avatar} />
      <Box fontSize='18px' fontWeight='bold' isTruncated letterSpacing='-0.4px'>
        {name}
      </Box>
    </Grid>
  )
}

export const FacilityGuardsModal = ({
  isOpen,
  onClose,
  facilityId,
}: IProps) => {
  const { data, loading: isLoading } = useFacilityGuardsQuery({
    variables: { facilityId },
    fetchPolicy: 'network-only',
  })

  const users = data?.checkedInUsers?.edges?.map((e) => e?.node) || []

  return (
    <Modal
      id='facilitiesPage_guardsModal'
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='inside'
      size='md'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader borderBottom='1px solid #D5DCE4' px='4'>
          <Box alignItems='center' d='flex'>
            <Icon as={Guards} boxSize={6} strokeColor='#000' />
            <Box ml={3}>
              <Box
                color='brand.500'
                fontSize='2xl'
                fontWeight='bold'
                lineHeight='1'
              >
                Guards
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' mt='5px' opacity={0.4} />
        <ModalBody p='4'>
          {isLoading ? (
            <>
              <ListItemSkeleton />
              <ListItemSkeleton />
              <ListItemSkeleton />
            </>
          ) : (
            <VStack spacing='2'>
              {users.map((user, i) => (
                <ListItem
                  avatar={(user?.avatar as UrlContent)?.url}
                  index={i}
                  key={user?.id}
                  name={`${user?.firstName} ${user?.lastName}`}
                />
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
