import React from 'react'

import { ImFilePdf } from 'react-icons/im'

import { Box, Flex, Text } from '@chakra-ui/react'

interface FeedPdfThumbnailIProps {
  name: string
  handleClick?: () => void
}

export const FeedPdfThumbnail = ({
  name,
  handleClick,
}: FeedPdfThumbnailIProps) => {
  return (
    <Flex
      _hover={{
        shadow: 'md',
      }}
      alignItems='center'
      backgroundColor='#ffff'
      border='1px solid #f2f2f2'
      borderRadius='8px'
      cursor='pointer'
      height={50}
      maxWidth='230px'
      minWidth='225px'
      onClick={handleClick}
      padding='6px'
      width='230px'
    >
      <ImFilePdf color='#E33E25' size='20px' />
      <Box
        flex='1'
        marginLeft='8px'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        <Text fontSize='xs' fontWeight='medium' isTruncated>
          {name}
        </Text>
        <Text color='gray.500' fontSize='10px'>
          PDF
        </Text>
      </Box>
    </Flex>
  )
}
