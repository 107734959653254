import { FacilityType, IncidentSource } from '@/graphql/generated/schemas'

import { IncidentImages } from '../incident-images'
import { IncidentDataType, MixpanelDataIProps } from '../types/types'
import { IncidentVideo } from './IncidentVideo'
import { MarketIncidentMap } from './MarketIncidentMap'

export interface IncidentMediaIProps {
  incident: IncidentDataType
  isLoading: boolean
  mixpanelData: MixpanelDataIProps
  scrollToTop: () => void
}

export const IncidentMedia = ({
  incident,
  isLoading,
  mixpanelData,
  scrollToTop,
}: IncidentMediaIProps) => {
  const device = incident?.devices?.[0]

  return (
    <>
      {incident?.type && (
        <IncidentVideo
          deviceId={device?.id}
          incidentId={incident?.id}
          incidentType={incident?.type}
          isLoading={isLoading}
          mixpanelData={mixpanelData}
          scrollToTop={scrollToTop}
        />
      )}
      {incident?.facility?.type === FacilityType.Market && (
        <MarketIncidentMap incident={incident} />
      )}
      {incident?.source === IncidentSource.Guard &&
        incident?.imageUrls?.length > 0 && (
          <IncidentImages images={incident?.imageUrls} />
        )}
    </>
  )
}
