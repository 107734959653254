import { createColumnHelper } from '@tanstack/react-table'

import { Avatar, Box, Flex, Text, Tooltip } from '@chakra-ui/react'

import { formatDateTime } from '@/utils/formatDateTime'

import { SubmittedToursRowDataType } from '../types/table'
import { FacilityBadge } from './FacilityBadge'
import { HeaderCell } from './HeaderCell'
import { TourStatusBadge } from './TourStatusBadge'

const columnHelper = createColumnHelper<SubmittedToursRowDataType>()

export const columns = [
  columnHelper.accessor('facilityName', {
    header: () => <HeaderCell justifyContent='start' label='Facility' />,
    cell: (props) => <FacilityBadge facilityName={props.getValue()} />,
    enableSorting: false,
  }),
  columnHelper.accessor('tourPlan', {
    header: () => <HeaderCell justifyContent='start' label='Tour Plan' />,
    cell: (props) => <Text isTruncated>{props.getValue()}</Text>,
    enableSorting: false,
  }),
  columnHelper.accessor('guardFullName', {
    header: () => <HeaderCell justifyContent='center' label='Guard' />,
    cell: (props) => {
      const guardFullName = props.getValue()
      return (
        <Box textAlign='center'>
          <Tooltip
            hasArrow
            label={guardFullName}
            openDelay={500}
            placement='left'
          >
            <Avatar name={guardFullName} size='xs' />
          </Tooltip>
        </Box>
      )
    },
    enableSorting: false,
  }),
  columnHelper.accessor('submittedTime', {
    header: () => <HeaderCell justifyContent='start' label='Submitted' />,
    cell: (props) => (
      <Text isTruncated>
        {formatDateTime(props.getValue(), 'HH:mm | MM/dd/yyyy')}
      </Text>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('status', {
    header: () => <HeaderCell justifyContent='right' label='Status' />,
    cell: (props) => (
      <Flex justifyContent='right'>
        <TourStatusBadge status={props.getValue()} />
      </Flex>
    ),
    enableSorting: false,
  }),
]
