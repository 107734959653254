export type Point = [number, number]

export type Bounds = [Point, Point]

export interface ViewportStateIProps {
  zoom: number
  latitude: number
  longitude: number
}

export enum PointType {
  Facility = 'FACILITY',
  MarketIncident = 'MARKET_INCIDENT',
}

export interface FacilityPointIProps {
  id: string
  latitude: number
  longitude: number
  incidentCount: number
  shortName: string
  floorId: string
}

export interface MarketIncidentPointIProps {
  id: string
  name: string
  facilityName: string
  address: string
  displayId: string
  latitude: number
  longitude: number
}

export interface ClusterPointIProps {
  cluster: boolean
  cluster_id: number
  point_count: number
  point_count_abbreviated: number
  type?: PointType
  incidentCount?: number
  facilityCount?: number
}
