import { FeatureFlag, useFeatureFlags } from './useFeatureFlags'

export const useLiveStreamSetup = () => {
  const flags = useFeatureFlags()
  const isCamio = flags[FeatureFlag.camioCameraStream]
  const isOrangePeel = flags[FeatureFlag.orangePeelCameraStream]

  return {
    hasNoLiveStream: !isCamio && !isOrangePeel,
    isCamio,
    isOrangePeel,
  }
}
