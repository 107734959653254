import { useSelector } from 'react-redux'

import { Filter } from '@/redux/types/filters'
import {
  selectSOPCreatedAtFilter,
  selectSOPCreatorIdFilter,
  selectSOPFacilityIdFilter,
  selectSOPNameLikeFilter,
} from '@/redux/ui/uiSlice'

export function useSOPsFilters() {
  const nameLikeFilterValue = useSelector(selectSOPNameLikeFilter)
  const facilityIdFilterValue = useSelector(selectSOPFacilityIdFilter)
  const createdAtFilterValue = useSelector(selectSOPCreatedAtFilter)
  const creatorIdFilterValue = useSelector(selectSOPCreatorIdFilter)

  const filters = {
    nameLike: nameLikeFilterValue?.value,
    facilityIdsContain: facilityIdFilterValue?.value,
    createdAtGte: createdAtFilterValue?.value?.gte,
    creatorId: creatorIdFilterValue?.value,
  }

  return {
    filters: JSON.parse(JSON.stringify(filters)),
  }
}

export const getNameLikeFilter = (filterString: string): Filter =>
  filterString
    ? {
        value: filterString,
        label: 'SOP name contains.',
      }
    : null
