import { useSelector } from 'react-redux'

import { DeviceType } from '@/graphql/generated/schemas'
import { selectDevice } from '@/redux/ui/uiSlice'

import { ACDeviceDrawer } from './ACDeviceDrawer'
import { CameraDeviceDrawer } from './CameraDeviceDrawer'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const DeviceDrawer = ({ isOpen, onClose }: IProps) => {
  const { id, type } = useSelector(selectDevice)

  switch (type) {
    case DeviceType.Camera:
      return (
        <CameraDeviceDrawer
          defaultIndex={0}
          deviceId={id}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    case DeviceType.Door:
      return (
        <ACDeviceDrawer
          defaultIndex={0}
          deviceId={id}
          isOpen={isOpen}
          onClose={onClose}
        />
      )
    default:
      return null
  }
}
