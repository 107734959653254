export const getExtension = (filename: string) => {
  const parts = filename.split('.')
  return parts.length === 1 ? '' : parts.pop()
}

export const haveCommonElement = (arr1: any[], arr2: any[]) =>
  arr1.some((el) => arr2.includes(el))

export const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as ArrayBuffer)
    }
    reader.onerror = () => {
      reject(reader.error)
    }

    reader.readAsArrayBuffer(file)
  })
}

export const BYTES_IN_MB = 1_0485_76

export const MAX_CSV_SIZE = 10 * BYTES_IN_MB
export const MAX_PDF_SIZE = 100 * BYTES_IN_MB
export const MAX_PNG_SIZE = 100 * BYTES_IN_MB

export const MAX_FILENAME_LENGTH = 50
