import { useState } from 'react'

import { MdFiberManualRecord } from 'react-icons/md'

import { Box, Flex, Icon, Square, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { Note } from '@/components/icons'

interface DispatchMessageIProps {
  voiceRecorder: React.ReactElement
  noteTextarea: React.ReactElement
}

export const DispatchMessage = ({
  voiceRecorder,
  noteTextarea,
}: DispatchMessageIProps) => {
  const [isVoiceMinimized, setIsVoiceMinimized] = useState(true)
  const [isNoteMinimized, setIsNoteMinimized] = useState(true)

  const openVoice = () => {
    setIsVoiceMinimized(false)
  }

  const toggleNote = () => {
    setIsNoteMinimized((prev) => !prev)
  }

  return (
    <>
      <Text
        color='#282424'
        fontSize='16px'
        letterSpacing='-0.53px'
        lineHeight='20px'
        mb='16px'
      >
        Send a voice message or a note to the guards
      </Text>

      <Flex alignItems='center' gap='5px' mb='12px'>
        <AnimatePresence>
          {isVoiceMinimized ? (
            <Square
              animate={{ opacity: 1, width: '38px', height: '38px' }}
              as={motion.div}
              bg='white'
              border='1px solid #D9D9D9'
              cursor='pointer'
              data-testid='dispatchMessage_voiceButton'
              initial={{ opacity: 0, width: 0, height: '38px' }}
              onClick={openVoice}
              rounded='5px'
              transitionDuration='40ms'
              transitionTimingFunction='ease'
            >
              <MdFiberManualRecord color='#D01030' size='18px' />
            </Square>
          ) : (
            <Box
              animate={{ width: '350px', opacity: 1 }}
              as={motion.div}
              exit={{ width: 0, opacity: 0 }}
              initial={{ width: 0, opacity: 0 }}
              key='voiceRecorder'
              transformOrigin='left'
              transitionDuration='40ms'
              transitionTimingFunction='ease'
            >
              {voiceRecorder}
            </Box>
          )}
        </AnimatePresence>

        <Square
          bg={isNoteMinimized ? '#e3eefb' : '#5f96f5'}
          border='1px solid'
          borderColor={isNoteMinimized ? '#e3eefb' : '#5f96f5'}
          cursor='pointer'
          data-testid='dispatchMessage_noteButton'
          h='38px'
          onClick={toggleNote}
          pb='2px'
          pl='2px'
          rounded='5px'
          w='38px'
        >
          <Icon
            as={Note}
            boxSize='18px'
            strokeColor={isNoteMinimized ? '#3d7fce' : '#ffffff'}
          />
        </Square>
      </Flex>

      <AnimatePresence>
        {isNoteMinimized || (
          <Box
            animate={{ height: 'auto' }}
            as={motion.div}
            initial={{ height: 0 }}
            overflow='hidden'
            transformOrigin='top'
          >
            {noteTextarea}
          </Box>
        )}
      </AnimatePresence>
    </>
  )
}
