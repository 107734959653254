import { Box, Spinner, Tbody } from '@chakra-ui/react'

export const TableSpinner = ({ height }: { height: number }) => {
  return (
    <Tbody>
      <Box as='tr' height={height}>
        <Box as='td' colSpan={99}>
          <Spinner
            as='tr'
            color='#298bbd'
            emptyColor='gray.300'
            size='md'
            thickness='4px'
          />
        </Box>
      </Box>
    </Tbody>
  )
}
