import { Avatar, Box, HStack } from '@chakra-ui/react'

import { User } from '@/graphql/generated/schemas'

export const GuardAvatars = ({ guards }: { guards?: Partial<User>[] }) => {
  const maxAvatars = 6
  const avatarCount =
    guards?.length > maxAvatars ? `+${guards?.length - maxAvatars}` : '+0'
  return (
    <HStack spacing='0'>
      {guards?.map((g, i) => {
        if (i < maxAvatars) {
          const url = g.avatar && 'url' in g.avatar ? g.avatar?.url : null
          return (
            <Avatar
              avatar={url}
              key={g?.id}
              name={`${g.firstName} ${g.lastName}`}
              size='xs'
            />
          )
        }
      })}
      <Box
        alignItems='center'
        bg='#D3D4D7'
        borderRadius='50%'
        display='flex'
        fontSize='10px'
        h='24px'
        justifyContent='center'
        w='24px'
      >
        {avatarCount}
      </Box>
    </HStack>
  )
}
