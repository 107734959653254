import { IncidentType } from '@/graphql/generated/schemas'
import { IncidentActionType, SystemAction } from '@/graphql/generated/schemas'

import {
  IncidentActionType as IncdentAction,
  IncidentAudioMessageType,
  IncidentEventType,
  IncidentNoteType,
  TimelineOrder,
} from '../types/types'
import { NonActionItem } from '../types/types'

export const getDescriptionMessage = (type: IncidentType) => {
  switch (type) {
    case IncidentType.DoorHeldOpen:
      return 'was held open.'
    case IncidentType.DoorHeldClose:
      return 'was held close.'
    case IncidentType.ForcedEntry:
      return 'was forced open.'
    case IncidentType.LineCrossing:
      return 'detected a boundary crossing.'
    case IncidentType.Tailgating:
      return 'Multiple people passed through a restricted entrance with only one authorized credential.'
    default:
      return ''
  }
}

export const getActivityTimeline = (
  actions: IncdentAction[],
  events: IncidentEventType,
  audioMessages: IncidentAudioMessageType[],
  notes: IncidentNoteType[],
  order: TimelineOrder = TimelineOrder.Desc
) => {
  const incidentActions = actions.filter(
    (action) => action?.type !== IncidentActionType.Dispatch
  )
  const noteActions = notes.map((note) => ({
    nonActionType: NonActionItem.IncidentNote,
    ...note,
  }))

  const audioMessageActions = audioMessages.map((audioMessage) => ({
    nonActionType: NonActionItem.AudioMessage,
    ...audioMessage,
  }))
  const systemMessages = events
    .filter((item) =>
      Object.prototype.hasOwnProperty.call(item, 'systemMessage')
    )
    .map((systemMessage) => ({
      nonActionType: NonActionItem.SystemAction,
      ...systemMessage,
    })) as unknown as SystemAction[]

  return [
    ...incidentActions,
    ...audioMessageActions,
    ...noteActions,
    ...systemMessages,
  ].sort((a, b) => {
    const aDate = new Date(a.createdAt).valueOf()
    const bDate = new Date(b.createdAt).valueOf()
    return order === TimelineOrder.Desc ? bDate - aDate : aDate - bDate
  })
}
