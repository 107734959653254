import { useToasts } from '@/hooks/useToasts'

import {
  useDeleteFacilityMutation,
  useFacilityUnresolvedIncidentsQuery,
} from '@/graphql/generated/hooks'

interface UseDeleteFacilityIProps {
  id: string
  onClose: () => void
}

export const useDeleteFacility = ({ id, onClose }: UseDeleteFacilityIProps) => {
  const [deleteFacilityMutation, { loading: isDeleteLoading }] =
    useDeleteFacilityMutation()
  const { showSuccess, showError } = useToasts()
  const {
    data,
    error,
    loading: isQueryLoading,
  } = useFacilityUnresolvedIncidentsQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })

  const deleteFacility = async () => {
    try {
      await deleteFacilityMutation({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: ['Facilities'],
      })
      showSuccess('Facility removed.')
      onClose()
    } catch (error) {
      showError()
    }
  }

  return {
    deleteFacility,
    data: data?.facility,
    hasUnresolvedIncidents: data?.facility.incidents?.totalCount > 0,
    //loading: isQueryLoading || isDeleteLoading,
    dataLoaded: !isQueryLoading || (!isDeleteLoading && data),
    error,
  }
}
